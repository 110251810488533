<template>
  <aside
  id="sidenav-main"
  class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 bg-aside"
  :class="[
    isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3',
    { 'dark-mode': isDarkMode }
  ]"
>

    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <a class="m-0 navbar-brand" href="/">

        <span :class="{ 'dark-mode': isDarkMode }" class="ms-2  font-weight-bold text-logo"
          >Caledonian School</span>

          <img
          :src="
            sidebarType === 'bg-white' ||
            (sidebarType === 'bg-transparent' && !isDarkMode)
              ? logoDark
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"/>
      </a>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-school-one.png";
import logoDark from "@/assets/img/logo-ct-dark.png";
import { mapState ,mapGetters} from "vuex";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType"]),
    ...mapGetters(['isDarkMode']),
  },
};
</script>


<style>
span.text-logo:not(.dark-mode){
  color: #261C2C !important;
}
.text-logo.dark-mode{
  color: white;
}
</style>