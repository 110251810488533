<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="text-center">
            <h3>
              <span :class="{ 'dark-mode': isDarkMode }" class="gradient-text">🏫 Caledonian International School 🏫
              </span>
              <br>
              <span :class="{ 'dark-mode': isDarkMode }" class="gradient-text"> 💠
                Your ambitions are our responsibility 💠</span>
            </h3>
          </div>
          <hr v-if="userType === 'moder' || userType === 'financial' || userType === 'student_m' || userType === 'hr' || userType === 'developer' "
            style="margin-top: 10px;margin-bottom:40px" />
          <div class="col-lg-3 col-md-6 col-sm-6" v-if="userType === 'moder' || userType === 'developer'">
            <mini-statistics-card :title="{ text: 'المستخدمين', value: usersCount }"
              :icon="{ name: 'person', color: 'text-white', background: 'dark' }" />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'developer' ">
            <mini-statistics-card :title="{ text: 'الموظفيـن', value: employeesCount }"
              :icon="{ name: 'work', color: 'text-white', background: 'primary' }" />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'developer'">
            <mini-statistics-card :title="{ text: 'الخزينة المالية', value: treasuryBalance }"
              :icon="{ name: 'money', color: 'text-white', background: 'success' }" />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4"
            v-if="userType === 'moder' || userType === 'financial' || userType === 'student_m' || userType === 'hr' || userType === 'developer' ">
            <mini-statistics-card :title="{ text: 'جميع الطلبة', value: studentsCount }"
              :icon="{ name: 'school', color: 'text-white', background: 'info' }" />
          </div>
        </div>
        <hr v-if="userType === 'moder' || userType === 'financial' || userType === 'student_m' || userType === 'hr' || userType === 'developer' " />

        <!-- <div class="col-lg-12 col-md-12 col-sm-12 mt-lg-0 mt-4"
       v-if="userType === 'moder' || userType === 'financial' || userType === 'student_m' || userType === 'hr'">
    <div class="balance-display">
      <i class="pi pi-money-bill icon"></i>
      <h2>الرصيد الراجع للخزينة:</h2>
      <span class="balance-amount">{{ totalDeductions }}</span>
    </div>
  </div> -->
        <div class="row mt-4">
          <div class="col-lg-12 col-md-12 col-sm-12 mt-lg-0 mt-4"
            v-if="userType === 'moder' || userType === 'financial' || userType === 'student_m' || userType === 'hr' || userType === 'developer' ">
            <chart-holder-card color="#fff" title="الفصــول الدراسيــة">
              <!-- Render only if data is available -->
              <Bar v-if="chartData.datasets[0].data.length" :data="chartData" :options="chartOptions" />
            </chart-holder-card>
          </div>


        </div>
        <hr v-if="userType === 'moder' || userType === 'financial' || userType === 'student_m' || userType === 'hr' || userType === 'developer' "
          style="margin-top: 10px;margin-bottom:40px" />
        <p style="margin-bottom:40px" class="text-center">{{ $t('quickLinks') }}</p>

          <div class="row" >
            <div class="col-lg-3 col-md-6 col-sm-6" v-if="userType === 'cashier'">
              <mini-links-card
             :title="{ text: $t('kitchenSales') }"
                :image="cashier"
                 href="/kitchen-sales"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6" v-if="userType === 'cashier'">
              <mini-links-card
             :title="{ text: $t('allkitchenbills') }"
                :image="bill"
                 href="/all-kitchen-bills"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'student_m'  || userType === 'developer' ">
              <mini-links-card
             :title="{ text: $t('quickAccess') }"
                :image="students"
                 href="/parent-student-form"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'student_m'  || userType === 'developer' ">
              <mini-links-card
             :title="{ text: $t('studentsManagement') }"
                :image="students"
                 href="/students"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'student_m'  || userType === 'developer' ">
              <mini-links-card
                   :title="{ text: $t('parentsManagement') }"
                :image="family"
                href="/parents"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'student_m'  || userType === 'developer' ">
              <mini-links-card
              :title="{ text: $t('studentsReports') }"
                :image="report"
                href="/student-report"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'student_m'  || userType === 'developer' ">
              <mini-links-card
                    :title="{ text: $t('sendEmails') }"
                :image="email"
                href="/send-emails"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'student_m'  || userType === 'developer' ">
              <mini-links-card
                    :title="{ text: $t('eventsCalendar') }"
                :image="calendar"
                href="/events-calendar"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'student_m'  || userType === 'developer' ">
              <mini-links-card
                    :title="{ text: $t('studentAttendances') }"
                :image="shoppingList"
                href="/attendance"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'student_m'  || userType === 'developer' ">
              <mini-links-card
                    :title="{ text: $t('studentAttendancesList') }"
                :image="shoppingList"
                href="/attendance-list"
              />
            </div>
            <hr v-if="userType === 'hr'" style="margin-top: 20px;margin-bottom:20px"/>
            <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'hr'">
              <mini-links-card
                    :title="{ text: $t('permissionEmployee') }"
                :image="hrManager"
                href="/permission-employee"
              />
            </div>
         
          <hr v-if="userType === 'moder' || userType === 'financial' || userType === 'student_m' || userType === 'hr'  || userType === 'developer' " style="margin-top: 20px;margin-bottom:20px"/>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr'  || userType === 'developer' ">
            <mini-links-card
                  :title="{ text: $t('employeesHourSalary') }"
              :image="hour"
              href="/employee-hour-salary"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr'  || userType === 'developer' ">
            <mini-links-card
                  :title="{ text: $t('manageAttendanceSalary') }"
              :image="record"
              href="/attendance-hour-employee"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr'  || userType === 'developer' ">
            <mini-links-card
                  :title="{ text: $t('manageDeductionHour') }"
              :image="deduction"
              href="/deduction-per-hour"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'hr'  || userType === 'developer' ">
            <mini-links-card
                  :title="{ text: $t('issuedSalaryHour') }"
              :image="salary"
              href="/issued-salaries-per-hour"
            />
          </div>

       
          <hr v-if="userType === 'moder' || userType === 'financial'" style="margin-top: 20px;margin-bottom:20px"/>
                    <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'developer' ">
            <mini-links-card
                  :title="{ text: $t('permissionEmployee') }"
              :image="hrManager"
              href="/permission-employee"
            />
          </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'developer' ">
            <mini-links-card
                  :title="{ text: $t('archiveFinancialDocument') }"
              :image="moneyFin"
              href="/print-financial-document"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" v-if="userType === 'moder' || userType === 'financial' || userType === 'developer' ">
            <mini-links-card :title="{ text: $t('financialManagement') }" :image="moneyFin"
              href="/financial-document" />
          </div>
          <hr v-if="userType === 'moder' || userType === 'financial' || userType === 'student_m' || userType === 'hr' || userType === 'developer' "
            style="margin-top: 20px;margin-bottom:20px" />
          <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4"
            v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'developer' ">
            <mini-links-card :title="{ text: $t('employeesManagement') }" :image="division" href="/employees" />
          </div>
          <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4"
            v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'developer' ">
            <mini-links-card :title="{ text: $t('employeesReports') }" :image="employeeReport"
              href="/employee-reports" />
          </div>

          <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4"
            v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'developer' ">
            <mini-links-card :title="{ text: $t('salariesManagement') }" :image="money" href="/manage-salaries" />
          </div>
          <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4"
            v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'developer' ">
            <mini-links-card :title="{ text: $t('settingsSalary') }" :image="discount" href="/absence-and-deduction" />
          </div>
          <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4"
            v-if="userType === 'moder' || userType === 'financial' || userType === 'hr' || userType === 'developer' ">
            <mini-links-card :title="{ text: $t('salaries') }" :image="payroll" href="/salaries" />
          </div>
        </div>



        <!-- <div class="row">
          <div class="text-left">
            <h3>Registration Bills</h3>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <mini-statistics-card
              :title="{ text: 'Total Registration Bills', value: '$53k' }"
              detail="<span class='text-success text-sm font-weight-bolder'>+55%</span> than last week"
              :icon="{
                name: 'weekend',
                color: 'text-white',
                background: 'dark',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
              :title="{ text: 'Paid Registration Bills', value: '2,300' }"
              detail="<span class='text-success text-sm font-weight-bolder'>+3%</span> than last month"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'primary',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
              :title="{ text: 'Unpaid Registration Bills', value: '3,462' }"
              detail="<span class='text-danger text-sm font-weight-bolder'>-2%</span> than yesterday"
              :icon="{
                name: 'person',
                color: 'text-white',
                background: 'success',
              }"
            />
          </div>

        </div> -->

      </div>
    </div>


  </div>
</template>
<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import ReportsLineChart from "@/examples/Charts/ReportsLineChart.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import MiniLinksCard from "./components/MiniLinksCard.vue";
import ProjectCard from "./components/ProjectCard.vue";
import TimelineList from "@/examples/Cards/TimelineList.vue";
import TimelineItem from "@/examples/Cards/TimelineItem.vue";
import logoXD from "@/assets/img/small-logos/logo-xd.svg";
import logoAtlassian from "@/assets/img/small-logos/logo-atlassian.svg";
import logoSlack from "@/assets/img/small-logos/logo-slack.svg";
import logoSpotify from "@/assets/img/small-logos/logo-spotify.svg";
import logoJira from "@/assets/img/small-logos/logo-jira.svg";
import logoInvision from "@/assets/img/small-logos/logo-invision.svg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import email from "@/assets/img/email.png";
import calendar from "@/assets/img/calendar.png";
import shoppingList from "@/assets/img/shopping-list.png";
import report from "@/assets/img/report.png";
import students from "@/assets/img/students.png";
import family from "@/assets/img/family.png";
import division from "@/assets/img/division.png";
import employeeReport from "@/assets/img/employeeReport.png";
import money from "@/assets/img/money.png";
import payroll from "@/assets/img/payroll.png";
import discount from "@/assets/img/discount.png";
import hour from "@/assets/img/hour.png";
import record from "@/assets/img/record.png";
import deduction from "@/assets/img/deduction.png";
import salary from "@/assets/img/salary.png";
import moneyFin from "@/assets/img/money-fin.png";
import hrManager from "@/assets/img/hr-manager.png";
import cashier from "@/assets/img/cashier.png";
import bill from "@/assets/img/bill.png";
import { mapMutations, mapState, mapGetters } from "vuex";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use((config) => {

  document.querySelectorAll('button').forEach((button) => {
    button.setAttribute('disabled', '');  // إضافة الخاصية فقط
  });
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
// دالة بعد اكتمال الطلب
api.interceptors.response.use((response) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });




  // يمكنك هنا التعامل مع البيانات بعد اكتمال الطلب
  // مثال: تعديل البيانات قبل إرجاعها

  return response;  // إرجاع الاستجابة كما هي
}, (error) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  // في حالة حدوث خطأ بعد اكتمال الطلب
  console.error("خطأ في الاستجابة:", error);
  return Promise.reject(error);
});
export default {
  name: "Dashboard",

  data() {
    return {
      logoXD,
      team1,
      team2,
      team3,
      students,
      totalDeductions: 0,

      family,
      report,
      email,
      division,
      employeeReport,
      money,
      bill,
      payroll,
      discount,
      hour,
      record,
      deduction,
      cashier,
      salary,
      moneyFin,
      hrManager,
      logoAtlassian,
      logoSlack,
      logoSpotify,
      calendar,
      shoppingList,
      logoJira,
      logoInvision,
      usersCount: 0,
      employeesCount: 0,
      treasuryBalance: 0,
      studentsCount: 0,
      lastCheckIn: {
        name: '',
        checkInTime: '',
        checkOutTime: '',
      },
      chartData: {
        labels: [],  // Class names
        datasets: [{
          label: 'Number of Students',
          data: [],  // Student count per class
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1,
        }],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,  // Ensures the y-axis starts at 0 and increments by 1
              color: 'rgba(75, 192, 192, 1)',  // Change y-axis label color
              font: {
                size: 14,  // Change font size
                family: 'Arial',  // Change font family
                style: 'italic',  // Change font style
              },
            },
            grid: {
              color: 'rgba(200, 200, 200, 0.3)',  // Grid line color
            },
          },
          x: {
            ticks: {

              font: {
                size: 14,  // Change font size
                family: 'DINNextLTArabic-Bold',  // Change font family
                style: 'italic',  // Change font style
              },
            },
            grid: {
              color: 'rgba(200, 200, 200, 0.3)',  // Grid line color
            },
          },
        },
        plugins: {
          legend: {
            display: false,  // Hides the legend as there's only one dataset
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return ` ${context.raw} students`;
              },
            },
          },
        },
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          }
        },
        backgroundColor: 'rgba(255, 255, 255, 0.9)',  // Set background color for the chart area
      },


      transactionChartData: {
        labels: [],  // Transaction types
        datasets: [{
          label: 'الحركات المالية',
          data: [],  // Total amount per transaction type
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
          ],
          borderWidth: 1,
        }],
      },
      transactionChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {

              stepSize: 1000,  // Adjust according to your data
              family: 'DINNextLTArabic-Bold',  // Change font family
            },
          },
          x: {
            ticks: {

              family: 'DINNextLTArabic-Bold',  // Change font family
            },
          },
        },
        plugins: {
          legend: {
            display: true,
          },
        },
      },
    };

  },
  mounted() {
    this.fetchStatistics();
    this.fetchTotalDeductions();

  },
  methods: {
    async fetchStatistics() {
      try {
        const usersResponse = await api.get('/statistics/users');
        this.usersCount = usersResponse.data.total_users;

        const employeesResponse = await api.get('/statistics/employees');
        this.employeesCount = employeesResponse.data.total_employees;

        const treasuryResponse = await api.get('/statistics/treasury/manual-balance');
        this.treasuryBalance = treasuryResponse.data.manual_treasury_balance;

        const studentsResponse = await api.get('/statistics/students');
        this.studentsCount = studentsResponse.data.total_students;



        const studentsPerClassResponse = await api.get('/statistics/students-per-class');
        const studentsPerClass = studentsPerClassResponse.data.students_per_class;


        // Update chart data
        this.chartData.labels = studentsPerClass.map(item => item.class_name);
        this.chartData.datasets[0].data = studentsPerClass.map(item => item.student_count);

        // Fetch treasury transactions
        const transactionsResponse = await api.get('/statistics/treasury-transactions');
        const transactions = transactionsResponse.data.transactions;

        // Update chart data
        this.transactionChartData.labels = transactions.map(item => item.transaction_type);
        this.transactionChartData.datasets[0].data = transactions.map(item => item.total_amount);

        // Fetch last check-in
        //  const lastCheckInResponse = await api.get('/statistics/last-check-in');
        // this.lastCheckIn = lastCheckInResponse.data;

      } catch (error) {
        if (error.response.data.message == 'Unauthenticated.') {

          localStorage.removeItem('access_token');
          localStorage.removeItem('user_type');
          window.location.reload();
        }
        console.error('Error fetching statistics:', error);
      }


    },
    async fetchTotalDeductions() {
      try {
        const response = await api.get('/total-deductions-2025');
        if (response.data.success) {
          this.totalDeductions = response.data.total_deductions;
        }
      } catch (error) {
        console.error('Error fetching total deductions:', error);
      }
    },
  },
  computed: {
    isDarkMode() {
      // Access the `isDarkMode` state from Vuex or another state management system
      return this.$store.state.isDarkMode;
    },
    ...mapGetters(['isDarkMode', 'userType']),

  },
  components: {
    ChartHolderCard,
    ReportsBarChart,
    ReportsLineChart,
    MiniStatisticsCard,
    MiniLinksCard,
    ProjectCard,
    TimelineList,
    TimelineItem,
    Bar
  },
};
</script>
<style scoped>
.balance-display {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon {
  font-size: 3rem;
  margin-right: 15px;
  color: #f1c40f; /* Gold color for the icon */
}

.balance-amount {
  font-size: 4rem;
  color: #27ae60;
}

    .balance-amount {
        font-size: 3rem;
        color: #27ae60;
    }
@font-face {
  font-family: 'DINNextLTArabic-Bold';
  src: url('../../public/fonts/TajawalBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.text-left {
  text-align: left !important;
  margin-bottom: 10px;
}

.chart-container {
  background-color: rgba(240, 240, 240, 0.8);
  /* Light gray with transparency */
  padding: 20px;
  border-radius: 8px;
  color: white;
  position: relative;
  width: 100%;
  height: 400px;
  /* Adjust height as needed */
}

.gradient-text:is(.dark-mode) {
  color: #8b8b8b;
  -webkit-background-clip: text;
}

.gradient-text {
  color: #674188;
  -webkit-background-clip: text;

  background-clip: text;

  font-family: DINNextLTArabic-Bold;
  font-weight: 700;

}


@font-face {
  font-family: 'DINNextLTArabic-Bold';
  src: url('../../public/fonts/TajawalBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

p {
  font-family: DINNextLTArabic-Bold;
  font-weight: 700;
}

h6 {
  font-family: DINNextLTArabic-Bold;
  font-weight: 700;
  text-align: center;
}

h2{
  font-family: DINNextLTArabic-Bold;
}

.card-body:is(.dark-mode) {
  color: #8b8b8b !important;
}

.dark-mode {
  color: white;
}
</style>