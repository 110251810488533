<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card my-4">
                    <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
                        <div class="d-flex justify-content-between">
                            <h6 class="text-white text-capitalize ps-3">Manage Treasuries</h6>
                            <div>
                                <el-button @click="dialogAddTreasuryVisible = true" type="success" circle class="mr-5">
                                    <el-icon style="vertical-align: middle">
                                        <Document />
                                    </el-icon>
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table :data="treasuries" style="width: 100%">
                            <el-table-column prop="name" label="Name" />
                            <el-table-column prop="type" label="Type" />
                            <el-table-column prop="balance" label="Balance" />
                            <el-table-column label="Operations" width="250">
                                <template #default="scope">
                                    <el-button @click="openDepositDialog(scope.row)" type="primary" size="mini">Deposit</el-button>
                                    <el-button @click="openDisburseDialog(scope.row)" type="warning" size="mini">Disburse</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Treasury Dialog -->
        <el-dialog v-model="dialogAddTreasuryVisible" title="Add Treasury" width="600">
            <el-form :model="formAddTreasury" ref="formAddTreasury">
                <el-form-item label="Name" :rules="[{ required: true, message: 'Please enter treasury name', trigger: 'change' }]">
                    <el-input v-model="formAddTreasury.name"></el-input>
                </el-form-item>
                <el-form-item label="Type" :rules="[{ required: true, message: 'Please select type', trigger: 'change' }]">
                    <el-select v-model="formAddTreasury.type" placeholder="Select type" @change="onTreasuryTypeChange">
                        <el-option label="Bank" value="bank"></el-option>
                        <el-option label="Manual" value="manual"></el-option>
                    </el-select>
                </el-form-item>
                <div v-if="formAddTreasury.type === 'bank'">
                    <el-form-item label="Bank Name" :rules="[{ required: true, message: 'Please enter bank name', trigger: 'change' }]">
                        <el-input v-model="formAddTreasury.bank_name"></el-input>
                    </el-form-item>
                    <el-form-item label="Account Number" :rules="[{ required: true, message: 'Please enter account number', trigger: 'change' }]">
                        <el-input v-model="formAddTreasury.account_number"></el-input>
                    </el-form-item>
                    <el-form-item label="Routing Number" :rules="[{ required: true, message: 'Please enter routing number', trigger: 'change' }]">
                        <el-input v-model="formAddTreasury.routing_number"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="dialogAddTreasuryVisible = false">Cancel</el-button>
                <el-button type="primary" @click="addTreasury">Add Treasury</el-button>
            </div>
        </el-dialog>

        <!-- Deposit Dialog -->
        <el-dialog v-model="dialogDepositVisible" title="Deposit to Treasury" width="600">
            <el-form :model="formDeposit" ref="formDeposit">
                <el-form-item label="Amount" :rules="[{ required: true, message: 'Please enter deposit amount', trigger: 'change' }]">
                    <el-input type="number" v-model="formDeposit.amount"></el-input>
                </el-form-item>
                <el-form-item label="Description">
                    <el-input v-model="formDeposit.description"></el-input>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="dialogDepositVisible = false">Cancel</el-button>
                <el-button type="primary" @click="depositToTreasury">Deposit</el-button>
            </div>
        </el-dialog>

        <!-- Disburse Dialog -->
        <el-dialog v-model="dialogDisburseVisible" title="Disburse from Treasury" width="600">
            <el-form :model="formDisburse" ref="formDisburse">
                <el-form-item label="Amount" :rules="[{ required: true, message: 'Please enter disbursement amount', trigger: 'change' }]">
                    <el-input type="number" v-model="formDisburse.amount"></el-input>
                </el-form-item>
                <el-form-item label="Description">
                    <el-input v-model="formDisburse.description"></el-input>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="dialogDisburseVisible = false">Cancel</el-button>
                <el-button type="primary" @click="disburseFromTreasury">Disburse</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Document } from '@element-plus/icons-vue';
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

export default {
    name: 'ManageTreasuries',
    components: {
        Document,
    },
    data() {
        return {
            treasuries: [],
            dialogAddTreasuryVisible: false,
            dialogDepositVisible: false,
            dialogDisburseVisible: false,
            formAddTreasury: {
                name: '',
                type: '',
                bank_name: '',
                account_number: '',
                routing_number: ''
            },
            formDeposit: {
                amount: '',
                description: '',
                related_id: null, // should be set automatically based on context
                related_type: ''  // should be set automatically based on context
            },
            formDisburse: {
                amount: '',
                description: '',
                related_id: null, // should be set automatically based on context
                related_type: ''  // should be set automatically based on context
            },
            selectedTreasury: null
        };
    },
    methods: {
        async fetchTreasuries() {
            try {
                const response = await api.get('/treasuries');
                this.treasuries = response.data;
            } catch (error) {
                this.$message.error('Failed to fetch treasuries');
            }
        },
        addTreasury() {
            this.$refs.formAddTreasury.validate(async (valid) => {
                if (valid) {
                    try {
                        const response = await api.post('/treasuries', this.formAddTreasury);
                        this.$message.success('Treasury added successfully');
                        this.treasuries.push(response.data);
                        this.dialogAddTreasuryVisible = false;
                        this.resetForm('formAddTreasury');
                    } catch (error) {
                        this.$message.error('Failed to add treasury');
                    }
                }
            });
        },
        openDepositDialog(treasury) {
            this.selectedTreasury = treasury;
            // Here, related_id and related_type can be determined by the context of the application
            this.formDeposit.related_id = 123; // Example ID
            this.formDeposit.related_type = 'salary'; // Example type
            this.dialogDepositVisible = true;
        },
        depositToTreasury() {
            this.$refs.formDeposit.validate(async (valid) => {
                if (valid) {
                    try {
                        await api.post(`/treasuries/${this.selectedTreasury.id}/deposit`, this.formDeposit);
                        this.$message.success('Deposit successful');
                        this.dialogDepositVisible = false;
                        this.resetForm('formDeposit');
                        this.fetchTreasuries();
                    } catch (error) {
                        this.$message.error('Failed to deposit to treasury');
                    }
                }
            });
        },
        openDisburseDialog(treasury) {
            this.selectedTreasury = treasury;
            // Here, related_id and related_type can be determined by the context of the application
            this.formDisburse.related_id = 123; // Example ID
            this.formDisburse.related_type = 'salary'; // Example type
            this.dialogDisburseVisible = true;
        },
        disburseFromTreasury() {
            this.$refs.formDisburse.validate(async (valid) => {
                if (valid) {
                    try {
                        await api.post(`/treasuries/${this.selectedTreasury.id}/disburse`, this.formDisburse);
                        this.$message.success('Disbursement successful');
                        this.dialogDisburseVisible = false;
                        this.resetForm('formDisburse');
                        this.fetchTreasuries();
                    } catch (error) {
                        this.$message.error('Failed to disburse from treasury');
                    }
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
    mounted() {
        this.fetchTreasuries();
    }
};
</script>



<style scoped>
.container-fluid {
    padding: 2rem;
}

.bg-header-table {
    background-color: #4CAF50;
}

.dialog-footer {
    text-align: right;
    padding: 10px 0;
}

.el-form-item {
    margin-bottom: 20px;
}

.el-input,
.el-select {
    width: 100%;
}
</style>
