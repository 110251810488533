<template>
    <div>
      <!-- FullCalendar Component -->
      <FullCalendar :options="calendarOptions" />
  
      <!-- Dialog for Adding/Editing Events -->
      <el-dialog
        :title="isEditing ? 'Edit Event' : 'Add Event'"
        v-model="dialogVisible"
        width="30%"
      >
        <el-form :model="eventForm" label-width="120px">
          <el-form-item label="Title">
            <el-input v-model="eventForm.title"></el-input>
          </el-form-item>
          <el-form-item label="Description">
            <el-input type="textarea" v-model="eventForm.description"></el-input>
          </el-form-item>
          <el-form-item label="Start Date">
            <el-date-picker
              v-model="eventForm.start_date"
              type="datetime"
              placeholder="Select start date and time"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="End Date">
            <el-date-picker
              v-model="eventForm.end_date"
              type="datetime"
              placeholder="Select end date and time"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="Color">
            <el-color-picker v-model="eventForm.color"></el-color-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
  <el-button @click="dialogVisible = false">Cancel</el-button>
  <el-button type="danger" @click="deleteEvent" v-if="isEditing">
    <i class="el-icon-delete"></i> Delete
  </el-button>
  <el-button type="primary" @click="saveEvent">Save</el-button>
</span>
      </el-dialog>
    </div>
  </template>
  <script>
  import axios from 'axios';
  import FullCalendar from '@fullcalendar/vue3';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import interactionPlugin from '@fullcalendar/interaction';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import { ElMessage, ElDialog, ElForm, ElFormItem, ElInput, ElDatePicker, ElColorPicker, ElButton } from 'element-plus';
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default {
    components: {
      FullCalendar,
      ElDialog,
      ElForm,
      ElFormItem,
      ElInput,
      ElDatePicker,
      ElColorPicker,
      ElButton,
    },
    data() {
      return {
        calendarOptions: {
          plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
          initialView: 'dayGridMonth',
          events: [],
          editable: true,
          selectable: true,
          select: this.handleDateSelect,
          eventClick: this.handleEventClick,
          eventDrop: this.handleEventDrop,
          eventResize: this.handleEventResize,
        },
        dialogVisible: false,
        isEditing: false,
        eventForm: {
          id: null,
          title: '',
          description: '',
          start_date: '',
          end_date: '',
          color: '#3b82f6', // Default color
        },
      };
    },
    mounted() {
      this.fetchEvents();
    },
    methods: {
      async fetchEvents() {
        try {
          const response = await api.get('/events');
          this.calendarOptions.events = response.data.map((event) => ({
            id: event.id,
            title: event.title,
            start: event.start_date,
            end: event.end_date,
            color: event.color,
          }));
        } catch (error) {
          ElMessage.error('Failed to fetch events');
        }
      },

      async deleteEvent() {
    try {
      // Confirm deletion with the user
      const confirmDelete = confirm('Are you sure you want to delete this event?');
      if (!confirmDelete) return;

      // Call the API to delete the event
      await api.delete(`/events/${this.eventForm.id}`);
      ElMessage.success('Event deleted successfully');

      // Close the dialog and refresh the events
      this.dialogVisible = false;
      this.fetchEvents();
    } catch (error) {
      ElMessage.error('Failed to delete event');
    }
  },
  
      handleDateSelect(selectInfo) {
        this.eventForm = {
          id: null,
          title: '',
          description: '',
          start_date: selectInfo.startStr,
          end_date: selectInfo.endStr,
          color: '#3b82f6',
        };
        this.isEditing = false;
        this.dialogVisible = true;
      },
  
      async saveEvent() {
        try {
          if (this.isEditing) {
            // Update existing event
            await api.put(`/events/${this.eventForm.id}`, this.eventForm);
            ElMessage.success('Event updated successfully');
          } else {
            // Create new event
            await api.post('/events', this.eventForm);
            ElMessage.success('Event created successfully');
          }
          this.dialogVisible = false;
          this.fetchEvents(); // Refresh events
        } catch (error) {
          ElMessage.error('Failed to save event');
        }
      },
  
      handleEventClick(clickInfo) {
        this.eventForm = {
          id: clickInfo.event.id,
          title: clickInfo.event.title,
          description: clickInfo.event.extendedProps.description || '',
          start_date: clickInfo.event.startStr,
          end_date: clickInfo.event.endStr,
          color: clickInfo.event.backgroundColor || '#3b82f6',
        };
        this.isEditing = true;
        this.dialogVisible = true;
      },
  
      async handleEventDrop(dropInfo) {
        try {
          await api.put(`/events/${dropInfo.event.id}`, {
            start_date: dropInfo.event.startStr,
            end_date: dropInfo.event.endStr,
          });
        } catch (error) {
          ElMessage.error('Failed to update event');
        }
      },
  
      async handleEventResize(resizeInfo) {
        try {
          await api.put(`/events/${resizeInfo.event.id}`, {
            start_date: resizeInfo.event.startStr,
            end_date: resizeInfo.event.endStr,
          });
        } catch (error) {
          ElMessage.error('Failed to update event');
        }
      },
    },
  };
  </script>

<style scoped>
.el-dialog__body {
  padding: 20px;
}
.el-form-item {
  margin-bottom: 20px;
}
</style>