<template>
    <el-card class="form-container">
      <h2 class="title">Add Parent & Students</h2>

      <el-upload
      class="upload-demo"
      action=""
      :show-file-list="false"
      :before-upload="beforeUpload"
      :http-request="uploadFile"
    >
      <el-button type="primary" :loading="uploading">
        <el-icon><Upload /></el-icon> Upload Excel File
      </el-button>
    </el-upload>
    <p v-if="uploadMessage" :class="uploadSuccess ? 'success-text' : 'error-text'">{{ uploadMessage }}</p>

  
      <el-form ref="parentForm" :model="form" label-width="150px">
        <!-- Parent Information -->
        <el-collapse v-model="activeSections">
          <el-collapse-item title="Parent Information" name="parent">
            <el-card class="section-card">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="First Name" required :error="errors['parent.first_name'] ? errors['parent.first_name'][0] : ''">
                    <el-input v-model="form.parent.first_name" placeholder="Enter parent's first name" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Last Name" required :error="errors['parent.last_name'] ? errors['parent.last_name'][0] : ''">
                    <el-input v-model="form.parent.last_name" placeholder="Enter parent's last name" />
                  </el-form-item>
                </el-col>
              </el-row>
  
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Phone Number 1" required :error="errors['parent.phone_number_one'] ? errors['parent.phone_number_one'][0] : ''">
                    <el-input v-model="form.parent.phone_number_one" placeholder="Enter primary phone number" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Phone Number 2" :error="errors['parent.phone_number_two'] ? errors['parent.phone_number_two'][0] : ''">
                    <el-input v-model="form.parent.phone_number_two" placeholder="Enter secondary phone number" />
                  </el-form-item>
                </el-col>
              </el-row>

              
  
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Email" required :error="errors['parent.email'] ? errors['parent.email'][0] : ''">
                    <el-input v-model="form.parent.email" placeholder="Enter email" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="City" required :error="errors['parent.city'] ? errors['parent.city'][0] : ''">
                    <el-input v-model="form.parent.city" placeholder="Enter city" />
                  </el-form-item>
                </el-col>
              </el-row>
  
              <el-form-item label="Address" required :error="errors['parent.address'] ? errors['parent.address'][0] : ''">
                <el-input type="textarea" v-model="form.parent.address" placeholder="Enter address" />
              </el-form-item>
  
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="National Number" required :error="errors['parent.national_number'] ? errors['parent.national_number'][0] : ''">
                    <el-input v-model="form.parent.national_number" placeholder="Enter national number" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Passport Number" :error="errors['parent.passport_num'] ? errors['parent.passport_num'][0] : ''">
                    <el-input v-model="form.parent.passport_num" placeholder="Enter passport number" />
                  </el-form-item>
                </el-col>
              </el-row>
  
              <el-form-item label="PIN Code" :error="errors['parent.pin_code'] ? errors['parent.pin_code'][0] : ''">
                <el-input v-model="form.parent.pin_code" placeholder="Enter PIN code" />
              </el-form-item>
  
              <el-form-item label="Images Info" :error="errors['parent.images_info'] ? errors['parent.images_info'][0] : ''">
                <el-input v-model="form.parent.images_info" placeholder="Enter images info" />
              </el-form-item>

              <el-row :gutter="20">
  <el-col :span="12">
    <el-form-item label="Note" :error="errors['parent.note'] ? errors['parent.note'][0] : ''">
      <el-input v-model="form.parent.note" placeholder="Enter note" />
    </el-form-item>
  </el-col>
  <el-col :span="12">
    <el-form-item label="Discount" :error="errors['parent.discount'] ? errors['parent.discount'][0] : ''">
      <el-switch v-model="form.parent.discount" active-text="Yes" inactive-text="No" />
    </el-form-item>
  </el-col>
</el-row>
  
              <!-- File Uploads -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="ID Image" :error="errors['parent.id_image'] ? errors['parent.id_image'][0] : ''">
                    <el-upload
                      class="upload-box"
                      :before-upload="handleParentIdImageUpload"
                      list-type="picture-card"
                    >
                      <el-icon><Plus /></el-icon>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Passport Image" :error="errors['parent.passport_image'] ? errors['parent.passport_image'][0] : ''">
                    <el-upload
                      class="upload-box"
                      :before-upload="handleParentPassportImageUpload"
                      list-type="picture-card"
                    >
                      <el-icon><Plus /></el-icon>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-collapse-item>
  
          <!-- Student Details -->
          <el-collapse-item v-for="(student, index) in form.students" :key="index" :title="`Student ${index + 1}`" :name="`student-${index}`">
            <el-card class="section-card">
              <el-form-item label="Study Year" required :error="errors[`students.${index}.study_year_id`] ? errors[`students.${index}.study_year_id`][0] : ''">
                <el-select v-model="student.study_year_id" placeholder="Select study year">
                  <el-option
                    v-for="studyYear in studyYears"
                    :key="studyYear.id"
                    :label="studyYear.year_study"
                    :value="studyYear.id"
                  ></el-option>
                </el-select>
              </el-form-item>
  
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Student Name" required :error="errors[`students.${index}.name`] ? errors[`students.${index}.name`][0] : ''">
                    <el-input v-model="student.name" placeholder="Enter student's name" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Arabic Name" :error="errors[`students.${index}.arabic_name`] ? errors[`students.${index}.arabic_name`][0] : ''">
                    <el-input v-model="student.arabic_name" placeholder="Enter student's Arabic name" />
                  </el-form-item>
                </el-col>
              </el-row>
  
              <el-row :gutter="20">
  <el-col :span="12">
    <el-form-item label="Date of Birth" required :error="errors[`students.${index}.date_of_birth`] ? errors[`students.${index}.date_of_birth`][0] : ''">
      <input
        type="date"
        v-model="student.date_of_birth"
        placeholder="Select date"
     
      />
    </el-form-item>
  </el-col>
  <el-col :span="12">
    <el-form-item label="Gender" required :error="errors[`students.${index}.gender`] ? errors[`students.${index}.gender`][0] : ''">
      <el-select v-model="student.gender" placeholder="Select gender">
        <el-option label="Male" value="male"></el-option>
        <el-option label="Female" value="female"></el-option>
      </el-select>
    </el-form-item>
  </el-col>
</el-row>
  
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Class" required :error="errors[`students.${index}.class_id`] ? errors[`students.${index}.class_id`][0] : ''">
                    <el-select v-model="student.class_id" @change="fetchSections(index)" placeholder="Select class">
                      <el-option v-for="schoolClass in classes" :key="schoolClass.id" :label="schoolClass.name" :value="schoolClass.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Section" required :error="errors[`students.${index}.section_id`] ? errors[`students.${index}.section_id`][0] : ''">
                    <el-select v-model="student.section_id" placeholder="Select section">
                      <el-option v-for="section in student.sections" :key="section.id" :label="section.name" :value="section.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
  
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Passport Number" :error="errors[`students.${index}.passport_num`] ? errors[`students.${index}.passport_num`][0] : ''">
                    <el-input v-model="student.passport_num" placeholder="Enter passport number" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="National Number" :error="errors[`students.${index}.national_number`] ? errors[`students.${index}.national_number`][0] : ''">
                    <el-input v-model="student.national_number" placeholder="Enter national number" />
                  </el-form-item>
                </el-col>
              </el-row>
  
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Has Books" required :error="errors[`students.${index}.has_books`] ? errors[`students.${index}.has_books`][0] : ''">
                    <el-select v-model="student.has_books" placeholder="Select">
                      <el-option label="Yes" value="yes"></el-option>
                      <el-option label="No" value="no"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Daily Allowed Purchase Value" :error="errors[`students.${index}.daily_allowed_purchase_value`] ? errors[`students.${index}.daily_allowed_purchase_value`][0] : ''">
                    <el-input v-model="student.daily_allowed_purchase_value" placeholder="Enter value" />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="20">
  <el-col :span="12">
    <el-form-item label="Note" :error="errors[`students.${index}.note`] ? errors[`students.${index}.note`][0] : ''">
      <el-input v-model="student.note" placeholder="Enter note" />
    </el-form-item>
  </el-col>
  <el-col :span="12">
    <el-form-item label="Subscriptions" :error="errors[`students.${index}.subscriptions`] ? errors[`students.${index}.subscriptions`][0] : ''">
      <el-input v-model="student.subscriptions" placeholder="Enter subscriptions" />
    </el-form-item>
  </el-col>
</el-row>

<el-row :gutter="20">
  <el-col :span="12">
    <el-form-item label="Missing" :error="errors[`students.${index}.missing`] ? errors[`students.${index}.missing`][0] : ''">
      <el-input v-model="student.missing" placeholder="Enter missing" />
    </el-form-item>
  </el-col>
</el-row>
  
              <el-form-item label="Student Registration Number (SRN)" :error="errors[`students.${index}.srn`] ? errors[`students.${index}.srn`][0] : ''">
                <el-input v-model="student.srn" placeholder="Enter SRN" />
              </el-form-item>
  
              <el-form-item label="Photo" :error="errors[`students.${index}.photo`] ? errors[`students.${index}.photo`][0] : ''">
                <el-upload :before-upload="handleStudentPhotoUpload">
                  <el-button type="primary">Upload Photo</el-button>
                </el-upload>
              </el-form-item>
  
              <el-button v-if="index > 0" type="danger" @click="removeStudent(index)">Remove Student</el-button>
            </el-card>
          </el-collapse-item>
        </el-collapse>
  
        <!-- Add Student Button -->
        <el-button type="primary" @click="addStudent" class="add-student-btn">+ Add Student</el-button>
  
        <!-- Submit Button -->
        <el-form-item class="submit-btn">
          <el-button type="success" @click="submitForm">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </template>
  
  <script>
  import axios from 'axios';
  import { ElMessage } from 'element-plus';
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default {
    data() {
      return {
        form: {
          parent: {
            first_name: '',
            last_name: '',
            phone_number_one: '',
            phone_number_two: '',
            email: '',
            city: '',
            address: '',
            national_number: '',
            passport_num: '',
            pin_code: '',
            id_image: null,
            passport_image: null,
            images_info: '',
            note: '', // Add this line
            discount: false, // Add this line
          },
          students: [
            {
              name: '',
              arabic_name: '',
              date_of_birth: '', // Default to today's date

              gender: '',
              class_id: '',
              section_id: '',
              sections: [],
              passport_num: '',
              national_number: '',
              has_books: '',
              daily_allowed_purchase_value: '',
              srn: '',
              photo: null,
              study_year_id: null, // Add study_year_id for each student
              note: '', // Add this line
          subscriptions: '', // Add this line
          missing: '', // Add this line
            },
          ],
        },
        classes: [],
        activeSections: ['parent', 'student-0'], // Open parent and first student by default
        errors: {}, // Add this line
        uploading: false,
      uploadMessage: '',
      uploadSuccess: false,
      };
    },
    methods: {
      beforeUpload(file) {
      const isExcel =
        file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "text/csv";
      if (!isExcel) {
        this.uploadMessage = "Only Excel (XLSX, CSV) files are allowed.";
        this.uploadSuccess = false;
        return false;
      }
      this.uploadMessage = "";
      return true;
    },
    async uploadFile({ file }) {
      this.uploading = true;
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await api.post("/import-parents-students", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        this.uploadMessage = "File uploaded successfully!";
        this.uploadSuccess = true;
        ElMessage.success(response.data.message);
      } catch (error) {
        this.uploadMessage = "File upload failed. Please check the file format.";
        this.uploadSuccess = false;
        ElMessage.error("Error uploading file.");
      } finally {
        this.uploading = false;
      }
    },
      async fetchClasses() {
        try {
          const response = await api.get('/classes');
          this.classes = response.data;
        } catch (error) {
          ElMessage.error('Failed to fetch classes');
        }
      },
      async fetchSections(index) {
        const classId = this.form.students[index].class_id;
        if (!classId) return;
  
        try {
          const response = await api.get('/sections', { params: { class_id: classId } });
          this.form.students[index].sections = response.data;
        } catch (error) {
          ElMessage.error('Failed to fetch sections');
        }
      },
      addStudent() {
        this.form.students.push({
          name: '',
          arabic_name: '',
          date_of_birth: '',
          gender: '',
          class_id: '',
          section_id: '',
          sections: [],
          passport_num: '',
          national_number: '',
          has_books: '',
          daily_allowed_purchase_value: '',
          srn: '',
          photo: null,
          study_year_id: null, // Add study_year_id for new student
          note: '', // Add this line
    subscriptions: '', // Add this line
    missing: '', // Add this line

        });
        this.activeSections.push(`student-${this.form.students.length - 1}`);
      },
      async fetchStudyYears() {
      try {
        const response = await api.get('/study-years');
        this.studyYears = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch study years');
      }
    },
      removeStudent(index) {
        this.form.students.splice(index, 1);
        this.activeSections = this.activeSections.filter(section => section !== `student-${index}`);
      },
      handleParentIdImageUpload(file) {
        this.form.parent.id_image = file;
        return false; // Prevent automatic upload
      },
      handleParentPassportImageUpload(file) {
        this.form.parent.passport_image = file;
        return false; // Prevent automatic upload
      },
      handleStudentPhotoUpload(file) {
        this.form.students[this.form.students.length - 1].photo = file;
        return false; // Prevent automatic upload
      },
      async submitForm() {
  try {
    const payload = {
      parent: {
        ...this.form.parent,
        discount: Boolean(this.form.parent.discount), // Ensure discount is a boolean
      },
      students: this.form.students.map(student => ({
        ...student,
        date_of_birth: student.date_of_birth,
        study_year_id: student.study_year_id,
        note: student.note,
        subscriptions: student.subscriptions,
        missing: student.missing,
      })),
    };

    console.log('Payload:', payload); // Log the payload

    const response = await api.post('/parents-with-students', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    ElMessage.success('Parent and students added successfully!');
    this.resetForm();
    this.errors = {};
  } catch (error) {
    console.error('Error details:', error); // Log the full error
    if (error.response && error.response.status === 422) {
      this.errors = error.response.data.errors;
    } else {
      ElMessage.error('Failed to submit form');
    }
  }
},
      resetForm() {
        this.form = {
          parent: {
            first_name: '',
            last_name: '',
            phone_number_one: '',
            phone_number_two: '',
            email: '',
            city: '',
            address: '',
            national_number: '',
            passport_num: '',
            pin_code: '',
            id_image: null,
            passport_image: null,
            images_info: '',
            note: '', // Add this line
            discount: false, // Add this line
          },
          students: [
            {
              name: '',
              arabic_name: '',
              date_of_birth: '',
              gender: '',
              class_id: '',
              section_id: '',
              sections: [],
              passport_num: '',
              national_number: '',
              has_books: '',
              daily_allowed_purchase_value: '',
              srn: '',
              photo: null,
              study_year_id: null, // Reset study_year_id
              note: '', // Add this line
        subscriptions: '', // Add this line
        missing: '', // Add this line

            },
          ],
        };
        this.errors = {}; // Clear errors

        this.activeSections = ['parent', 'student-0'];
      },
    },
    mounted() {
      this.fetchClasses();
      this.fetchStudyYears(); // Fetch study years when the component is mounted

    },
  };
  </script>
  
  <style scoped>
  .form-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .section-card {
    margin-bottom: 10px;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
  }
  
  .upload-box {
    display: flex;
    justify-content: center;
  }
  
  .add-student-btn {
    width: 100%;
    margin-top: 15px;
  }
  
  .submit-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .el-form-item__error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
  </style>