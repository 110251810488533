<template>
  <div class="calendar-container">
    <!-- Notes Section -->
    <div class="calendar-notes">
      <div class="note-item">
        <strong class="color-box attendance-day"></strong>
        <strong class="note-text">الأيام الخضراء: حضور كامل (حضور وخروج).</strong>
      </div>
      <div class="note-item">
        <strong class="color-box absence-day"></strong>
        <strong class="note-text">الأيام الحمراء: غياب.</strong>
      </div>
      <div class="note-item">
        <strong class="color-box partial-attendance-day"></strong>
        <strong class="note-text">الأيام البرتقالية: حضور فقط (يعتبر غياب).</strong>
      </div>
      <div class="note-item">
        <el-icon><info-filled /></el-icon>
        <strong class="note-text">سيتم خصم أيام التأخير والغياب تلقائياً وفقاً لسياسة الرواتب.</strong>
      </div>
    </div>

    <!-- Calendar Grid -->
    <div class="calendar-grid">
      <div 
        v-for="day in daysOfMonth" 
        :key="day.date" 
        class="calendar-day"
        :class="{ 
          'attendance-day': isAttendanceDay(day.date), 
          'absence-day': isAbsent(day.date), 
          'partial-attendance-day': day.isPartialAttendance, 
          'empty-day': !day.date 
        }"
        @click="day.date && openDayDetails(day)"
      >
        <strong v-if="day.date">{{ formatDate(day.date) }}</strong>
      </div>
    </div>

    <!-- Day Details Dialog -->
    <el-dialog v-model="dayDialogVisible" title="تفاصيل اليوم">
      <p><strong>التاريخ:</strong> {{ selectedDay.date }}</p>
      <p><strong>حضور:</strong> {{ selectedDay.check_in || "لم يتم حضور" }}</p>
      <p><strong>انصراف:</strong> {{ selectedDay.check_out || "لم يتم انصراف" }}</p>

      <el-select 
  v-if="isAbsent(selectedDay.date) || selectedDay.isPartial" 
  v-model="selectedDay.absence_type_id" 
  placeholder="اختر نوع الغياب" 
  @change="updateAbsence(selectedDay)"
>
  <el-option 
    v-for="type in absenceTypes" 
    :key="type.id" 
    :label="type.name" 
    :value="type.id"
  ></el-option>
</el-select>


      <el-button type="primary" @click="dayDialogVisible = false">إغلاق</el-button>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { InfoFilled } from '@element-plus/icons-vue'; // Import the info icon

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  components: {
    InfoFilled, // Register the info icon
  },
  props: [
    "employeeId", 
    "month", 
    "year", 
    "attendanceRecords", 
    "absences", 
    "absenceTypes"
  ],
  data() {
    return {
      selectedMonth: this.month,
      selectedYear: this.year,
      daysOfMonth: [],
      dayDialogVisible: false,
      selectedDay: {},
      months: [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
      ],
    };
  },
  watch: {
    month(newMonth) {
      this.selectedMonth = newMonth;
      this.generateDays();
    },
    year(newYear) {
      this.selectedYear = newYear;
      this.generateDays();
    },
    attendanceRecords() {
      this.generateDays();
    },
    absences() {
      this.generateDays();
    },
  },
  methods: {
    async updateAbsence(day) {
  try {
    await this.$confirm(`Are you sure you want to record this absence for ${day.date}?`, 'Confirm Absence', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warning'
    });

    await api.post('/absences', {
      employee_id: this.employeeId,
      date: day.date,
      absence_type_id: day.absence_type_id,
    });

    // إذا كان نوع الغياب هو "أذن من الادارة" (مثلاً absence_type_id === 5)
    if (day.absence_type_id === 5) {
      const idx = this.daysOfMonth.findIndex(d => d.date === day.date);
      if (idx !== -1) {
        this.daysOfMonth[idx].isManagementPermission = true;
        this.daysOfMonth[idx].isAbsent = false;
        this.daysOfMonth[idx].isPartialAttendance = false;
      }
    }
    
    this.$notify({
      type: 'success',
      title: 'تمت العملية',
      message: `تم تسجيل نوع الغياب لليوم ${day.date}.`,
    });
    // إرسال حدث للتحديث في حالة استرجاع بيانات جديدة من الأب
    this.$emit('refresh-data');
  } catch (error) {
    if (error !== 'cancel') {
      console.error('Error updating absence:', error);
      this.$notify({
        type: 'error',
        title: 'خطأ',
        message: 'فشل في تسجيل الغياب. يرجى المحاولة مرة أخرى.',
      });
    }
  }
},
    formatDate(date) {
      if (!date) return "تاريخ غير صحيح";
      const formatted = new Date(date);
      return formatted.toLocaleDateString("en-US", { weekday: "short", day: "numeric" });
    },
    generateDays() {
  const totalDaysInMonth = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
  this.daysOfMonth = [];

  for (let day = 1; day <= totalDaysInMonth; day++) {
    const formattedDate = `${this.selectedYear}-${String(this.selectedMonth).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    
    const attendanceRecord = this.attendanceRecords.find(record => record.date.trim() === formattedDate);
    const absence = this.absences.find(absence => absence.date === formattedDate);
    // إذا كان سجل الحضور يحتوي على absence_type_id === 5 أو سجل الغياب كذلك، نعتبر اليوم بإذن إدارة
    const isManagementPermission = (attendanceRecord && attendanceRecord.absence_type_id === 5) || (absence && absence.absence_type_id === 5);
    const isPartialAttendance = (!isManagementPermission) && attendanceRecord?.check_in && !attendanceRecord?.check_out;

    this.daysOfMonth.push({
      date: formattedDate,
      check_in: attendanceRecord?.check_in || "No Check-in",
      check_out: attendanceRecord?.check_out || "No Check-out",
      isAbsent: (absence && !isManagementPermission),
      isPartialAttendance: isPartialAttendance,
      isManagementPermission: isManagementPermission,
    });
  }
}
,
    isAttendanceDay(date) {
      const day = this.daysOfMonth.find(day => day.date === date);
      return (day?.check_in && day?.check_out) || day?.isManagementPermission;
    },
    isAbsent(date) {
      const day = this.daysOfMonth.find(day => day.date === date);
      return day?.isAbsent;
    },
    async openDayDetails(day) {
  if (!day.date) return;

  const selectedDate = day.date.trim();
  const attendanceRecord = this.attendanceRecords.find(record => record.date.trim() === selectedDate);
  const absence = this.absences.find(absence => absence.date === selectedDate);

  // نستخدم الخاصية isPartialAttendance من العنصر day إذا وُجد
  this.selectedDay = {
    date: selectedDate,
    check_in: attendanceRecord?.check_in ?? "No Check-in",
    check_out: attendanceRecord?.check_out ?? "No Check-out",
    absence_type_id: absence?.absence_type_id ?? null,
    isPartial: day.isPartialAttendance || false,
  };

  this.dayDialogVisible = true;
}

  },
  mounted() {
    this.generateDays();
  },
};
</script>

<style scoped>
.calendar-container {
  padding: 15px;
}

.calendar-notes {
  margin-bottom: 20px;
}

.note-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.color-box {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  border-radius: 4px;
}

.attendance-day {
  background-color: #d4f4dd; /* Green */
}

.absence-day {
  background-color: #f4d4d4; /* Red */
}

.partial-attendance-day {
  background-color: #ffd699; /* Orange */
}

.note-text {
  margin-right: 8px;
  font-size: 14px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.calendar-day {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
  cursor: pointer;
}

.attendance-day {
  background-color: #d4f4dd; /* Green */
}

.absence-day {
  background-color: #f4d4d4; /* Red */
}

.partial-attendance-day {
  background-color: #ffd699; /* Orange */
}

.empty-day {
  background-color: transparent;
  border: none;
}
</style>