<template>
  <div class="container">
    <el-card class="mb-4">
      <h3 class="text-center">فواتير المطبخ</h3>
      <div class="col-12 row">
        <div class="col-2">
          <el-select v-model="buyerType" @change="fetchCustomers" placeholder="نوع الزبون" class="mb-0 col-12 p-0">
            <el-option label="موظف" value="employee" />
            <el-option label="طالب" value="student" />
            <el-option label="زائر" value="visitor" />
          </el-select>
        </div>
        <div class="col-4">
          <el-select filterable v-model="selectedCustomer" placeholder="الزبون" class="mb-0 col-12 p-0">
            <el-option v-for="item in customers" :key="item.id" :label="item.id + ' - ' + item.name" :value="item.id" />
          </el-select>
        </div>
        <div class="col-2">
          <el-select filterable v-model="selectedPaymentMethod" placeholder="طريقة الدفع" class="mb-0 col-12 p-0">
            <el-option v-for="item in paymentMethods" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>

        <div class="col-2">
          <el-button @click="fetchBills" type="primary">
            <i class="material-icons-round opacity-10 fs-5">search</i>
          </el-button>
        </div>
      </div>
      <div class="col-12 row mt-2">

        <div class="col-2">
          <el-input v-model="billNumber" placeholder="رقم الفاتوره" class="mb-0 col-12 p-0" />

        </div>
        <div class="col-4 row col-4 row  px-4" style="padding-left: 0px !important;">

          <!-- Select for day -->
          <div class="col-3 p-0">
            <el-select v-model="selectedDay" placeholder="اليوم" class="mb-0 col-12 p-0">
              <el-option v-for="day in days" :key="day" :label="day" :value="day" />
            </el-select>
          </div>

          <!-- Select for month -->
          <div class="col-4 p-0">
            <el-select v-model="selectedMonth" placeholder="الشهر" class="mb-0 col-12 p-0">
              <el-option v-for="month in months" :key="month.value" :label="month.label" :value="month.value" />
            </el-select>
          </div>

          <!-- Select for year -->
          <div class="col-5 p-0">
            <el-select v-model="selectedYear" placeholder="السنة" class="mb-0 col-12 p-0">
              <el-option v-for="year in years" :key="year" :label="year" :value="year" />
            </el-select>
          </div>
        </div>
        <div class="col-4 row col-4 row  px-5" style="padding-left: 0px !important;">

          <!-- Select for day -->
          <div class="col-3 p-0">
            <el-select v-model="selectedEndDay" placeholder="اليوم" class="mb-0 col-12 p-0">
              <el-option v-for="day in days" :key="day" :label="day" :value="day" />
            </el-select>
          </div>

          <!-- Select for month -->
          <div class="col-4 p-0">
            <el-select v-model="selectedEndMonth" placeholder="الشهر" class="mb-0 col-12 p-0">
              <el-option v-for="month in months" :key="month.value" :label="month.label" :value="month.value" />
            </el-select>
          </div>

          <!-- Select for year -->
          <div class="col-5 p-0">
            <el-select v-model="selectedEndYear" placeholder="السنة" class="mb-0 col-12 p-0">
              <el-option v-for="year in years" :key="year" :label="year" :value="year" />
            </el-select>
          </div>
        </div>

      </div>
    </el-card>
    <!-- pagination section -->

    <el-pagination v-if="Bills.length" background layout="prev, pager, next, jumper" :total="totalBills"
      :page-size="pageSize" :current-page="currentPage" @current-change="handlePageChange" />

    <!-- end of pagination section -->
    <!-- جدول الفواتير -->
    <el-table v-if="Bills.length" :data="Bills" style="width: 100%" class="mt-4">
      <el-table-column prop="created_at" label="التاريخ" width="120">
        <template #default="{ row }">
          {{ new Date(row.created_at).toLocaleDateString('ar-LY', { year: 'numeric', month: '2-digit', day: '2-digit' })
          }}
        </template>
      </el-table-column>
      <el-table-column label="الوقت" width="120">
        <template #default="{ row }">
          {{ new Date(row.created_at).toLocaleTimeString('ar-LY', { hour: '2-digit', minute: '2-digit' }) }}
        </template>
      </el-table-column>
      <el-table-column prop="bill_number" label="رقم الفاتورة" width="120"></el-table-column>
      <el-table-column label="طريقة الدفع" width="120">
        <template #default="{ row }">

          <p v-if="row.payment_method == 'cash'">نقدي</p>
          <p v-if="row.payment_method == 'wallet'">محفظه</p>
          <p v-if="row.payment_method == 'subscription'">اشتراك</p>
        </template>
      </el-table-column>
      <el-table-column label="الإسم" width="180">
        <template #default="{ row }">
          <p v-if="row.employee">{{ row.employee.name }}</p>
          <p v-if="row.student">{{ row.student.name }}</p>
        </template>
      </el-table-column>
      <el-table-column label="اجمالي قيمة الفاتورة " width="120">
        <template #default="{ row }">
          {{ calculateThebill(row.items) }}

        </template>
      </el-table-column>
      <el-table-column label="#" width="120">
        <template #default="{ row }">
          <div class="d-flex justify-content-around">
            <button @click="showTheBill(row)" class="btn btn-sm btn-outline-info p-1 px-2">
              <i class="material-icons-round opacity-10 fs-6">visibility</i>
            </button>
            <button class="btn btn-sm btn-info p-1 px-2" @click="openEditBillDailog(row)">
              <i class="material-icons-round opacity-10 fs-6 ">edit</i>
            </button>
            <!-- <button v-if="isToday(row.created_at)" class="btn btn-sm btn-info p-1 px-2"
              @click="openEditBillDailog(row)">
              <i class="material-icons-round opacity-10 fs-6 ">edit</i>
            </button> -->
          </div>

        </template>

      </el-table-column>
      <el-table-column label="#" width="100">
        <template #default="{ row }">

          <button class="btn btn-sm btn-danger p-1 px-2">
            <i class="material-icons-round opacity-10 fs-6 ">delete</i>

          </button>


        </template>

      </el-table-column>
    </el-table>

    <!-- edit html section  -->
    <el-dialog v-model="showEditDialog" title="Edit Bill" width="50%">
      <div v-if="editingBill">
        <el-table :data="editingBill.items" style="width: 100%">
          <el-table-column prop="meal.name" label="الصنف" width="180"></el-table-column>
          <el-table-column label="الكمية" width="120">
            <template #default="{ row }">


              <el-button @click="if (row.quantity > 1) { row.quantity--; } else { row.quantity = 0 }"
                type="danger">-</el-button>

              <label>{{ row.quantity }}</label>
            </template>
          </el-table-column>
          <el-table-column prop="piece_price" label="سعر القطعة" width="120"></el-table-column>
          <el-table-column label="الإجمالي" width="120">
            <template #default="{ row }">
              {{ row.quantity * row.piece_price }}
            </template>
          </el-table-column>
        </el-table>
        <p class="text-bold text-align-left">
          الإجمالي : {{ calculateTheEditenbill(editingBill.items) }}
        </p>
      </div>

      <span slot="footer" class="dialog-footer d-flex justify-content-end">
        <!-- <el-button @click="cancelEdit">إلغاء</el-button> -->
        <el-button type="primary" @click="saveEditedBill">حفظ</el-button>
      </span>
    </el-dialog>


    <!-- edit html section -->

  </div>
</template>

<script>
import axios from 'axios';
import {
  ElCard,
  ElSelect,
  ElOption,
  ElTable,
  ElTableColumn,
  ElButton,
  ElMessage,
  ElMessageBox
} from 'element-plus';
import { NULL } from 'sass';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

// Interceptors for auth token
api.interceptors.request.use((config) => {
  document.querySelectorAll('button').forEach((button) => {
    button.setAttribute('disabled', '');  // إضافة الخاصية فقط
  });
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
// دالة بعد اكتمال الطلب
api.interceptors.response.use((response) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  ;

  // يمكنك هنا التعامل مع البيانات بعد اكتمال الطلب
  // مثال: تعديل البيانات قبل إرجاعها

  return response;  // إرجاع الاستجابة كما هي
}, (error) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  // في حالة حدوث خطأ بعد اكتمال الطلب
  console.error("خطأ في الاستجابة:", error);
  return Promise.reject(error);
});

export default {
  components: {
    ElCard,
    ElSelect,
    ElOption,
    ElMessageBox,
    ElMessage,
    ElTable,
    ElTableColumn,
    ElButton,
  },
  data() {
    return {
      totalBills: 0, // إجمالي عدد الفواتير
      currentPage: 1, // الصفحة الحالية
      pageSize: 10,   // عدد النتائج لكل صفحة
      showenBillElement: '',
      showenBill: '',
      editingBill: [],
      showEditDialog: false,
      Bills: [],
      billNumber: null,
      selectedPaymentMethod: "",
      paymentMethods: [

        { label: 'الكل', value: 'all' },
        { label: 'نقدي', value: 'cash' },
        { label: 'محفظه', value: 'wallet' },
        { label: 'اشتراك', value: 'subscription' },
      ],
      buyerType: 'employee',
      selectedCustomer: '',
      customers: [],
      visitorData: [{ id: null, name: null }],
      selectedDay: String(new Date().getDate()).padStart(2, '0'),
      selectedMonth: `${new Date().getMonth() + 1}`.padStart(2, '0'),
      selectedYear: new Date().getFullYear(),


      selectedEndDay: String(new Date().getDate()).padStart(2, '0'),
      selectedEndMonth: `${new Date().getMonth() + 1}`.padStart(2, '0'),
      selectedEndYear: new Date().getFullYear(),
      days: Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0')), // ["01", "02", ..., "31"]
      months: [
        { label: 'يناير', value: '01' },
        { label: 'فبراير', value: '02' },
        { label: 'مارس', value: '03' },
        { label: 'أبريل', value: '04' },
        { label: 'مايو', value: '05' },
        { label: 'يونيو', value: '06' },
        { label: 'يوليو', value: '07' },
        { label: 'أغسطس', value: '08' },
        { label: 'سبتمبر', value: '09' },
        { label: 'أكتوبر', value: '10' },
        { label: 'نوفمبر', value: '11' },
        { label: 'ديسمبر', value: '12' },
      ],
      years: Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i), // Last 10 years
    };
  },
  methods: {


    // edit functions section

    isToday(date) {
      const today = new Date().toISOString().split('T')[0]; // الحصول على تاريخ اليوم
      const billDate = new Date(date).toISOString().split('T')[0]; // الحصول على تاريخ الفاتورة
      return today === billDate; // مقارنة التواريخ
    },

    async openEditBillDailog(bill) {
      // Clone the selected bill data into a temporary editing object

      this.editingBill = JSON.parse(JSON.stringify(bill));
      this.showEditDialog = true; // Show the edit dialog
    },

    async saveEditedBill() {
      try {
        // Make API call to save updated bill data
        const response = await api.put(`/update-bill/${this.editingBill.id}`, {
          items: this.editingBill.items, // Send only the updated items if needed
          bill_id: this.editingBill.id,
          created_at: this.editingBill.created_at,
        });
        this.Bills = [];
        this.fetchBills();
        this.showEditDialog = false;
        this.editingBill = null;
      } catch (error) {

        ElMessage.error("خطأ" + error.response.data.error);
      }
    },

    // cancelEdit() {
    //   // Reset and close the edit dialog without saving
    //   this.editingBill = null;
    //   this.showEditDialog = false;
    // },




    // end of edit functions section
    calculateThebill(items) {
      let result = 0;

      items.forEach(element => {
        result += (element.quantity * element.piece_price);
      });

      return (result);
    },

    calculateTheEditenbill(items) {
      let result = 0;

      items.forEach(element => {
        result += (element.quantity * element.piece_price);
      });

      return (result);
    },

    async openBillDailog() {

      let htmlContent = `
        <style>
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid black; padding: 5px; text-align: center; }
            p { text-align: center; }
        </style>
        <div>
          <center>
            <img style='width:120px;height:50px;' src='${require('@/assets/img/logo-school.png')}' alt='invoice'>
          </center>`;
      if (this.showenBill.employee != null)
        htmlContent += ` <p>المشتري : ${this.showenBill.employee.name}</p>`;
      else if (this.showenBill.student != null)
        htmlContent += ` <p>المشتري : ${this.showenBill.student.name}</p>`;
      else
        htmlContent += ` <p>المشتري : زائر</p>`;

      htmlContent += ` <p>رقم الفاتورة : ${this.showenBill.bill_number}</p>
            <p>نوع الشراء : ${this.showenBill.payment_method}</p>
        </div>
        <table border='1' cellpadding='5' cellspacing='0'>
            <thead>
                <tr>
                    <th>الصنف</th>
                    <th>الكمية</th>
                    <th>سعر القطعه</th>
                    <th>السعر</th>
                </tr>
            </thead>
            <tbody>`;

      // Add each item row in the table
      let totalPriceValue = 0;
      this.showenBill.items.forEach((element) => {
        if (element.quantity > 0) {
          totalPriceValue += (element.piece_price * element.quantity);
          htmlContent += `
                <tr>
                    <td>${element.meal.name}</td>
                    <td>${element.quantity}</td>
                    <td>${element.piece_price}</td>
                    <td>${element.piece_price * element.quantity}</td>
                </tr>`;
        }

      });

      htmlContent += `
            </tbody>
            <tfoot>
                <tr>
                    <td colspan='3' align='right'>Total</td>
                    <td>${totalPriceValue}</td>
                </tr>
            </tfoot>
        </table>`;

      // Display the ElMessageBox with a custom "Print" button
      ElMessageBox.confirm(
        htmlContent,
        {
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Print',
          cancelButtonText: 'Close',
          callback: (action) => {
            if (action === 'confirm') {
              this.printHtmlContent(htmlContent);
            }
          }
        }
      );
    },
    // Function to print only the htmlContent using an iframe
    printHtmlContent(htmlContent) {
      const iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.style.border = 'none';
      document.body.appendChild(iframe);

      const iframeDoc = iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(htmlContent);
      iframeDoc.close();

      // Print and remove iframe after printing
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    },
    async showTheBill(showenBillElement) {

      try {
        const response = await api.get('/show-bill', {
          params: {
            bill_id: showenBillElement.id,
            buyer_type: showenBillElement.buyer_type
          }

        });
        this.showenBill = response.data;
        this.openBillDailog();

      } catch (error) {
        console.error("Error fetching bill:", error);
      }
    },
    async fetchCustomers() {
      this.selectedCustomer = null;
      this.customers = "";
      if (this.buyerType !== 'visitor') {
        const endpoint = this.buyerType === 'student'
          ? '/students-customers'
          : '/employees-customers';
        try {
          const response = await api.get(endpoint);
          this.customers = response.data;
        } catch (error) {
          console.error('Error fetching customers:', error);
        }
      } else {
        this.customers = this.visitorData;
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchBills(); // إعادة جلب البيانات بناءً على الصفحة المحددة
    },
    async fetchBills() {
      this.Bills = [];
      try {
        const response = await api.post('/fetch-bills', {
          buyer_type: this.buyerType,
          buyer_id: this.selectedCustomer,
          payment_method: this.selectedPaymentMethod,
          day: this.selectedDay,
          month: this.selectedMonth,
          year: this.selectedYear,
          selectedDate: `${this.selectedYear}-${String(this.selectedMonth).padStart(2, '0')}-${String(this.selectedDay).padStart(2, '0')}`,
          selectedEndDate: `${this.selectedEndYear}-${String(this.selectedEndMonth).padStart(2, '0')}-${String(this.selectedEndDay).padStart(2, '0')}`,
          billNumber: this.billNumber,

          page: this.currentPage,  // الصفحة الحالية
          page_size: this.pageSize, // حجم الصفحة
        });
        this.Bills = response.data.data;
        this.totalBills = response.data.total;
      } catch (error) {
        console.error("Error fetching bills:", error);
      }
    },
  },
  mounted() {
    this.fetchCustomers();
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}
</style>
