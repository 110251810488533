<template>
  <div class="container ">
    <el-card class="mb-4">
      <!-- <h1 class="text-center text-2xl font-bold mb-4"> المبيعات </h1> -->

      <!-- <qrcode-drop-zone></qrcode-drop-zone>
<qrcode-capture></qrcode-capture> -->


      <div class="row d-flex">
        <!-- <qrcode-capture :formats="['qr_code', 'code_128']"></qrcode-capture> -->
        <!-- Customers Section -->
        <el-card class="customer-list col-span-1 col-6 overflow-x-auto max-h-500">
          <div class="col-12 d-flex">
            {{ pinCodeFromQR }} - {{ pinCheckStatus }}
            <div class="col-4">
              <qrcode-stream @decode="onDecode" @init="onInit" @detect="onDetect" @error="onError"></qrcode-stream>

            </div>
            <div class="col-8 d-flex align-items-end">
              <el-radio-group v-model="selectedPaymentMethod" @change="selectedPaymentMethodChanged"
                placeholder=" نوع الفاتوره" class="mb-0 p-0" style="direction: ltr;align-items:end;">
                <el-radio-button v-for="PM in paymentMethods" :label="PM.label" :value="PM.value" />

              </el-radio-group>
            </div>

          </div>


          <h2 class="text-lg font-semibold mb-0">الزبائن </h2>
          <div class="col-12 row d-flex flex-row p-2 py-0">
            <el-select v-model="buyerType" @change="fetchCustomers" placeholder=" نوع الزبون"
              class="mb-0 col-4 w-30 p-0">
              <el-option label="موظف" value="employee" />
              <el-option label="طالب" value="student" />
              <el-option label="زائر" value="visitor" />
            </el-select>


            <el-input v-model="searchQuery" placeholder="ابحث عن  الزبون بواسطة الاسم أو الرقم التعريفي"
              @input="filterCustomers" @change="filterCustomers" class="mb-0 col-4 w-70 p-0" />
          </div>
          <div class="table-wrapper">
            <el-table :data="filteredCustomers" border>
              <el-table-column prop="name" label="الاسم" />
              <el-table-column prop="daily_allowed_purchase_value" label="القيمة المسموحة" />
              <el-table-column prop="id" label="الرقم التعريفي" />
              <el-table-column label="الإجراء">
                <template #default="scope">
                  <el-button @click="selectCustomer(scope.row)" type="primary">اختر</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>

        <!-- Meals Section -->
        <el-card v-if="selectedCustomer && selectedPaymentMethod"
          class="meal-section col-span-1 col-6 overflow-x-auto max-h-500 p-0">

          <p class="text-lg font-semibold mb-2">الزبون : {{ selectedCustomer.name }}</p>
          <div class="col-12 ">
            <div class="col-12">
              <label class="text-bold text-dark">التصنيفات</label>
              <!-- <el-select class="" v-model="selectedCategory" placeholder="Select a category" @change="filterMeals">
                <el-option v-for="category in categories" :key="category.id" :label="category.name"
                  :value="category.id" />
              </el-select> -->
              <el-radio-group v-model="selectedCategory" @change="filterMeals" placeholder=" نوع الفاتوره"
                class="mb-0 p-0" style="">
                <el-radio border v-for="category in categories" :key="category.id" :label="category.name"
                  :value="category.id" />

              </el-radio-group>
            </div>

            <div class="col-8">
              <label>...</label>
              <el-input v-model="mealSearchQuery" placeholder="ابحث عن الأصناف" @input="filterMeals" class="mb-4" />

            </div>
          </div>



          <p class="mb-2">اختر الأصناف:</p>

          <div class="table-wrapper">
            <el-table :data="filteredMeals" border>
              <el-table-column prop="name" label="اسم الصنف" />
              <el-table-column prop="price" width="70" label="السعر" />
              <el-table-column label="الإجراء" width="70">
                <template #default="scope">
                  <el-button @click="addToBill(scope.row)" type="success">+</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </div>

      <!-- Bill Section -->
      <el-card class="bill-section col-span-1 overflow-x-auto max-h-500 row">
        <h2 class="text-lg font-semibold mt-0">الفاتوره </h2>

        <el-card>
          <h2 class="text-lg font-semibold mb-2 card">الزبون : {{ selectedCustomer.name }}</h2>

        </el-card>

        <div class="table-wrapper">
          <el-table :data="kitchenBillItems" border stripe>
            <el-table-column prop="name" label="اسم الصنف" width="300" />
            <el-table-column prop="quantity" label="الكمية">

              <template #default="scope">

                <div class="m-0 p-0 d-flex justify-content-between">
                  <el-button
                    @click="if (scope.row.quantity > 1) { scope.row.quantity--; } else { removeFromBill(scope.row.meal_id) }"
                    type="danger">-</el-button>

                  <label>{{ scope.row.quantity }}</label>
                  <el-button v-if="selectedPaymentMethod != 'subscription'" @click="scope.row.quantity++"
                    type="primary">+</el-button>

                </div>
              </template>
            </el-table-column>
            <el-table-column prop="meal_price" label="سعر القطعه" />
            <el-table-column prop="" label="السعر">
              <template #default="scope">
                {{ scope.row.meal_price * scope.row.quantity }}
              </template>
            </el-table-column>
            <el-table-column label="الإجراء">
              <template #default="scope">
                <el-button @click="removeFromBill(scope.row.meal_id)" type="danger">إزالة</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="d-flex flex-column align-items-end">

          <p class="font-bold mt-2">الإجمالي: {{ totalPrice }} دينار</p>
          <el-button v-if="selectedPaymentMethod != ''" @click="submitBill" type="success" class="mt-4  w-25"> تأكيد
            عملية
            البيع
          </el-button>
        </div>
      </el-card>
    </el-card>
  </div>
</template>

<script>

import axios from 'axios';


import { ElCard, ElSelect, ElOption, ElInput, ElTable, ElMessageBox, ElTableColumn, ElButton, ElNotification } from 'element-plus';
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

// Interceptors for auth token
api.interceptors.request.use((config) => {
  document.querySelectorAll('button').forEach((button) => {
    button.setAttribute('disabled', '');  // إضافة الخاصية فقط
  });
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
// دالة بعد اكتمال الطلب
api.interceptors.response.use((response) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  ;

  // يمكنك هنا التعامل مع البيانات بعد اكتمال الطلب
  // مثال: تعديل البيانات قبل إرجاعها

  return response;  // إرجاع الاستجابة كما هي
}, (error) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  // في حالة حدوث خطأ بعد اكتمال الطلب
  console.error("خطأ في الاستجابة:", error);
  return Promise.reject(error);
});

export default {
  components: {
    ElCard,
    ElSelect,
    ElOption,
    ElInput,
    ElTable,
    ElTableColumn,
    ElButton,
    QrcodeStream,
    QrcodeDropZone,
    ElMessageBox,
    QrcodeCapture,

  },
  data() {
    return {
      pinCheckStatus: false,
      pinCodeFromQR: "",
      customers: [],
      selectedCategory: null,
      categories: null,
      filteredCustomers: [],
      meals: [],
      filteredMeals: [],
      kitchenBillItems: [],
      buyerType: 'employee',
      selectedCustomer: null,
      searchQuery: '',
      mealSearchQuery: '',
      totalPriceValue: '',
      restrictedMealIds: [],
      paymentMethods: Array(),
      selectedPaymentMethod: "",

      allcategories: null,
      breakFastCategory: [
        {
          id: 111,
          name: "وجبات افطار Caledonian",
        }
      ]
      ,
      lunchCategory: [
        {
          id: 222,
          name: "وجبات غداء Caledonian",
        }
      ]
      ,
      breakFastAndlunchCategories: [
        {
          id: 111,
          name: "وجبات غداء Caledonian",
        },
        {
          id: 222,
          name: "وجبات افطار Caledonian",
        }
      ]
      ,

      visitorData:
        [{
          id: null,
          name: null,
        }]
      ,
    };
  },
  computed: {
    totalPrice() {
      this.totalPriceValue = this.kitchenBillItems.reduce((sum, item) => sum + item.meal_price * item.quantity, 0);

      return this.kitchenBillItems.reduce((sum, item) => sum + item.meal_price * item.quantity, 0);
    },
  },
  methods: {
    async openBillDailog(billNo) {
      let htmlContent = `
        <style>
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid black; padding: 5px; text-align: center; }
            p { text-align: center; }
        </style>
        <div>
<center>
            <img style='width:120px;height:50px;' src='${require('@/assets/img/logo-school.png')}' alt='invoice'>
</center>
          <p>المشتري : ${this.selectedCustomer.name}</p>
            <p>رقم الفاتورة : ${billNo}</p>
            <p>نوع الشراء : ${this.selectedPaymentMethod}</p>
        </div>
        <table border='1' cellpadding='5' cellspacing='0'>
            <thead>
                <tr>
                    <th>الصنف</th>
                    <th>الكمية</th>
                    <th>سعر القطعه</th>
                    <th>السعر</th>
                </tr>
            </thead>
            <tbody>`;

      // Add each item row in the table
      this.kitchenBillItems.forEach((element) => {
        htmlContent += `
                <tr>
                    <td>${element.name}</td>
                    <td>${element.quantity}</td>
                    <td>${element.meal_price}</td>
                    <td>${element.meal_price * element.quantity}</td>
                </tr>`;
      });

      htmlContent += `
            </tbody>
            <tfoot>
                <tr>
                    <td colspan='3' align='right'>Total</td>
                    <td>${this.totalPriceValue}</td>
                </tr>
            </tfoot>
        </table>`;

      // Display the ElMessageBox with a custom "Print" button
      ElMessageBox.confirm(
        htmlContent,
        {
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Print',
          cancelButtonText: 'Close',
          callback: (action) => {
            if (action === 'confirm') {
              this.printHtmlContent(htmlContent);
            }
          }
        }
      );
    },

    // Function to print only the htmlContent using an iframe
    printHtmlContent(htmlContent) {
      const iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.style.border = 'none';
      document.body.appendChild(iframe);

      const iframeDoc = iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(htmlContent);
      iframeDoc.close();

      // Print and remove iframe after printing
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    },


    async selectedPaymentMethodChanged() {
      this.selectedCategory = null;
      this.meals = null;

      this.filteredMeals = null;
      this.fetchMeals();
      this.kitchenBillItems = [];
      if (this.selectedPaymentMethod == 'wallet') {

        this.categories = this.allcategories;
      }
      if (this.selectedPaymentMethod == 'cash') {

        this.categories = this.allcategories;
      }

      if (this.buyerType == 'student') {

        // check if the the student has a meals subscription
        if (this.selectedCustomer.meal_fees.length > 0 && this.selectedPaymentMethod != 'wallet' && this.selectedPaymentMethod != 'cash') {

          if (this.selectedCustomer.meal_fees.length == 2) {
            this.categories = this.breakFastAndlunchCategories;



          }
          else if (this.selectedCustomer.meal_fees.length == 1) {
            if (this.selectedCustomer.meal_fees[0].id == 100)
              this.categories = this.breakFastCategory;
            if (this.selectedCustomer.meal_fees[0].id == 200)
              this.categories = this.lunchCategory;
          }

        }



      }

    },
    onDecode(result) {
      console.log('Decoded QR Code:', result); // Debugging output
      this.closeQrScanner();
      // Handle the reservation data from the QR code
      try {
        this.decodedData = JSON.parse(result);
        this.dialogVisible = true;
      } catch (error) {
        // alert('Invalid QR code format');
        console.log('Invalid QR code format');
      }
    },
    onInit(promise) {
      promise.catch(error => {
        if (error.name === 'NotAllowedError') {
          alert('User denied camera access');
        } else if (error.name === 'NotFoundError') {
          alert('No camera found on this device');
        } else {
          alert('Error initializing camera: ' + error.message);
        }
      });
    },
    onError(error) {
      // console.error('QR Code reading error:', error); // Debugging output
      alert('Error reading QR code: ' + error.message);
    },
    onDetect(result) {





      // Debugging output
      this.decodedData = JSON.stringify(result);
      this.dialogVisible = true;
      // this.closeQrScanner();




      const jsonString = result[0].rawValue;
      const jsonStringArray = JSON.parse(jsonString);
      this.buyerType = jsonStringArray[0];

      this.fetchCustomers();
      this.searchQuery = jsonStringArray[1];
      this.pinCheckStatus = true;
      this.pinCodeFromQR = jsonStringArray[2];

      // alert(this.searchQuery)

    },
    async fetchCategories() {
      const response = await api.get('/categories');
      this.categories = response.data;
      this.allcategories = this.categories;
    },

    async fetchCustomers() {
      this.pinCodeFromQR = "",
        this.pinCheckStatus = false;
      this.kitchenBillItems = [];
      this.selectedCustomer = null;
      this.searchQuery = '';
      this.mealSearchQuery = '';
      this.customers = "";
      this.filteredCustomers = "";
      this.paymentMethods = "";
      this.selectedPaymentMethod = "";
      if (this.buyerType != 'visitor') {
        const endpoint = this.buyerType === 'student'
          ? '/students-customers'
          : '/employees-customers';

        try {
          const response = await api.get(endpoint);
          this.customers = response.data;
          this.filteredCustomers = response.data;
        } catch (error) {
          console.error('Error fetching customers:', error);
        }

        const query = this.searchQuery.toLowerCase();
        this.filteredCustomers = this.customers.filter(customer => {
          return (
            customer.name.toLowerCase().includes(query) ||
            customer.id == query
          );
        });
      }
      else if (this.buyerType == 'visitor') {

        this.customers = this.visitorData;
        this.filteredCustomers = this.visitorData;
      }
    },


    async totalPriceForToday() {
      const response = await api.post('/total-price-for-today', {
        // customer:this.selectedCustomer,
        buyerType: this.buyerType,
        selectCustomer: this.selectedCustomer.id,

      });
      console.log(this.buyer_type);
    },
    async fetchMeals() {


      try {
        const response = await api.get('/meals');
        this.meals = response.data;
        console.log(this.meals);
        // Remove restricted meals
        this.meals = this.meals.filter(meal => !this.restrictedMealIds.includes(meal.id));
        this.meals = this.meals.filter(meal => this.selectedCategory.includes(this.meal.category.id));


        // Filter meals for display
        this.filterMeals();
      } catch (error) {
        console.error('Error fetching meals:', error);
      }
      finally {

      }
    },
    async filterCustomers() {
      this.pinCodeFromQR = "",
        this.pinCheckStatus = false;

      // const query = this.searchQuery.toLowerCase();
      // this.filteredCustomers = this.customers.filter(customer => {
      //   return (
      //     customer.name.toLowerCase().includes(query) ||
      //     customer.id.toString().includes(query)
      //   );
      // });
      const query = this.searchQuery.toLowerCase();
      this.filteredCustomers = this.customers.filter(customer => {
        return (
          customer.name.toLowerCase().includes(query) || // بحث جزئي في الاسم
          customer.id.toString() == query // تطابق دقيق مع id
        );
      });

    },
    async filterMeals() {

      const query = this.mealSearchQuery.toLowerCase();
      const theSelectedCategory = this.selectedCategory;

      this.filteredMeals = this.meals.filter(meal => {
        const isItInSelectedCategory = theSelectedCategory ? meal.category_id === theSelectedCategory : true; // Check if category matches or if none is selected
        const isRestrictedMeal = this.restrictedMealIds.includes(meal.id);
        const matchesSearchQuery = meal.name.toLowerCase().includes(query);

        // If a student is selected, filter out restricted meals
        if (this.selectedCustomer && this.selectedCustomer.type === 'student') {
          return !isRestrictedMeal && matchesSearchQuery && isItInSelectedCategory;
        }

        // Show all meals for employees or if no student is selected
        return matchesSearchQuery && isItInSelectedCategory;
      });
    },


    selectCustomer(customer) {
      var conntenue = false;
      if (this.pinCheckStatus) {
        if (this.buyerType == 'student') {
          if (customer.parent.pin_code == this.pinCodeFromQR)
            conntenue = true;
        }
        else if (this.buyerType == 'employee') {
          if (customer.pin == this.pinCodeFromQR)
            conntenue = true;

        }
        // if()
      } else {
        conntenue = true;
      }
      if (conntenue) {
        this.selectedCategory = "";
        this.meals = [];
        this.filteredMeals = [];

        this.selectedCustomer = customer;
        this.kitchenBillItems = [];

        this.paymentMethods = Array();
        this.selectedPaymentMethod = "";
        this.categories = this.allcategories;
        if (this.buyerType == 'student') {

          // check if the the student has a meals subscription
          if (this.selectedCustomer.meal_fees.length > 0) {

            this.paymentMethods.push({ label: 'اشتراك', value: 'subscription' });
            if (this.selectedCustomer.meal_fees.length == 2) {
              this.categories = this.breakFastAndlunchCategories;



            }
            else if (this.selectedCustomer.meal_fees.length == 1) {
              if (this.selectedCustomer.meal_fees[0].id == 100)
                this.categories = this.breakFastCategory;
              if (this.selectedCustomer.meal_fees[0].id == 200)
                this.categories = this.lunchCategory;
            }

          }

          // check if the the parent of the  student has a wallet
          if (this.selectedCustomer.parent != null) {

            if (this.selectedCustomer.parent.wallet != null)

              this.paymentMethods.push({ label: 'محفظة', value: 'wallet' });
          }

        }
        if (this.buyerType == 'employee') {
          if (this.selectedCustomer.wallet != null)
            if (this.selectedCustomer.wallet.balance > 0) {

              this.paymentMethods.push({ label: 'محفظة', value: 'wallet' });
            }

        }


        // this.paymentMethods = Array(
        //     { label: 'محفظة', value: 'wallet' },
        //     { label: 'اشتراك', value: 'subscription' },
        //     { label: 'نقدي', value: 'cash' }
        //   );

        // Extract restricted meal IDs
        this.restrictedMealIds = [];
        if (customer.student_restricted_meal) {
          for (let i = 0; i < customer.student_restricted_meal.length; i++) {
            this.restrictedMealIds.push(customer.student_restricted_meal[i].meal_id);
          }
        }

        // this.totalPriceForToday();
        // Fetch meals and filter out restricted meals
        this.fetchMeals();

        this.paymentMethods.push({ label: 'نقدي', value: 'cash' });
        this.selectedPaymentMethod = this.paymentMethods[0].value;
      } else {
        alert('البطاقة غير صالحه');
      }

    },

    addToBill(meal) {
      console.log(this.selectedCustomer);
      const existingItem = this.kitchenBillItems.find(item => item.meal_id === meal.id);
      if (existingItem) {
        if (this.selectedPaymentMethod == 'subscription' && existingItem.quantity < 1 && this.kitchenBillItems.length < 1) {

          existingItem.quantity++;
        }
        else if (this.selectedPaymentMethod == 'wallet' || this.selectedPaymentMethod == 'cash')
          existingItem.quantity++;
      } else {
        ////
        if (this.selectedPaymentMethod == 'subscription' && this.kitchenBillItems.length < 1) {
          // alert();
          this.kitchenBillItems.push({
            meal_id: meal.id,
            name: meal.name,
            meal_price: meal.price,
            category_id: meal.category_id,
            quantity: 1,
          });
        }
        else if (this.selectedPaymentMethod == 'wallet' || this.selectedPaymentMethod == 'cash')
          this.kitchenBillItems.push({
            meal_id: meal.id,
            name: meal.name,
            meal_price: meal.price,
            category_id: meal.category_id,
            quantity: 1,
          });
        ////

      }
    },

    removeFromBill(mealId) {
      const index = this.kitchenBillItems.findIndex(item => item.meal_id === mealId);
      if (index !== -1) {
        this.kitchenBillItems.splice(index, 1);
      }
    },
    ignoreError() {
      const debounce = (callback, delay) => {
        let tid;
        return function (...args) {
          const ctx = self;
          tid && clearTimeout(tid);
          tid = setTimeout(() => {
            callback.apply(ctx, args);
          }, delay);
        };
      };

      const _ = window.ResizeObserver;
      window.ResizeObserver = class ResizeObserver extends _ {
        constructor(callback) {
          callback = debounce(callback, 20);
          super(callback);
        }
      };
    },
    async submitBill() {

      let billData = {};
      if (this.buyerType == 'student' && this.selectedPaymentMethod == 'subscription') {
        billData = {
          buyer_id: this.selectedCustomer.id,
          buyer_type: this.buyerType,
          billTotalPrice: this.totalPriceValue,
          PaymentMethod: this.selectedPaymentMethod,

          category_id: this.kitchenBillItems[0].category_id,
          items: this.kitchenBillItems.map(item => ({
            meal_id: item.meal_id,
            quantity: item.quantity,
            meal_price: item.meal_price,

          })),
        };
      } else {
        billData = {
          buyer_id: this.selectedCustomer.id,
          buyer_type: this.buyerType,
          billTotalPrice: this.totalPriceValue,
          PaymentMethod: this.selectedPaymentMethod,

          category_id: '',
          items: this.kitchenBillItems.map(item => ({
            meal_id: item.meal_id,
            quantity: item.quantity,
            meal_price: item.meal_price,

          })),
        };

      }
      try {
        const response = await api.post('/buying-from-kitchen', billData);
        console.log('Bill submitted:', response);
        // this.openBillDailog();
        if (response.status == 201) {
          this.openBillDailog(response.data.bill.bill_number);
          this.fetchCustomers();
        }
        // open
      } catch (error) {

        ElNotification({
          title: 'حدث خطأ',
          message: error.response.data.errors.message,
          position: 'top-left',
          duration: 0,
          type: 'error',
        });

      }
    },
  },
  mounted() {
    this.fetchCustomers();
    this.fetchCategories();

    this.ignoreError();

  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}

.table-wrapper {
  max-height: 179px;
  height: 179px;
  overflow-x: auto;
  overflow-y: auto;
  /* Allows vertical scroll if needed */
}

.el-card__body {
  padding: 10px !important;
}

.el-radio-button {
  margin: 0px !important;
}
</style>
