<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>الملف الشخصي</span>
      </div>
      <div>
        <p><strong>الاسم:</strong> {{ user.name }}</p>
        <p><strong>البريد الإلكتروني:</strong> {{ user.email }}</p>
        <p><strong> العنوان:</strong> {{ user.address }}</p>
        <p><strong>رقم الهاتف :</strong> {{ user.phone }}</p>


        <el-button type="primary" @click="openEditDialog">تعديل البيانات</el-button>
        <el-button type="warning" @click="openPasswordDialog">تغيير كلمة المرور</el-button>
      </div>
    </el-card>

    <!-- حوار تعديل البيانات -->
    <el-dialog title="تعديل البيانات" v-model="editDialogVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="الاسم" required>
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="البريد الإلكتروني" required>
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label=" العنوان" required>
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label=" رقم الهاتف" required>
          <el-input v-model="form.phone"></el-input>
        </el-form-item>



      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">إلغاء</el-button>
        <el-button type="primary" @click="updateProfile">حفظ</el-button>
      </div>
    </el-dialog>

    <!-- حوار تغيير كلمة المرور -->
    <el-dialog title="تغيير كلمة المرور" v-model="passwordDialogVisible" width="30%">
      <el-form :model="passwordForm">
        <el-form-item label="كلمة المرور الجديدة" required>
          <el-input type="password" v-model="passwordForm.password"></el-input>
        </el-form-item>
        <el-form-item label="تأكيد كلمة المرور" required>
          <el-input type="password" v-model="passwordForm.password_confirmation"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="passwordDialogVisible = false">إلغاء</el-button>
        <el-button type="primary" @click="changePassword">تغيير</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use((config) => {
  document.querySelectorAll('button').forEach((button) => {
    button.setAttribute('disabled', '');  // إضافة الخاصية فقط
  });
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
// دالة بعد اكتمال الطلب
api.interceptors.response.use((response) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  ;

  // يمكنك هنا التعامل مع البيانات بعد اكتمال الطلب
  // مثال: تعديل البيانات قبل إرجاعها

  return response;  // إرجاع الاستجابة كما هي
}, (error) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  // في حالة حدوث خطأ بعد اكتمال الطلب
  console.error("خطأ في الاستجابة:", error);
  return Promise.reject(error);
});
export default {
  name: "profile",
  data() {
    return {
      user: {},
      form: {
        address: '',
        phone: '',
        name: '',
        email: '',
      },
      passwordForm: {
        password: '',
        password_confirmation: '',
      },
      editDialogVisible: false,
      passwordDialogVisible: false,
    };
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      api.get('/user/profile')
        .then(response => {
          this.user = response.data;
          this.form.name = response.data.name;
          this.form.email = response.data.email;
          this.form.address = response.data.address;
          this.form.phone = response.data.phone;

        })
        .catch(error => {
          console.error("Error fetching user data:", error);
        });
    },
    openEditDialog() {
      this.editDialogVisible = true;
    },
    openPasswordDialog() {
      this.passwordDialogVisible = true;
    },
    updateProfile() {
      api.put('/user/profile/update', this.form)
        .then(response => {
          ElMessage.success('تم تحديث البيانات بنجاح');
          this.user = response.data;
          this.editDialogVisible = false;
        })
        .catch(error => {
          ElMessage.error('حدث خطأ أثناء التحديث');
        });
    },
    changePassword() {
      api.post('/user/profile/change-password', this.passwordForm)
        .then(response => {
          ElMessage.success('تم تغيير كلمة المرور بنجاح');
          this.passwordDialogVisible = false;
        })
        .catch(error => {
          ElMessage.error('حدث خطأ أثناء تغيير كلمة المرور');
        });
    }
  }
};
</script>

<style>
/* .box-card {
  width: 480px;
  margin: 20px auto;
} */
</style>
