<template lang="">
  <div class="container">
    <div class="container-card">
      <el-card class="mb-4">
        <!-- Header
  with cover image -->
        <div class="header-image">
          <img
            src="/header-school2.jpg"
            alt="Cover
  Image"
            class="cover-image"
          />
          <hr/>
          <h3 class="text-center english">Employee Registration Form</h3>
          <h3 class="text-center arabic">نموذج تسجيل موظفين</h3>
        </div>
        <div class="extra-employee-form">
          <el-form
            :model="form"
            @submit.prevent="confirmAndSubmit"
            label-position="top"
          >
            <div class="grid-container">
              <el-form-item style="font-weight: bold" label="Name" required>
                <el-input v-model="form.name" placeholder="Enter Name" />
              </el-form-item>
              <el-form-item
                class="gender-label"
                style="font-weight: bold"
                label="Gender"
                required
              >
                <el-select v-model="form.gender" placeholder="Select Gender">
                  <el-option label="Male" value="male"></el-option>
                  <el-option label="Female" value="female"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="date-label" label="Date of Birth" required>
                <el-date-picker
                  v-model="form.date_of_birth"
                  type="date"
                  placeholder="Select Date of Birth"
               
                />
              </el-form-item>
              <el-form-item label="Fingerprint Number">
                <span>
                    If you don't know your fingerprint device number, leave it blank.
                </span>
                <el-input
                  v-model="form.fingerprint_id"
                  type="text"
                  placeholder="Fingerprint Number"
                />
              </el-form-item>
              <el-form-item label="Passport Number">
                <el-input
                  v-model="form.passport_number"
                  placeholder="Enter Passport Number"
                />
              </el-form-item>
              <el-form-item label="Department" required>
                <el-select
                  v-model="form.department_id"
                  placeholder="Select Department"
                >
                  <el-option
                    v-for="department in departments"
                    :key="department.id"
                    :label="department.name"
                    :value="department.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Teacher Types">
                <el-select
                  v-model="form.teacher_type_ids"
                  multiple
                  placeholder="Select Teacher Types"
                  @change="fetchClassesAndSections"
                  filterable
                >
                  <el-option
                    v-for="teacherType in teacherTypes"
                    :key="teacherType.id"
                    :label="teacherType.type"
                    :value="teacherType.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Classes" v-if="form.teacher_type_ids.length">
                <el-select
                  v-model="form.class_ids"
                  multiple
                  placeholder="Select Classes"
                  @change="fetchSections"
                  :disabled="!form.teacher_type_ids.length"
                >
                  <el-option
                    v-for="classRoom in classes"
                    :key="classRoom.id"
                    :label="classRoom.name"
                    :value="classRoom.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Sections" v-if="form.class_ids.length">
                <el-select
                  v-model="form.section_ids"
                  multiple
                  placeholder="Select Sections"
                  :disabled="!form.class_ids.length"
                >
                  <el-option
                    v-for="section in filteredSections"
                    :key="section.id"
                    :label="section.name"
                    :value="section.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Employee Type">
                <el-select
                  v-model="form.employee_type_id"
                  placeholder="Select
  Employee Type"
                >
                  <el-option
                    v-for="employeeType in employeeTypes"
                    :key="employeeType.id"
                    :label="employeeType.name"
                    :value="employeeType.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="National ID">
                <el-input
                  v-model="form.national_number"
                  placeholder="Enter National ID"
                />
              </el-form-item>
              <el-form-item label="Phone Number" required>
                <el-input
                  v-model="form.phone_number"
                  placeholder="Enter Phone Number"
                />
              </el-form-item>
              <el-form-item
                label="Second Phone
  Number"
              >
                <el-input
                  v-model="form.phone_number_two"
                  placeholder="Enter Second Phone
  Number"
                />
              </el-form-item>
              <el-form-item label="Address" required>
                <el-input v-model="form.address" placeholder="Enter Address" />
              </el-form-item>
              <el-form-item label="Date of Join" required>
                <el-date-picker
                  v-model="form.date_of_join"
                  type="date"
                  placeholder="Select Date of Join"
              
                />
              </el-form-item>
              <br>
              <hr/>
             
              <hr />
              <!-- Footer
  with text -->
  <div class="row form-row">
    <div class="col-lg-6 form-col">
      <el-form-item label="Photos">
        <el-upload
          action=""
          list-type="picture-card"
          :file-list="photosList"
          :on-remove="removePhoto"
          :before-upload="beforeUploadPhoto"
          :multiple="true"
          :limit="10"
        >
          <el-button type="primary">Upload Photos</el-button>
        </el-upload>
      </el-form-item>
    </div>
    <div class="col-lg-6 form-col">
      <el-form-item class="content-upload" label="Attached Files">
        <el-upload
          action=""
          class="upload-demo"
          drag
          multiple
          :file-list="attachedFilesList"
          :on-remove="removeAttachedFile"
          :before-upload="beforeUploadAttachedFile"
          :multiple="true"
          :limit="10"
        >
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <el-button type="primary">Upload Files</el-button>
        </el-upload>
      </el-form-item>
    </div>
  </div>
              
           
            </div>
            <el-form-item>
       
<button type="submit" class="continue-application">
    <div>
        <div class="pencil"></div>
        <div class="folder">
            <div class="top">
                <svg viewBox="0 0 24 27">
                    <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                </svg>
            </div>
            <div class="paper"></div>
        </div>
    </div>
    Send Your Data
</button>
              </el-form-item>
          </el-form>
        </div>
        <hr />
        <div class="footer-form">
            <h5 class="text-center english">
              This form contains data that conforms to the requirements of
              the Caledonia system. All entered data will be reviewed to
              verify its accuracy, but we appreciate your cooperation in
              providing genuine information to make the process faster and
              simpler.🌟
            </h5>
            <h5 class="text-center arabic">
              هذا النموذج هو عبارة عن بيانات تتوافق مع البيانات المطلوبة في
              نظام كاليدونيا، كل ما يتم ادخاله سيتم مراجعته لتحقق من صحة
              البيانات ولكن يُسعدنا اذا كنت متعاوناً وقدمت البيانات الحقيقية
              لجعل العملية أسرع وأبسط🌟
            </h5>
          </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { UploadFilled } from '@element-plus/icons-vue';
import { Document, Money } from "@element-plus/icons-vue";
import Wanday from "../../../public/Wanday.ttf";
import { ElPopconfirm } from "element-plus";
import axios from "axios";
import { debounce } from "lodash";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || "https://cale.caledonian.ly/api",
});

// api.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("access_token");
//     if (token) {
//       config.headers["Authorization"] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
export default {
  name: "ExtraEmployeeData",
  components: {
    Document,
    Money,
    UploadFilled,
    ElPopconfirm,
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      dialogEditVisible: false,
      showDialogVisible: false,
      salaryDialogVisible: false,
      selectedEmployee: null,
      selectedSalary: null,
      selectedDeductions: [],
      deductionsFetchedAll: [],
      selectedAbsences: [],

      form: {
        name: "",
        date_of_join: "",
        fingerprint_id: "",
        employee_type_id: "",
        user_id: null,
        department_id: null,
        teacher_type_ids: [], // Updated to allow multiple teacher types
        class_ids: [], // Updated to allow multiple classes
        section_ids: [], // Updated to allow multiple sections
        class_id: "",
        section_id: "",
        national_number: "",
        phone_number: "",
        phone_number_two: "",
        address: "",
        pin: "",
        photos: [],
        attached_files: [], // New field
        years_of_experience: 0,
        base_salary: 0,
        gender: "", // New field
        date_of_birth: "", // New field
        passport_number: "", // New field
      },
      photosList: [],
      attachedFilesList: [],
      employeeTypes: [],
      filteredSections: [],
      searchQuery: "", // Search input model
      searchById: "", // for ID
      searchNationalQuery: "", // New: for national number
      searchPhoneQuery: "", // New: for national number
      searchPassportQuery: "", // New: for passport number
      allEmployees: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10, // Number of records per page
      totalEmployees: 0,
      departments: [],
      teacherTypes: [],
      classes: [],
      sections: [],
      message: "",
    };
  },
  methods: {
    
    handlePhotosChange(file, fileList) {
      // Handle the files selected by the user
      this.photoFiles = fileList.map((file) => file.raw); // Store the raw file objects
    },
    handleFilesChange(file, fileList) {
      // Handle the files selected by the user
      this.fileUploads = fileList.map((file) => file.raw); // Store the raw file objects
    },

    beforeUploadPhoto(file) {
      this.form.photos.push(file);
      this.photosList.push(file);
      return false; // Prevent automatic upload
    },
    removePhoto(file) {
      const index = this.form.photos.indexOf(file);
      if (index > -1) {
        this.form.photos.splice(index, 1);
      }
    },
    beforeUploadAttachedFile(file) {
      this.form.attached_files.push(file);
      this.attachedFilesList.push(file);
      return false; // Prevent automatic upload
    },
    removeAttachedFile(file) {
      const index = this.form.attached_files.indexOf(file);
      if (index > -1) {
        this.form.attached_files.splice(index, 1);
      }
    },
    handlePhotoUpload(file) {
      this.form.photos.push(file.raw);
    },
    // handleFileUpload(file) {
    //   this.form.attached_files.push(file.raw);
    // },
    formatDateOfBirth(value) {
      // This method formats the date to YYYY-MM-DD
      if (!value) return ''; // Handle empty values safely
  const d = new Date(value);
  
  // Format date as YYYY-MM-DD without shifting due to time zone
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(d.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
     
    },
    formatDateOfJoin(value) {
     // This method formats the date to YYYY-MM-DD
     if (!value) return ''; // Handle empty values safely
  const d = new Date(value);
  
  // Format date as YYYY-MM-DD without shifting due to time zone
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(d.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
    },
    async fetchClassesAndSections() {
      this.classes = [];
      this.form.class_ids = [];
      this.form.section_ids = [];

      if (this.form.teacher_type_ids.length) {
        try {
          // Fetch classes that match the selected teacher types
          const response = await api.get(
            `/classes?teacher_type_ids=${this.form.teacher_type_ids.join(",")}`
          );
          this.classes = response.data;

          // Fetch sections for the first selected class if any
          if (this.classes.length) {
            await this.fetchSections();
          }
        } catch (error) {
          console.error("Error fetching classes:", error);
        }
      }
    },
    async fetchSections() {
      this.filteredSections = []; // Reset filtered sections
      if (this.form.class_ids.length) {
        try {
          // Fetch sections for each selected class
          for (const classId of this.form.class_ids) {
            const response = await api.get(`/classes/${classId}/sections`);
            this.filteredSections.push(...response.data);
          }
          // Filter sections based on the selected teacher types
          this.filteredSections = this.filteredSections.filter((section) => {
            const sectionSubjects = section.subjects.map(
              (subject) => subject.id
            );
            return this.form.teacher_type_ids.some((type) =>
              sectionSubjects.includes(type)
            );
          });
        } catch (error) {
          console.error("Error fetching sections:", error);
        }
      }
    },
    handleAttachedFilesUpload(event) {
      const files = event.target.files;
      if (files) {
        this.form.attached_files = Array.from(files);
      }
    },
    getImageUrl(imagePath) {
      return `http://localhost:8000/storage/${imagePath}`;
    },
    async fetchEmployeeTypes() {
      try {
        const response = await axios.get("https://cale.caledonian.ly/api/employee-types-all");
        this.employeeTypes = response.data;
      } catch (error) {
        console.error("Failed to fetch employee types:", error);
      }
    },


    async submitForm() {
      const formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("user_id", this.form.user_id);
      formData.append("department_id", this.form.department_id);
      formData.append("employee_type_id", this.form.employee_type_id);
      // formData.append('teacher_type_id', this.form.teacher_type_id);
      // Append arrays for teacher types, classes, and sections
      this.form.teacher_type_ids.forEach((id) => {
        formData.append("teacher_type_ids[]", id);
      });

      this.form.class_ids.forEach((id) => {
        formData.append("class_ids[]", id);
      });

      this.form.section_ids.forEach((id) => {
        formData.append("section_ids[]", id);
      });
      // formData.append('class_id', this.form.class_id);
      // formData.append('class_id', this.form.class_id);
      // formData.append('section_id', this.form.section_id);
      formData.append("national_number", this.form.national_number);
      formData.append("phone_number", this.form.phone_number);
      formData.append("phone_number_two", this.form.phone_number_two);
      formData.append("address", this.form.address);
      formData.append("date_of_join", this.formatDateOfJoin(this.form.date_of_join));
      formData.append("fingerprint_id", this.form.fingerprint_id);
      formData.append("years_of_experience", this.form.years_of_experience);
      formData.append("base_salary", this.form.base_salary);
      formData.append("pin", this.form.pin);
      formData.append("gender", this.form.gender);
      formData.append("date_of_birth", this.formatDateOfBirth(this.form.date_of_birth) );
      formData.append("passport_number", this.form.passport_number);
      // Debug: Check photos_list before appending
      console.log("Photos List before appending:", this.form.photos_list);

      // Append photos to formData
      this.form.photos.forEach((photo) => {
        formData.append("photos[]", photo);
      });

      // Append attached files to formData
      this.form.attached_files.forEach((file) => {
        formData.append("attached_files[]", file);
      });

      this.$forceUpdate();

      try {
        await api.post("/employees", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.$message.success("تمت إضافة الموظف بنجاح");
        this.resetForm();
        this.dialogVisible = false;
      } catch (error) {
        this.$message.error("فشل في إضافة الموظف");
      }
    },
    async confirmAndSubmit() {
  this.$confirm('Are you sure you want to submit this data?', 'Confirmation', {
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    type: 'warning',
  }).then(async () => {
    await this.submitForm();
  }).catch(() => {
    this.$message({
      type: 'info',
      message: 'Submission canceled',
    });
  });
},

    async fetchDepartmentsAndTeacherTypes() {
      try {
        const response = await api.get("/departments-teacher-types");
        this.departments = response.data.departments;
        this.teacherTypes = response.data.teacherTypes;
      } catch (error) {
        this.$message.error("فشل في جلب الأقسام وأنواع المعلمين");
      }
    },

    resetForm() {
      this.form = {
        employee_type_id: "",
        name: "",
        user_id: "",
        department_id: "",
        teacher_type_id: "",
        national_number: "",
        phone_number: "",
        phone_number_two: "",
        address: "",
        pin: "",
        photos: [],
        attached_files: [],
        teacher_type_ids: [],
        photos_list: [],
        class_ids: [], // Updated to allow multiple classes
        section_ids: [],
        years_of_experience: 0,
        base_salary: 0,
      };
      this.filteredSections = [];
      // this.dialogVisible = false;
    },
    handlePreview(file) {
      window.open(file.url || URL.createObjectURL(file.raw));
    },
    handleRemove(file, fileList) {
      this.form.photos_list = fileList;
    },
    handleFileUpload(event) {
      const files = event.target.files;
      if (files) {
        this.form.photos = Array.from(files);
      }
    },
    beforeUploadPhotos(file) {
      this.form.photos_list.push(file);
      console.log("File added to photos_list:", file);
      return false; // Prevent auto upload
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleSearch: debounce(function () {
     
    }, 300), // Delay of 300ms
    async loadInitialData() {
      // Load your data here
      try {
        const [teacherTypes, classes, sections, departments, employeeTypes] =
          await Promise.all([
            api.get("/teacher-types"),
            api.get("/classes"),
            api.get("/sections"),
            api.get("/departments"),
          ]);
        this.teacherTypes = teacherTypes.data;
        this.classes = classes.data;
        this.sections = sections.data;
        this.departments = departments.data;
      } catch (error) {
        console.error("Error loading initial data:", error);
      }
    },
  },


  computed: {

 
    parsedPhotos() {
      // Parse selectedEmployee.photos if it's a string
      if (
        this.selectedEmployee &&
        typeof this.selectedEmployee.photos === "string"
      ) {
        return JSON.parse(this.selectedEmployee.photos);
      }
      // If already an array, return as-is
      return this.selectedEmployee ? this.selectedEmployee.photos : [];
    },
  },
  mounted() {
    this.loadInitialData();
    this.fetchEmployeeTypes();
    this.fetchDepartmentsAndTeacherTypes();
  },
  beforeRouteEnter(to, from, next) {
    // Hide layout elements on entering the 404 page
    next((vm) => vm.$store.commit("setDisplayVisibility", false));
  },
  beforeRouteLeave(to, from, next) {
    // Show layout elements on leaving the 404 page
    vm.$store.commit("setDisplayVisibility", true);
    next();
  },
};
</script>
<style scoped>
/* From Uiverse.io by Nawsome */ 
.continue-application {
    --color: #fff;
    --background: #546ddd;
    --background-hover: #546ddd;
    --background-left: #546ddd;
    --folder: #F3E9CB;
    --folder-inner: #BEB393;
    --paper: #FFFFFF;
    --paper-lines: #BBC1E1;
    --paper-behind: #E1E6F9;
    --pencil-cap: #fff;
    --pencil-top: #275EFE;
    --pencil-middle: #fff;
    --pencil-bottom: #5C86FF;
    --shadow: rgba(13, 15, 25, .2);
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    padding: 17px 29px 17px 69px;
    transition: background 0.3s;
    color: var(--color);
    background: var(--bg, var(--background));
  }
  
  .continue-application > div {
    top: 0;
    left: 0;
    bottom: 0;
    width: 53px;
    position: absolute;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
    background: var(--background-left);
  }
  
  .continue-application > div .folder {
    width: 23px;
    height: 27px;
    position: absolute;
    left: 15px;
    top: 13px;
  }
  
  .continue-application > div .folder .top {
    left: 0;
    top: 0;
    z-index: 2;
    position: absolute;
    transform: translateX(var(--fx, 0));
    transition: transform 0.4s ease var(--fd, 0.3s);
  }
  
  .continue-application > div .folder .top svg {
    width: 24px;
    height: 27px;
    display: block;
    fill: var(--folder);
    transform-origin: 0 50%;
    transition: transform 0.3s ease var(--fds, 0.45s);
    transform: perspective(120px) rotateY(var(--fr, 0deg));
  }
  
  .continue-application > div .folder:before, .continue-application > div .folder:after,
  .continue-application > div .folder .paper {
    content: "";
    position: absolute;
    left: var(--l, 0);
    top: var(--t, 0);
    width: var(--w, 100%);
    height: var(--h, 100%);
    border-radius: 1px;
    background: var(--b, var(--folder-inner));
  }
  
  .continue-application > div .folder:before {
    box-shadow: 0 1.5px 3px var(--shadow), 0 2.5px 5px var(--shadow), 0 3.5px 7px var(--shadow);
    transform: translateX(var(--fx, 0));
    transition: transform 0.4s ease var(--fd, 0.3s);
  }
  
  .continue-application > div .folder:after,
  .continue-application > div .folder .paper {
    --l: 1px;
    --t: 1px;
    --w: 21px;
    --h: 25px;
    --b: var(--paper-behind);
  }
  
  .continue-application > div .folder:after {
    transform: translate(var(--pbx, 0), var(--pby, 0));
    transition: transform 0.4s ease var(--pbd, 0s);
  }
  
  .continue-application > div .folder .paper {
    z-index: 1;
    --b: var(--paper);
  }
  
  .continue-application > div .folder .paper:before, .continue-application > div .folder .paper:after {
    content: "";
    width: var(--wp, 14px);
    height: 2px;
    border-radius: 1px;
    transform: scaleY(0.5);
    left: 3px;
    top: var(--tp, 3px);
    position: absolute;
    background: var(--paper-lines);
    box-shadow: 0 12px 0 0 var(--paper-lines), 0 24px 0 0 var(--paper-lines);
  }
  
  .continue-application > div .folder .paper:after {
    --tp: 6px;
    --wp: 10px;
  }
  
  .continue-application > div .pencil {
    height: 2px;
    width: 3px;
    border-radius: 1px 1px 0 0;
    top: 8px;
    left: 105%;
    position: absolute;
    z-index: 3;
    transform-origin: 50% 19px;
    background: var(--pencil-cap);
    transform: translateX(var(--pex, 0)) rotate(35deg);
    transition: transform 0.4s ease var(--pbd, 0s);
  }
  
  .continue-application > div .pencil:before, .continue-application > div .pencil:after {
    content: "";
    position: absolute;
    display: block;
    background: var(--b, linear-gradient(var(--pencil-top) 55%, var(--pencil-middle) 55.1%, var(--pencil-middle) 60%, var(--pencil-bottom) 60.1%));
    width: var(--w, 5px);
    height: var(--h, 20px);
    border-radius: var(--br, 2px 2px 0 0);
    top: var(--t, 2px);
    left: var(--l, -1px);
  }
  
  .continue-application > div .pencil:before {
    -webkit-clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
    clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
  }
  
  .continue-application > div .pencil:after {
    --b: none;
    --w: 3px;
    --h: 6px;
    --br: 0 2px 1px 0;
    --t: 3px;
    --l: 3px;
    border-top: 1px solid var(--pencil-top);
    border-right: 1px solid var(--pencil-top);
  }
  
  .continue-application:before, .continue-application:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background: var(--color);
    transform-origin: 9px 1px;
    transform: translateX(var(--cx, 0)) scale(0.5) rotate(var(--r, -45deg));
    top: 26px;
    right: 16px;
    transition: transform 0.3s;
  }
  
  .continue-application:after {
    --r: 45deg;
  }
  
  .continue-application:hover {
    --cx: 2px;
    --bg: var(--background-hover);
    --fx: -40px;
    --fr: -60deg;
    --fd: .15s;
    --fds: 0s;
    --pbx: 3px;
    --pby: -3px;
    --pbd: .15s;
    --pex: -24px;
  }
  

@font-face {
    font-family: 'Wanday', Arial, sans-serif;
    src: url("/public/Wanday.ttf") format('truetype');
  }
.form-row { margin-bottom: 20px; } .form-col { padding-left: 10px; padding-right: 10px; } .el-form-item { margin-bottom: 15px; }
.container-card {
  max-width: 800px;
  margin: auto;
}
.header-image {
  text-align: center;
  position: relative;
}
.cover-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.extra-employee-form {
  padding: 20px;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
/* Responsive Design */ @media (max-width: 768px) { .grid-container { grid-template-columns: 1fr; } .form-col { padding-left: 0; padding-right: 0; } }
 .english{
  margin-top: 20px;
  font-family: Wanday ,Arial, sans-serif !important;
}
 .arabic{
  margin-top: 20px;
  font-family: TajawalBold ,Arial, sans-serif !important;
}
.text-center {
  text-align: center;
}
h5 {
  margin: 10px 0;
}
.el-form-item {
  margin-bottom: 15px;
}

.el-form .el-form-item__label {
  color: blue;
  font-weight: bold;
  font-size: 16px;
  font-family: "Wanday" !important;
}

.el-form-item__label {
  color: blue !important;
  font-weight: bold !important;
  font-size: 16px !important;
  font-family: "Wanday" !important;
}

#app,
label {
  color: blue !important;
  font-weight: bold !important;
  font-size: 16px !important;
  font-family: "Wanday" !important;
}
</style>
