<template>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
              <div class="d-flex justify-content-around">
                <h6 class="text-white text-capitalize ps-3">Manage Employees</h6>
                <el-input
                  style="width: 200px;"
                  v-model="searchQuery"
                  placeholder="Search employees..."
                  class="search-input"
                  @input="fetchEmployees"
                />
                <el-button @click="dialogVisible = true" type="success" circle class="mr-5">
                  <el-icon style="vertical-align: middle">
                    <Document />
                  </el-icon>
                </el-button>
              </div>
            </div>
            <div>
              <el-form :model="filters" inline class="mt-4">
                <el-form-item label="Department">
                  <el-select v-model="filters.department_id" placeholder="Select Department" @change="fetchEmployees">
                    <el-option :label="'All Departments'" :value="null"></el-option>
                    <el-option v-for="department in departments" :key="department.id" :label="department.name" :value="department.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Teacher Type">
                  <el-select v-model="filters.teacher_type_id" placeholder="Select Teacher Type" @change="fetchEmployees">
                    <el-option :label="'All Teacher Types'" :value="null"></el-option>
                    <el-option v-for="teacherType in teacherTypes" :key="teacherType.id" :label="teacherType.type" :value="teacherType.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="fetchEmployees">Filter Employees</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button type="default" @click="resetFilters">Reset Filters</el-button>
                </el-form-item>
              </el-form>
  
              <el-table :data="employees" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" />
                <el-table-column prop="employee.name" label="Name" width="120" />
                <el-table-column prop="employee.department.name" label="Department" width="150" />
                <el-table-column prop="employee.teacher_type.type" label="Teacher Type" width="150" />
                <el-table-column prop="base_salary" label="Base Salary" width="120" />
                <el-table-column prop="hourly_rate" label="Hourly Rate" width="120" />
                <el-table-column prop="total_salary" label="Total Salary" width="120" />
              </el-table>
  
              <div class="d-flex justify-content-center mt-4">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-size="pageSize"
                  :total="totalEmployees"
                  layout="prev, pager, next"
                  background
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { Document } from '@element-plus/icons-vue';
  import axios from 'axios';
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  export default {
    name: 'EmployeeSalaries',
    components: {
      Document,
    },
    data() {
      return {
        filters: {
          department_id: null,
          teacher_type_id: null
        },
        departments: [],
        teacherTypes: [],
        employees: [],
        selectedEmployees: [],
        searchQuery: '',
        currentPage: 1,
        pageSize: 10,
        totalEmployees: 0,
      };
    },
    methods: {
      async fetchDepartments() {
        try {
          const response = await api.get('/departments');
          this.departments = response.data;
        } catch (error) {
          this.$message.error('Failed to fetch departments');
        }
      },
      async fetchTeacherTypes() {
        try {
          const response = await api.get('/teacher-types');
          this.teacherTypes = response.data;
        } catch (error) {
          this.$message.error('Failed to fetch teacher types');
        }
      },
      async fetchEmployees() {
        try {
          const response = await api.get('/salaries', {
            params: {
              department_id: this.filters.department_id,
              teacher_type_id: this.filters.teacher_type_id,
              search: this.searchQuery,
              page: this.currentPage,
              per_page: this.pageSize,
            }
          });
  
          // Directly use the response array
          this.employees = response.data;
          this.totalEmployees = response.data.length;
        } catch (error) {
          this.$message.error('Failed to fetch employees');
        }
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.fetchEmployees();
      },
      handleSelectionChange(val) {
        this.selectedEmployees = val;
      },
      resetFilters() {
        this.filters.department_id = null;
        this.filters.teacher_type_id = null;
        this.fetchEmployees();
      },
    },
    mounted() {
      this.fetchDepartments();
      this.fetchTeacherTypes();
      this.fetchEmployees();
    },
    watch: {
      searchQuery: 'fetchEmployees',
    }
  };
  </script>
<style scoped>
.el-form {
  margin-bottom: 20px;
}
</style>
    