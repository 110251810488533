<template>
  <div class="container">
    <el-card class="mb-4">
      <div class="search-bar">
        <el-input placeholder="ابحث عن طالب" v-model="searchQuery" @input="fetchStudents" clearable />
      </div>


      <el-table :data="students" style="width: 100%" v-loading="loading">
        <el-table-column prop="id" label="رقم الطالب" width="120" />
        <el-table-column prop="name" label="اسم الطالب" />
        <el-table-column prop="daily_allowed_purchase_value" label="الحد اليومي">
          <template #default="scope">
            <el-input v-model.number="scope.row.daily_allowed_purchase_value" placeholder="أدخل الحد اليومي" />
          </template>
        </el-table-column>
        <el-table-column prop="daily_allowed_purchase_value" label="الحد اليومي">
          <template #default="scope">
            <button class="btn btn-sm btn-info" @click="updateDailyLimit(scope.row)">
              تعديل
            </button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background layout="prev, pager, next" :total="totalStudents" :page-size="pageSize"
        @current-change="handlePageChange" />
    </el-card>
  </div>
</template>

<script>
import axios from "axios";
import {
  ElCard,
  ElInput,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElSelect,
  ElOption,
  ElNotification
} from "element-plus";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || "https://cale.caledonian.ly/api",
});

api.interceptors.request.use((config) => {
  document.querySelectorAll('button').forEach((button) => {
    button.setAttribute('disabled', '');  // إضافة الخاصية فقط
  });
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
// دالة بعد اكتمال الطلب
api.interceptors.response.use((response) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  ;

  // يمكنك هنا التعامل مع البيانات بعد اكتمال الطلب
  // مثال: تعديل البيانات قبل إرجاعها

  return response;  // إرجاع الاستجابة كما هي
}, (error) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  // في حالة حدوث خطأ بعد اكتمال الطلب
  console.error("خطأ في الاستجابة:", error);
  return Promise.reject(error);
});

export default {
  components: {
    ElCard,
    ElInput,
    ElTable,
    ElTableColumn,
    ElPagination,
    ElSelect,
    ElOption,
  },
  data() {
    return {
      students: [],
      // studentsWithoutLimit: [],
      searchQuery: "",
      loading: false,
      page: 1,
      pageSize: 10,
      totalStudents: 0,

    };
  },
  methods: {
    async fetchStudents() {
      this.loading = true;
      try {
        const response = await api.get("/students-limit/purchase-limits", {
          params: {
            search: this.searchQuery,
            page: this.page,
          },
        });
        this.students = response.data.students.data;
        this.totalStudents = response.data.students.total;
      } catch (error) {
        console.error("Error fetching students:", error);
      } finally {
        this.loading = false;
      }
    },
    // async fetchStudentsWithoutLimit() {
    //   try {
    //     const response = await api.get("/students-limit/without-purchase-limit");
    //     this.studentsWithoutLimit = response.data.students;
    //   } catch (error) {
    //     console.error("Error fetching students without limit:", error);
    //   }
    // },
    async updateDailyLimit(student) {
      try {
        await api.put(`/students-limit/${student.id}/purchase-limit`, {
          daily_allowed_purchase_value: student.daily_allowed_purchase_value,
        });
        ElNotification({
          title: "نجاح",
          message: "تم تحديث الحد اليومي بنجاح",
          type: "success",
        });
      } catch (error) {
        ElNotification({
          title: "خطأ",
          message: "حدث خطأ أثناء تحديث الحد اليومي",
          type: "error",
        });
        console.error("Error updating daily limit:", error);
      }
    },
    // setInitialDailyLimit() {
    //   if (this.selectedStudent) {
    //     this.selectedStudent.daily_allowed_purchase_value = 0;
    //   }
    // },
    handlePageChange(newPage) {
      this.page = newPage;
      this.fetchStudents();
    },
  },
  mounted() {
    this.fetchStudents();
    // this.fetchStudentsWithoutLimit();
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: auto;
}

.search-bar {
  margin-bottom: 20px;
}

.el-card__body {
  padding: 10px !important;
}
</style>
