<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="isRTL ? '' : ' me-sm-6'"
    >
      <li class="text-sm breadcrumb-item" >
        <a  v-if="isRTL"   href="#"
          >لوحات القيادة</a
        >
        <a v-else  href="#">Pages</a>
      </li>
      <li
        class="text-sm breadcrumb-item "
      
        aria-current="page"
      >
        {{ currentPage }}
      </li>
    </ol>
    <h6 class="mb-0 font-weight-bolder" :class="color">
      {{ currentPage }}
    </h6>
  </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "breadcrumbs",
  props: {
    currentPage: {
      type: String,
      required: "",
    },
    color: {
      type: String,
      default: "text-dark",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>
<style scoped>
@font-face {
  font-family: 'DINNextLTArabic-Bold';
  src: url('../../public/fonts/DINNextLTArabic-Bold-4.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
h6{
  color: #4dc0da ;
  font-family: 'DINNextLTArabic-Bold';
}
a{
  color: white;
}
li{
  color: white;
}
</style>