<template>
    <div class="">
        <el-card class="m-3">
            <div class="col-12 row ">
                <div class="col-2 column">
                    <el-label class="text-dark"> class</el-label>
                    <el-select v-model="form.class_id" @change="fetchSections(); fetchStudents();">
                        <el-option v-for="item in classes" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                </div>
                <div class="col-4 column" v-if="sections.length > 0">
                    <el-label class="text-dark"> section</el-label>
                    <el-radio-group v-model="form.section_id" @change=" fetchStudents()">
                        <el-radio border v-for="item in sections" :key="item.id" :label="item.name"
                            :value="item.id"></el-radio>
                    </el-radio-group>
                </div>
                <div class="col-4 column">
                    <!-- السنة -->
                    <select v-model="selectedYear" @change="fetchStudents()">
                        <option v-for="year in years" :key="year" :value="year">
                            {{ year }}
                        </option>
                    </select>

                    <!-- الشهر -->
                    <select v-model="selectedMonth" @change="fetchStudents()">
                        <option v-for="month in months" :key="month" :value="month">
                            {{ month }}
                        </option>
                    </select>

                    <!-- اليوم -->
                    <select v-model="selectedDay" @change="fetchStudents()">
                        <option v-for="day in daysInMonth" :key="day" :value="day">
                            {{ day }}
                        </option>
                    </select>

                    <p>التاريخ المحدد: {{ formattedDate }}</p>
                </div>
            </div>
        </el-card>

        <!-- Add the Export Button -->
        <el-button type="primary" @click="exportToCSV" class="m-3">
            Export to CSV
        </el-button>

        <div class="w-100 d-flex flex-row p-3  justify-content-between m-auto ">
            <el-card class="w-80 m-1" v-if="students.students2">
                <template #header>
                    <div class="card-header">
                        <span> List of attending students - قائمة الطلبة الحاضرين</span>
                    </div>
                </template>
                <div class="col-12">
                    <ul v-if="students.students2" class="p-0">
                        <li v-for="item in students.students2" :key="item.id" class="border-bottom font-weight-bold">
                            <div class="col-12 row d-flex flex-row align-items-center justify-content-center">
                                <div class="col-1 p-0">
                                    <img :src="getPhotoUrl(item.photo, item.gender)" alt="Student Photo"
                                        style="width: 80px; height: 80px; border-radius: 50%; object-fit: cover;" />
                                </div>
                                <label class="col-4 text-dark h6 m-0 pl-0">{{ item.name }}</label>
                                <label class="w-10 p-0 text-dark h6 m-0 d-flex align-items-center">{{ item.class.name }}
                                    <label v-if="item.section" class="text-dark d-flex align-items-center m-0 h6">- {{
                                        item.section.name }}</label>
                                </label>
                                <div class="col-2 m-0 p-0 ss">{{ item.student_attendance_records[0].record_state }}
                                </div>
                                <div class="col-3 m-0 p-0">
                                    {{ formatDate(item.student_attendance_records[0].created_at) }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ElCard, ElInput, ElDatePicker, ElButton, ElTable, ElTableColumn, ElSelect, ElOption, ElForm, ElFormItem, ElDialog, ElRadioGroup, ElRadio, ElCheckboxGroup, ElCheckbox } from 'element-plus';
import malePhoto from '../../assets/img/male.png';
import femalePhoto from '../../assets/img/female.png';
import moneyFin from '../../../public/money-fin.png';
import unspecifiedPhoto from '../../assets/img/unspecified.png';
import { toInteger } from 'lodash';
import { nextTick } from 'vue';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use((config) => {
    document.querySelectorAll('button').forEach((button) => {
        button.setAttribute('disabled', '');  // إضافة الخاصية فقط
    });
    const token = localStorage.getItem('access_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use((response) => {
    document.querySelectorAll('button').forEach((button) => {
        button.removeAttribute('disabled');  // إزالة الخاصية
    });
    return response;
}, (error) => {
    document.querySelectorAll('button').forEach((button) => {
        button.removeAttribute('disabled');  // إزالة الخاصية
    });
    console.error("خطأ في الاستجابة:", error);
    return Promise.reject(error);
});

export default {
    components: {
        ElCard,
        ElInput,
        ElButton,
        ElTable,
        ElTableColumn,
        ElSelect,
        ElOption,
        ElForm,
        ElFormItem,
        ElDialog,
        ElRadioGroup,
        ElRadio,
        ElDatePicker
    },
    data() {
        return {
            selectedYear: new Date().getFullYear(),
            selectedMonth: new Date().getMonth() + 1,
            selectedDay: new Date().getDate(),
            years: this.generateYears(),
            months: this.generateMonths(),
            daysInMonth: this.generateDaysInMonth(new Date().getFullYear(), new Date().getMonth() + 1),
            selectAll: false,
            selectedStudents: [],
            classes: [],
            sections: [],
            students: [],
            form: {
                day: '',
                class_id: "",
                section_id: "",
            }
        };
    },
    methods: {
        generateYears() {
            const currentYear = new Date().getFullYear();
            return Array.from({ length: currentYear - 2024 + 1 }, (_, index) => 2024 + index);
        },
        generateMonths() {
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        },
        generateDaysInMonth(year, month) {
            const daysInMonth = new Date(year, month, 0).getDate();
            return Array.from({ length: daysInMonth }, (_, index) => index + 1);
        },
        updateDaysInMonth(year, month) {
            this.daysInMonth = this.generateDaysInMonth(year, month);
            if (this.selectedDay > this.daysInMonth.length) {
                this.selectedDay = this.daysInMonth[this.daysInMonth.length - 1];
            }
        },
        formatDate(datetime) {
            const date = new Date(datetime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const days = ["الأحد", "الإثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"];
            const dayName = days[date.getDay()];
            return `${year}-${month}-${day} (${dayName})`;
        },
        getPhotoUrl(photoPath, gender) {
            if (photoPath) {
                return `https://cale.caledonian.ly/storage/${photoPath}`;
            }
            switch (gender) {
                case 'male':
                    return malePhoto;
                case 'female':
                    return femalePhoto;
                default:
                    return unspecifiedPhoto;
            }
        },
        async fetchClasses() {
            try {
                const response = await api.get('/classes');
                this.classes = response.data;
            } catch (error) {
                this.$message.error('Failed to fetch classes');
            }
        },
        async fetchSections() {
            this.sections = [];
            this.form.section_id = "";
            try {
                const response = await api.get('/sections', {
                    params: { class_id: this.form.class_id },
                });
                this.sections = response.data;
            } catch (error) {
                this.$message.error('Failed to fetch sections');
            }
        },
        async fetchStudents() {
            try {
                const response = await api.post("/students-for-attendance-list", {
                    day: this.formattedDate,
                    class_id: this.form.class_id,
                    section_id: this.form.section_id,
                });
                this.students = response.data;
            } catch (error) {
                console.error("Error fetching students:", error);
            }
        },
        async sendAttendanceforOneStudent(student_id) {
            try {
                const response = await api.post("/send-attendance-for-one-student", {
                    class_id: this.form.class_id,
                    section_id: this.form.section_id,
                    student_id: student_id,
                });
            } catch (error) {
                console.error("Error  ", error);
            }
            this.fetchStudents();
        },
        async cancelAttendanceforOneStudent(student_id) {
            try {
                const response = await api.post("/cancel-attendance-for-one-student", {
                    class_id: this.form.class_id,
                    section_id: this.form.section_id,
                    student_id: student_id,
                });
            } catch (error) {
                console.error("Error  ", error);
            }
            this.fetchStudents();
        },
        afterMountedActions() {
            console.log(this.classes);
        },
        async sendAttendanceforSelectedStudents(status) {
            try {
                const response = await api.post("/send-attendance-for-selected-students", {
                    class_id: this.form.class_id,
                    section_id: this.form.section_id,
                    selectedStudents: this.selectedStudents,
                    status: status,
                });
            } catch (error) {
                console.error("Error  ", error);
            }
            this.fetchStudents();
        },
        toggleSelectAll() {
            if (this.selectAll) {
                this.selectedStudents = this.students.students.map(student => student.id);
            } else {
                this.selectedStudents = [];
            }
        },
        exportToCSV() {
            if (!this.students.students2 || this.students.students2.length === 0) {
                alert("No students to export!");
                return;
            }

            const csvContent = "data:text/csv;charset=utf-8," 
                + "Name,Class,Section,Attendance Status,Date\n" 
                + this.students.students2.map(student => 
                    `"${student.name}","${student.class.name}","${student.section ? student.section.name : 'N/A'}","${student.student_attendance_records[0].record_state}","${this.formatDate(student.student_attendance_records[0].created_at)}"`
                ).join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "attendance_list.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
    watch: {
        selectedStudents(newVal) {
            this.selectAll = newVal.length === this.students.students.length;
        },
        selectedYear(newYear, oldYear) {
            this.updateDaysInMonth(newYear, this.selectedMonth);
        },
        selectedMonth(newMonth, oldMonth) {
            this.updateDaysInMonth(this.selectedYear, newMonth);
        },
    },
    computed: {
        formattedDate() {
            return `${this.selectedYear}-${String(this.selectedMonth).padStart(2, '0')}-${String(this.selectedDay).padStart(2, '0')}`;
        },
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const urlClass = urlParams.get('class');
        const urlSection = urlParams.get('section');
        if (urlClass != null) {
            this.fetchClasses().then(() => {
                const foundClass = this.classes.find(c => c.name.toLowerCase() === urlClass.toLowerCase());
                if (foundClass) {
                    this.form.class_id = foundClass.id;
                } else {
                    console.log('Class not found');
                }
                if (urlSection == null) {
                    this.fetchStudents();
                } else {
                    this.fetchSections().then(() => {
                        const foundSection = this.sections.find(s => s.name.toLowerCase() === urlSection.toLowerCase());
                        if (foundSection) {
                            this.form.section_id = foundSection.id;
                            this.fetchStudents();
                        } else {
                            console.log('section not found');
                            this.fetchStudents();
                        }
                    });
                }
            });
        } else {
            this.fetchClasses();
        }
    },
};
</script>

<style scoped>
.container {
    margin: 20px;
}

.error-message {
    color: red;
    font-size: 12px;
}

.el-radio {
    margin: 4px;
}

.el-checkbox {
    margin: 0px !important;
}
</style>