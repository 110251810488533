<template>
    <div>
        <!-- Employee Attendance Table -->
        <div class="table-container">
            <el-card shadow="always" class="custom-card">
              <h2 class="table-title">{{$t('manageAttendanceSalary')}}</h2>
              <el-table v-loading="loading" :data="employees" border stripe style="width: 100%">
                <el-table-column prop="id" :label="$t('fileNumberOfEmployee')" width="120" align="center" />
                <el-table-column prop="name" :label="$t('employeeName')" width="180" align="center" />
                <el-table-column :label="$t('operations')" width="480" align="center">
                  <template #default="scope">
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-plus"
                      @click="openAttendanceDialog(scope.row)"
                    >
                      Add Attendance
                    </el-button>
                    <el-button
                      type="success"
                      size="small"
                      icon="el-icon-view"
                      @click="fetchAttendanceRecords(scope.row.id)"
                      style="margin-left: 10px"
                    >
                      View Attendance
                    </el-button>
                    <el-button
                    type="danger"
                    size="small"
                    icon="el-icon-delete"
                    @click="deleteAttendance(scope.row.id)"
                    style="margin-left: 10px"
                  >
                    Delete Attendance
                  </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </div>

        <el-dialog
    title="Attendance Records"
    v-model="attendanceRecordsDialogVisible"
    width="50%"
>
    <el-table :data="attendanceRecords.data" style="width: 100%" v-loading="loadingRecords">
        <el-table-column prop="date" label="Date" />
        <el-table-column prop="arrival_time" label="Arrival Time" />
        <el-table-column prop="departure_time" label="Departure Time" />
    </el-table>

    <el-pagination
        v-if="attendanceRecords"
        :current-page="attendanceRecords.current_page"
        :page-size="attendanceRecords.per_page"
        :total="attendanceRecords.total"
        @current-change="handlePageChange"
    />
</el-dialog>


        <!-- Attendance Form Dialog -->
        <el-dialog
            title="Add Attendance Record"
            v-model="attendanceDialogVisible"
            width="50%"
        >
            <el-form :model="attendanceForm">
                <el-form-item label="Employee">
                    <el-select v-model="attendanceForm.employee_id" placeholder="Select Employee" disabled>
                        <el-option
                            v-for="employee in employees"
                            :key="employee.id"
                            :label="employee.name"
                            :value="employee.id"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item label="Arrival Time (HH:MM)">
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-input 
                                v-model.number="arrivalTime.hours" 
                                type="number" 
                                placeholder="Hours"
                                @input="calculateArrivalTime"
                            ></el-input>
                        </el-col>
                        <el-col :span="12">
                            <el-input 
                                v-model.number="arrivalTime.minutes" 
                                type="number" 
                                placeholder="Minutes"
                                @input="calculateArrivalTime"
                            ></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>

                <el-form-item label="Departure Time (HH:MM)">
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-input 
                                v-model.number="departureTime.hours" 
                                type="number" 
                                placeholder="Hours"
                                @input="calculateDepartureTime"
                            ></el-input>
                        </el-col>
                        <el-col :span="12">
                            <el-input 
                                v-model.number="departureTime.minutes" 
                                type="number" 
                                placeholder="Minutes"
                                @input="calculateDepartureTime"
                            ></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>

                <el-form-item label="Date">
                    <el-date-picker
                        v-model="attendanceForm.date"
                        type="date"
                        placeholder="Pick a date"
                        @change="formatDate"
                    />
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="attendanceDialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="submitAttendance">Submit</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default {
    data() {
        return {
            employees: [],
            loading:false,
            attendanceRecords: null,
            attendanceRecordsDialogVisible: false,
            loadingRecords: false,
            currentEmployeeId: null,
            attendanceDialogVisible: false,
            attendanceForm: {
                employee_id: null,
                arrival_time: null,
                departure_time: null,
                date: null,
            },
            arrivalTime: {
                hours: '',
                minutes: '',
            },
            departureTime: {
                hours: '',
                minutes: '',
            }
        };
    },
    methods: {
        async fetchAttendanceRecords(employeeId) {
            this.currentEmployeeId = employeeId;
            this.attendanceRecordsDialogVisible = true;
            await this.loadAttendanceRecords(1);
        },
        async loadAttendanceRecords(page) {
            this.loadingRecords = true;
            try {
                const response = await api.get(`attendance_records/${this.currentEmployeeId}?page=${page}`);
                this.attendanceRecords = response.data;
            } catch (error) {
                ElMessage.error('Error fetching attendance records.');
            } finally {
                this.loadingRecords = false;
            }
        },
        handlePageChange(page) {
            this.loadAttendanceRecords(page);
        },
        formatDate(date) {
            const d = new Date(date);
            return d.toISOString().split('T')[0]; // This returns the date in 'YYYY-MM-DD' format
        },
        async fetchEmployees() {
            this.loading = true;
            try {
                const response = await api.get(`employees/with-salary-records`);
                this.employees = response.data;
            } catch (error) {
                ElMessage.error('Error fetching employees.');
            }finally{
                this.loading = false;
            }
        },
        openAttendanceDialog(employee) {
            this.attendanceForm.employee_id = employee.id;
            this.attendanceDialogVisible = true;
        },
        calculateArrivalTime() {
            const hours = this.arrivalTime.hours || 0;
            const minutes = this.arrivalTime.minutes || 0;
            this.attendanceForm.arrival_time = (hours * 60) + minutes;
        },
        calculateDepartureTime() {
            const hours = this.departureTime.hours || 0;
            const minutes = this.departureTime.minutes || 0;
            this.attendanceForm.departure_time = (hours * 60) + minutes;
        },
        async submitAttendance() {
            try {
                await api.post(`attendance_records`, this.attendanceForm);
                ElMessage.success('Attendance record added successfully.');
                this.attendanceDialogVisible = false;
                // Optionally, clear the form after submission.
                this.resetAttendanceForm();
            } catch (error) {
                ElMessage.error('Error saving attendance record.');
            }
        },
        async deleteAttendance(employeeId) {
      try {
        await api.delete(`/attendance-records/${employeeId}`);
        ElMessage.success('Attendance record deleted successfully');
        // Refresh data or update the UI after deletion
        this.fetchEmployees(); // Assuming fetchEmployees is a method to refresh employee data
      } catch (error) {
        ElMessage.error('Failed to delete attendance record');
        console.error("Delete error:", error);
      }
    },
        resetAttendanceForm() {
            this.attendanceForm = {
                employee_id: null,
                arrival_time: null,
                departure_time: null,
                date: null,
            };
            this.arrivalTime = { hours: 0, minutes: 0 };
            this.departureTime = { hours: 0, minutes: 0 };
        }
    },
    mounted() {
        this.fetchEmployees();
    }
};
</script>
<style scoped>
.table-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .custom-card {
    padding: 20px;
  }
  
  .table-title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    color: #303133;
    margin-bottom: 20px;
  }
  
  .el-table th {
    background-color: #f5f7fa;
    color: #303133;
    font-weight: bold;
    text-align: center;
  }
  
  .el-table .el-table__cell {
    font-size: 14px;
  }
  
  .el-button {
    margin: 0 5px;
  }
</style>
