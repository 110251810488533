<template>
    <div>
      <h2>Send Email to Parents</h2>
      <el-table :data="parents" @selection-change="handleSelectionChange" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="first_name" label="First Name" width="120"></el-table-column>
        <el-table-column prop="last_name" label="Last Name" width="120"></el-table-column>
        <el-table-column prop="email" label="Email" width="200"></el-table-column>
        <el-table-column label="Actions" width="150">
          <template #default="scope">
            <el-button size="small" @click="openSingleEmailDialog(scope.row)">Send Email</el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <el-pagination
        @current-change="handlePageChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="totalParents"
        layout="prev, pager, next"
        background>
      </el-pagination>
  
      <el-button @click="openGroupEmailDialog" :disabled="selectedParents.length === 0">Send Email to Selected</el-button>
  
      <!-- Dialog for Single Email -->
      <el-dialog v-model="singleEmailDialogVisible" title="Send Email to Parent">
        <div>
          <el-input v-model="emailData.subject" placeholder="Email Subject"></el-input>
          <el-input type="textarea" v-model="emailData.message" placeholder="Email Message"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="singleEmailDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="sendEmail(singleEmail)">Send Email</el-button>
        </span>
      </el-dialog>
  
      <!-- Dialog for Group Email -->
      <el-dialog v-model="groupEmailDialogVisible" title="Send Group Email">
        <div>
          <el-input v-model="emailData.subject" placeholder="Email Subject"></el-input>
          <el-input type="textarea" v-model="emailData.message" placeholder="Email Message"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="groupEmailDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="sendGroupEmail">Send All</el-button>
        </span>
      </el-dialog>
      
      <div v-if="successMessage">{{ successMessage }}</div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ElMessage } from 'element-plus';
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default {
  data() {
    return {
      parents: [],
      selectedParents: [],
      currentPage: 1,
      pageSize: 10,
      totalParents: 0,
      singleEmailDialogVisible: false,
      groupEmailDialogVisible: false,
      emailData: {
        subject: '',
        message: ''
      },
      singleEmail: '',
      successMessage: ''
    };
  },
  mounted() {
    this.fetchParents();
  },
  methods: {
    async fetchParents() {
      try {
        const response = await api.get(`/get-parents-for-emails?page=${this.currentPage}`);
        this.parents = response.data.data;
        this.totalParents = response.data.total;
      } catch (error) {
        console.error("Error fetching parents:", error);
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchParents();
    },
    handleSelectionChange(selection) {
      this.selectedParents = selection;
    },
    openSingleEmailDialog(parent) {
      this.singleEmail = parent.email;
      this.resetEmailData(); // Clear input fields
      this.singleEmailDialogVisible = true;
    },
    openGroupEmailDialog() {
      this.resetEmailData(); // Clear input fields
      this.groupEmailDialogVisible = true;
    },
    resetEmailData() {
      // Reset subject and message fields
      this.emailData.subject = '';
      this.emailData.message = '';
    },
    async sendEmail() {
      try {
        await api.post('/send-email-to-parents', {
          emails: [this.singleEmail], // Send only the selected email
          subject: this.emailData.subject,
          message: this.emailData.message
        });
        this.successMessage = "Email sent successfully!";
        ElMessage.success(this.successMessage);
        this.singleEmailDialogVisible = false;
        this.resetEmailData(); // Clear inputs after sending
      } catch (error) {
        console.error("Error sending email:", error);
      }
    },
    async sendGroupEmail() {
      try {
        const emails = this.selectedParents.map(parent => parent.email);
        await api.post('/send-email-to-parents', {
          emails, // Send the list of selected emails
          subject: this.emailData.subject,
          message: this.emailData.message
        });
        this.successMessage = "Group email sent successfully!";
        ElMessage.success(this.successMessage);
        this.groupEmailDialogVisible = false;
        this.resetEmailData(); // Clear inputs after sending
      } catch (error) {
        console.error("Error sending group email:", error);
      }
    }
  }
};

  </script>
  
  <style scoped>
  /* Add custom styles here */
  </style>
  