<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
              <div class="d-flex justify-content-around">
                <h6 class="text-white text-capitalize ps-3">Parents Records</h6>
                <el-input style="width: 200px;" v-model="searchQuery"
                  placeholder="Search by first name, last name, phone number, city, or national number"
                  @input="handleSearch" />
                <el-button @click="printDocument" type="primary" icon="el-icon-printer">Print</el-button>
                <el-button @click="dialogVisible = true" type="success" circle class="mr-5">
                  <el-icon style="vertical-align: middle">
                    <Document />
                  </el-icon>
                </el-button>
              </div>
            </div>
          </div>
          <div>
            <el-table v-loading="loading" :data="allParents" style="width: 100%" :cell-class-name="highlightIdCell">
              <el-table-column type="expand" label="Operations" width="100">
                <template #default="scope">
                  <el-button @click="showParent(scope.row)" type="primary" size="mini">Show</el-button>
                  <el-button @click="editParent(scope.row)" type="warning" size="mini">Edit</el-button>
                  <el-button @click="confirmDelete(scope.row.id)" type="danger" size="mini">Delete</el-button>
                </template>
              </el-table-column>
              <el-table-column property="id" label="F/N" width="60" />
              <el-table-column property="first_name" label="First Name" width="120" />
              <el-table-column property="last_name" label="Last Name" width="120" />
              <el-table-column label="Number of Students" align="center" width="150">
                <template #default="scope">
                  {{ scope.row.students_count }}
                </template>
              </el-table-column>
              <el-table-column label="Students" align="center" width="350">
                <template #default="scope">
                  <div style="display: flex; flex-wrap: wrap; gap: 10px;">
                    <div v-for="student in scope.row.students" :key="student.id"
                      style="display: flex; flex-direction: column; align-items: center; margin-bottom: 10px;">
                      <img :src="getPhotoUrl(student.photo, student.gender)" alt="Student Photo"
                        style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover; margin-bottom: 5px;" />
                      <span style="font-size: 12px; text-align: center;">{{ student.name }}</span>

                      <span style="font-size: 12px; text-align: center;" v-if="student.class">{{ student.class.name
                        }}</span>

                      <span style="font-size: 12px; text-align: center;" v-if="student.subscription_fees">

                        <span style="font-size: 12px; text-align: center;" v-for="item in student.subscription_fees">
                          <span style="font-size: 12px; text-align: center; font-weight: bold;"
                            class="bg-success text-light" v-if="item.category == 'Tuition Fees'">
                            {{
                              item.sub_category }}
                          </span>


                        </span>
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>



              <el-table-column property="phone_number_one" label="Phone Number One" width="120" />
              <el-table-column property="phone_number_two" label="Phone Number Two" width="120" />
              <el-table-column property="city" label="City" width="120" />
              <el-table-column property="address" label="Address" />
              <el-table-column property="email" label="Email" />
              <el-table-column property="passport_num" label="Passport Number" width="120" />
              <el-table-column property="national_number" label="National Number" width="120" />
            </el-table>

            <div class="d-flex justify-content-center mt-4">
              <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
                :total="totalParents" layout="prev, pager, next">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Dialog -->
    <el-dialog title="Confirm Deletion" v-model="deleteDialogVisible" width="30%" :before-close="handleClose">
      <div class="dialog-content">
        <el-icon class="warning-icon">
          <WarningFilled style="color: #f56c6c; font-size: 24px;" />
        </el-icon>
        <span class="dialog-message">Are you sure you want to delete this parent?</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false" type="info">Cancel</el-button>
        <el-button class="confirm-btn" type="danger" @click="deleteParentConfirmed">Confirm</el-button>
      </div>
    </el-dialog>

    <el-dialog v-model="dialogVisible" title="Add/Edit Parent" width="830">
      <span>Please fill out the form below</span>
      <el-form :model="form" label-width="120px">
        <div class="form-grid">
          <el-form-item label="First Name"
            :rules="[{ required: true, message: 'Please enter first name', trigger: 'blur' }]">
            <el-input v-model="form.first_name"></el-input>
          </el-form-item>
          <el-form-item label="Last Name"
            :rules="[{ required: true, message: 'Please enter last name', trigger: 'blur' }]">
            <el-input v-model="form.last_name"></el-input>
          </el-form-item>

          <el-form-item label="Phone Number One"
            :rules="[{ required: true, message: 'Please enter phone number one', trigger: 'blur' }]">
            <el-input v-model="form.phone_number_one"></el-input>
          </el-form-item>
          <el-form-item label="Phone Number Two"
            :rules="[{ required: true, message: 'Please enter phone number two', trigger: 'blur' }]">
            <el-input v-model="form.phone_number_two"></el-input>
          </el-form-item>

          <el-form-item label="City" :rules="[{ required: true, message: 'Please enter city', trigger: 'blur' }]">
            <el-input v-model="form.city"></el-input>
          </el-form-item>
          <el-form-item label="Address" :rules="[{ required: true, message: 'Please enter address', trigger: 'blur' }]">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item label="Email" :rules="[{ required: false, message: 'Please enter email', trigger: 'blur' }]">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('pinCodeParent')"
            :rules="[{ required: false, message: 'Please enter PIN CODE', trigger: 'blur' }]">
            <el-input v-model="form.pin_code"></el-input>
          </el-form-item>

          <el-form-item label="National Number"
            :rules="[{ required: false, message: 'Please enter National Number', trigger: 'blur' }]">
            <el-input v-model="form.national_number"></el-input>
          </el-form-item>
          <el-form-item style="font-weight: 900 !important;" label="Passport Number"
            :rules="[{ required: false, message: 'Please enter Passport Number', trigger: 'blur' }]">
            <el-input v-model="form.passport_num"></el-input>
          </el-form-item>
        </div>

        <hr />

        <div class="form-grid">
          <el-form-item label="ID Image">
            <input type="file" @change="handleIDImageChange" />
          </el-form-item>

          <el-form-item label="Passport Image">
            <input type="file" @change="handlePassportImageChange" />
          </el-form-item>



        </div>
      </el-form>

      <div class="step-actions">
        <el-button type="primary" @click="submitForm">Submit</el-button>
      </div>
    </el-dialog>

    <!-- Show Parent Dialog -->
    <el-dialog v-model="showDialogVisible" title="Parent Details" width="800">
      <div v-if="selectedParent" class="parent-info-grid">
        <div>
          <strong>First Name:</strong> {{ selectedParent.first_name }}
        </div>
        <div>
          <strong>Last Name:</strong> {{ selectedParent.last_name }}
        </div>
        <div>
          <strong>Phone Number One:</strong> {{ selectedParent.phone_number_one }}
        </div>
        <div>
          <strong>Phone Number Two:</strong> {{ selectedParent.phone_number_two }}
        </div>
        <div>
          <strong>City:</strong> {{ selectedParent.city }}
        </div>
        <div>
          <strong>Address:</strong> {{ selectedParent.address }}
        </div>
        <div>
          <strong>Email:</strong> {{ selectedParent.Email }}
        </div>
        <div>
          <strong>National Number:</strong> {{ selectedParent.national_number }}
        </div>
        <div>
          <strong>Passport Number:</strong> {{ selectedParent.passport_num }}
        </div>
        <div>
          <strong>PIN CODE:</strong> {{ selectedParent.pin_code }}
        </div>

        <div class="parent-images">
          <div>
            <strong>ID Image:</strong>
            <img :src="getImageUrl(selectedParent.id_image)" alt="ID Image" class="responsive-image">
          </div>
          <div>
            <strong>Passport Image:</strong>
            <img :src="getImageUrl(selectedParent.passport_image)" alt="Passport Image" class="responsive-image">
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Document } from '@element-plus/icons-vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import malePhoto from '../../assets/img/male.png';
import femalePhoto from '../../assets/img/female.png';
import WaterMarkLogo from '../../assets/img/logo-school-one.png';
import unspecifiedPhoto from '../../assets/img/unspecified.png'; // Optional for null gender
import Echo from '../../echo';
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',

});

api.interceptors.request.use((config) => {
  document.querySelectorAll('button').forEach((button) => {
    button.setAttribute('disabled', '');  // إضافة الخاصية فقط
  });
  const token = localStorage.getItem('access_token'); // Get token from local storage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
// دالة بعد اكتمال الطلب
api.interceptors.response.use((response) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  ;

  // يمكنك هنا التعامل مع البيانات بعد اكتمال الطلب
  // مثال: تعديل البيانات قبل إرجاعها

  return response;  // إرجاع الاستجابة كما هي
}, (error) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  // في حالة حدوث خطأ بعد اكتمال الطلب
  console.error("خطأ في الاستجابة:", error);
  return Promise.reject(error);
});


export default {
  name: "Parents",
  components: { Document },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      deleteDialogVisible: false,
      selectedParentId: null,
      showDialogVisible: false,
      selectedParent: null,
      form: {
        id: null,
        first_name: '',
        pin_code: '',
        last_name: '',
        phone_number_one: '',
        phone_number_two: '',
        city: '',
        address: '',
        email: '',
        national_number: '',
        passport_num: '',
        id_image: null, // Single file instead of list
        passport_image: null, // Single file instead of list
      },
      searchQuery: '',
      allParents: [],
      allParentsWithChildren: [],
      currentPage: 1,
      pageSize: 10,
      totalParents: 0,
    };
  },
  methods: {
    handleIDImageChange(event) {
      const file = event.target.files[0]; // Get the selected file
      if (file) {
        this.form.id_image = file; // Store the selected file in the form data
      }
    },
    handlePassportImageChange(event) {
      const file = event.target.files[0]; // Get the selected file
      if (file) {
        this.form.passport_image = file; // Store the selected file in the form data
      }
    },
    // You can implement preview and remove logic if needed
    handlePreview(file) {
      // Logic to preview file if necessary
      console.log('Previewing file:', file);
    },
    handleRemove(file) {
      // Logic to handle file removal
      console.log('Removing file:', file);
      if (file === this.form.id_image) {
        this.form.id_image = null;
      } else if (file === this.form.passport_image) {
        this.form.passport_image = null;
      }
    },
    printDocument() {
      const newWindow = window.open('', '', 'width=800,height=600');
      newWindow.document.write(`
    <html>
      <head>
        <title>Print Document</title>
        <style>
          body {
            font-family: 'Arial', sans-serif;
            margin: 20px;
            line-height: 1.6;
            color: #333;
            position: relative;
          }
          /* Watermark Styling - Fixed across pages */
          .watermark {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60%;
            opacity: 0.1;
            z-index: 1;
          }
          /* Page Styling for Print */
     
          body {
            margin: 0;
            padding: 0;
          }
          /* Header Styling */
          .header {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            border-bottom: 2px solid #333;
            padding-bottom: 10px;
          }
          .header .title {
            font-size: 28px;
            font-weight: bold;
            margin-right: 15px;
          }
          .header .logo {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
          /* Table Styling */
          .print-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
          }
          .print-table th, .print-table td {
            border: 1px solid #ccc;
            padding: 10px;
            text-align: left;
          }
          .print-table th {
            background-color: #563A9C;
            color: white;
          }
          .print-table td {
            background-color: #f9f9f9;
          }
          .student-info {
            margin-bottom: 8px;
            padding: 6px;
            background-color: #e8f5e9;
            border-radius: 4px;
          }
        </style>
      </head>
      <body>
        <!-- Watermark -->
        <img class="watermark" src="${WaterMarkLogo}" alt="Watermark Logo" />

        <!-- Header Section -->
        <div class="header">
          <div class="title">Caledonian System #1</div>
          <img class="logo" src="${WaterMarkLogo}" alt="Logo" />
        </div>

        <!-- Table Section -->
        <table class="print-table">
          <thead>
            <tr>
              <th>Parent Name</th>
              <th>Phone Number</th>
              <th>Count of Children</th>
              <th>Students (Class/Section)</th>
            </tr>
          </thead>
          <tbody>
            ${this.allParentsWithChildren.map(parent => `
              <tr>
                <td>${parent.first_name} ${parent.last_name}</td>
                <td>${parent.phone_number_one || 'N/A'}</td>
                <td>${parent.students_count || 0}</td>
                <td>
                  ${parent.students.map(student => `
                    <div class="student-info">
                      ${student.name} 
                      (Class: ${student.class ? student.class.name : 'N/A'}, 
                      Section: ${student.section ? student.section.name : 'N/A'})
                    </div>
                  `).join('')}
                </td>
              </tr>
            `).join('')}
          </tbody>
        </table>
      </body>
    </html>
  `);
      newWindow.document.close();
      newWindow.print();
    },

    getPhotoUrl(photoPath, gender) {
      if (photoPath) {
        // Return the photo URL if the student already has one
        return `https://cale.caledonian.ly/storage/${photoPath}`;
      }

      // Check the gender and return the corresponding default photo
      switch (gender) {
        case 'male':
          return malePhoto;
        case 'female':
          return femalePhoto;
        default:
          return unspecifiedPhoto; // Return a placeholder for unspecified gender
      }
    },

    storeNotification(notification) {
      // Retrieve existing notifications from local storage
      const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
      notifications.push({
        title: notification.title,
        timestamp: new Date().toISOString() // Add a timestamp if you need it for sorting or other purposes
      });

      // Save updated notifications back to local storage
      localStorage.setItem('notifications', JSON.stringify(notifications));
    },

    // Load notifications from local storage
    loadNotifications() {
      const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
      // Set the notifications in your Vue component's state or display them
      this.notifications = notifications; // Assuming you have a data property called notifications
    },
    getImageUrl(imagePath) {
      return `https://cale.caledonian.ly/storage/${imagePath}`;
    },
    async fetchParents() {
      this.loading = true
      try {
        const response = await api.get('/parents', {
          params: {
            search: this.searchQuery,
            per_page: this.pageSize,
            page: this.currentPage,
          },
        });
        this.allParents = response.data.data;
        this.totalParents = response.data.total;
      } catch (error) {
        ElMessage.error('Failed to fetch parents');
      } finally {
        this.loading = false
      }
    },
    async fetchAllParentsWithChildren() {

      try {
        const response = await api.get('/parents-with-children', {
          params: {
            search: this.searchQuery,
          },
        });
        this.allParentsWithChildren = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch parents');
      } finally {
        this.loading = false
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchParents();
    },
    handleSearch() {
      this.currentPage = 1; // Reset to first page on new search
      this.fetchParents();
    },
    highlightIdCell({ row, column }) {

      if (column.property === 'id') {
        return 'highlight-id-cell';
      }
      return '';
    },
    editParent(parent) {
      console.log('Editing Parent:', parent); // Check the structure
      this.form = { ...parent };
      this.dialogVisible = true;
    },
    async submitForm() {
      try {
        const formData = new FormData();

        // Append regular form fields to the formData
        formData.append('first_name', this.form.first_name || '');
        formData.append('last_name', this.form.last_name || '');
        formData.append('phone_number_one', this.form.phone_number_one || '');
        formData.append('phone_number_two', this.form.phone_number_two || '');
        formData.append('city', this.form.city || '');
        formData.append('address', this.form.address || '');
        formData.append('email', this.form.email || '');
        formData.append('national_number', this.form.national_number || '');
        formData.append('pin_code', this.form.pin_code || '');
        formData.append('passport_num', this.form.passport_num || '');

        // Append file fields to formData if they exist
        if (this.form.id_image instanceof File) {
          formData.append('id_image', this.form.id_image);
        }
        if (this.form.passport_image instanceof File) {
          formData.append('passport_image', this.form.passport_image);
        }

        // Log formData to ensure it is being populated correctly
        for (let pair of formData.entries()) {
          console.log(pair[0] + ': ' + pair[1]);
        }

        if (this.form.id) {
          // Send a POST request to update an existing parent record
          await api.post(`/parents/update/${this.form.id}`, formData);
          ElMessage.success('Parent updated successfully.');
        } else {
          // Handle creation of a new parent
          await api.post('/parents', formData);
          ElMessage.success('Parent created successfully.');
        }

        this.fetchParents();
        this.dialogVisible = false;
        this.resetForm();
      } catch (error) {
        if (error.response && error.response.data.errors) {
          const messages = Object.values(error.response.data.errors)
            .flat()
            .join(', ');
          ElMessage.error(messages);
        } else {
          console.error(error);
          ElMessage.error('Failed to save parent.');
        }
      }
    },

    resetForm() {
      this.form = {
        id: null,
        first_name: '',
        last_name: '',
        phone_number_one: '',
        phone_number_two: '',
        city: '',
        address: '',
        email: '',
        national_number: '',
        passport_num: '',
        id_image: '',
        id_image_list: [],
        passport_image: '',
        passport_image_list: [],
      };
      this.dialogVisible = false;
    },

    async confirmDelete(id) {
      this.selectedParentId = id;
      this.deleteDialogVisible = true;
    },
    async deleteParentConfirmed() {
      try {
        await api.delete(`/parents/${this.selectedParentId}`);
        ElMessage.success('Parent deleted successfully');
        this.fetchParents();
      } catch (error) {
        ElMessage.error('Failed to delete parent');
      } finally {
        this.deleteDialogVisible = false;
      }
    },
    showParent(parent) {
      this.selectedParent = parent;
      this.showDialogVisible = true;
    },
    handleClose(done) {
      this.$confirm('Are you sure you want to close this dialog?')
        .then(() => {
          done();
        })
        .catch(() => { });
    },
  },
  mounted() {

    this.fetchParents();
    this.fetchAllParentsWithChildren();
    const userId = localStorage.getItem('userId')
    const username = localStorage.getItem('username')
    console.log(userId);  // Check if the user data is available


    if (userId) {
      Echo.private(`App.Models.User.${userId}`)
        .notification((notification) => {
          console.log('New Notification:', notification);
          // Get the current date and time
          const currentDateTime = new Date();
          const date = currentDateTime.toLocaleDateString(); // e.g., 25/09/2024
          const time = currentDateTime.toLocaleTimeString(); // e.g., 14:35:06

          const title = `
                تم إضافة ولي أمر للنظام من قبل ${username} 
                إسم ولي الأمر: ${notification.first_name} 
                رقم ملف ولي الأمر: ${notification.parent_id} 
                التاريخ: ${date} 
                الوقت: ${time}
            `;

          this.$notify({
            title: `
                تم إضافة ولي أمر للنظام من قبل ${username}
                إسم ولي الأمر : ${notification.first_name}
                رقم ملف ولي الأمر: ${notification.parent_id}
                `,
            message: notification.message,
            type: 'success'
          });
          // Store notification in local storage
          this.storeNotification({ title: title });
        });
    } else {
      console.error('User ID is null, cannot subscribe to notifications');
    }
    this.loadNotifications();
  },
};
</script>



<style scoped>
img {
  border-radius: 50%;
  /* Circle shape */
  object-fit: cover;
  /* Crop to fit the space */
}

.el-table>>>.highlight-id-cell {
  background-color: #063f5a !important;
  /* Increased specificity */
  color: white;
  text-align: center;
  font: 800;
  font-size: 20px;
}

.el-table>>>.operation-cell-align-right {
  text-align: right !important;
}

.el-table>>>.el-table__cell {
  text-align: right !important;
}

.operation-buttons {
  display: flex;
  justify-content: flex-end;
}

.custom-dialog {
  transition: all 0.3s ease;
}

.dialog-content {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.dialog-message {
  margin-left: 10px;
  font-size: 16px;
}

.dialog-footer {
  display: flex;
  justify-content: space-evenly;
}



.warning-icon {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-4px);
  }

  75% {
    transform: translateX(4px);
  }
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.parent-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.parent-images {
  display: flex;
  justify-content: space-between;
}

.responsive-image {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.step-actions {
  text-align: right;
  margin-top: 20px;
}

.bg-header-table.dark-mode {
  background-image: linear-gradient(to right, #434343 0%, black 100%);
}

.search-input {
  width: 0px;
  margin-right: 10px;
}

.step-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.bg-header-table {}


.parent-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.parent-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.detail-item {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.parent-images {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.image-item {
  flex: 1;
  text-align: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}
</style>