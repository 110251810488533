<template>
    <div>
      <h2>Student Performance Prediction</h2>
      
      <!-- Input fields for student data -->
      <form @submit.prevent="submitData">
        <div v-for="(student, index) in students" :key="index">
          <label>Student {{ index + 1 }}:</label>
          <input type="number" v-model="student.attendance" placeholder="Attendance" required />
          <input type="number" v-model="student.grades" placeholder="Grades" required />
        </div>
        
        <!-- Add more students -->
        <button type="button" @click="addStudent">Add Student</button>
        
        <!-- Submit form -->
        <button type="submit">Predict</button>
      </form>
      
      <!-- Display predictions -->
      <div v-if="predictions.length">
        <h3>Predictions</h3>
        <ul>
          <li v-for="(prediction, index) in predictions" :key="index">
            Student {{ index + 1 }}: Predicted Grade - {{ prediction }}
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        students: [
          { attendance: 0, grades: 0 }
        ],
        predictions: []
      };
    },
    methods: {
      addStudent() {
        this.students.push({ attendance: 0, grades: 0 });
      },
      async submitData() {
        try {
          const response = await axios.post('http://127.0.0.1:5000/analyze', this.students);
          this.predictions = response.data.predictions;
        } catch (error) {
          console.error('Error during prediction:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  