<template>
    <div class="">
        <el-card class="m-3">
            <div class="col-12 row ">
                <div class="col-2 column">
                    <el-label class="text-dark"> class</el-label>
                    <el-select v-model="form.class_id" @change="fetchSections(); fetchStudents();">
                        <el-option v-for="item in classes" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>

                    </el-select>

                </div>
                <div class="col-4 column" v-if="sections.length > 0">

                    <el-label class="text-dark"> section</el-label>
                    <el-radio-group v-model="form.section_id" @change=" fetchStudents()">
                        <el-radio border v-for="item in sections" :key="item.id" :label="item.name"
                            :value="item.id"></el-radio>

                    </el-radio-group>

                </div>

            </div>


        </el-card>
        <div class="w-100 d-flex flex-row p-3  justify-content-between m-auto ">
            <el-card class="w-50 m-1" v-if="students.students">
                <template #header>
                    <div class="card-header">
                        <span>Students List - قائمة الطلبة</span>
                    </div>
                </template>
                <div class="col-12  d-flex justify-content-between">
                    <button class="btn bnt-sm btn-success d-flex " v-if="selectedStudents.length > 0"
                        @click="sendAttendanceforSelectedStudents('حضور - presence')"> حضور - presence
                        <i class=" material-icons-round opacity-10 fs-4">done_all</i>


                    </button>
                    <button class="btn bnt-sm btn-danger d-flex " v-if="selectedStudents.length > 0"
                        @click="sendAttendanceforSelectedStudents('غياب - absence')"> غياب - absence
                        <i class=" material-icons-round opacity-10 fs-4">done_all</i>


                    </button>
                    <button class="btn bnt-sm btn-warning d-flex " v-if="selectedStudents.length > 0"
                        @click="sendAttendanceforSelectedStudents('اذن - permission')"> اذن - permission
                        <i class=" material-icons-round opacity-10 fs-4">done_all</i>


                    </button>
                </div>
                <div class="col-12">
                    <div v-if="students.students.length > 0"
                        class="d-flex justify-content-between align-items-center mb-2">
                        <div class="col-12  d-flex justify-content-between">
                            <el-checkbox v-model="selectAll" @change="toggleSelectAll">
                                اختيار الكل - select all
                            </el-checkbox>
                        </div>

                    </div>
                    <ul v-if="students.students" class="p-0">
                        <li v-for="item in students.students" :key="item.id" class="border-bottom font-weight-bold">
                            <div class="col-12 row d-flex flex-row align-items-center justify-content-center">
                                <div class="w-5 m-0 p-0">
                                    <el-checkbox v-model="selectedStudents" style="transform: scale(2);"
                                        :value="item.id"></el-checkbox>
                                </div>
                                <div class="col-2 p-0">
                                    <img :src="getPhotoUrl(item.photo, item.gender)" alt="Student Photo"
                                        style="width: 80px; height: 80px; border-radius: 50%; object-fit: cover;" />
                                </div>
                                <label class="col-7 text-dark h6 m-0 pl-0">{{ item.name }}</label>
                                <label class="w-10 p-0 text-dark h6 m-0 d-flex align-items-center">{{ item.class.name }}
                                    <label v-if="item.section" class="text-dark d-flex align-items-center m-0 h6">- {{
                                        item.section.name }}</label>
                                </label>
                                <!-- <div class="col-1 m-0 p-0">
                                    <button class="btn bnt-sm btn-success p-2 py-1  m-0 d-flex"
                                        @click="sendAttendanceforOneStudent(item.id)">
                                        <i class=" material-icons-round opacity-10 fs-3">how_to_reg</i>

                                    </button>
                                </div> -->

                            </div>
                        </li>
                    </ul>
                </div>
            </el-card>
            <el-card class="w-50 m-1" v-if="students.students2">
                <template #header>
                    <div class="card-header">
                        <span> List of attending students - قائمة الطلبة الحاضرين</span>
                    </div>
                </template>
                <div class="col-12">

                    <ul v-if="students.students2" class="p-0">
                        <li v-for="item in students.students2" :key="item.id" class="border-bottom font-weight-bold">
                            <div class="col-12 row d-flex flex-row align-items-center justify-content-center">

                                <div class="col-2 p-0">
                                    <img :src="getPhotoUrl(item.photo, item.gender)" alt="Student Photo"
                                        style="width: 80px; height: 80px; border-radius: 50%; object-fit: cover;" />
                                </div>
                                <label class="col-5 text-dark h6 m-0 pl-0">{{ item.name }}</label>
                                <label class="w-10 p-0 text-dark h6 m-0 d-flex align-items-center">{{ item.class.name }}
                                    <label v-if="item.section" class="text-dark d-flex align-items-center m-0 h6">- {{
                                        item.section.name }}</label>
                                </label>
                                <div class="col-2 m-0 p-0 ss">{{ item.student_attendance_records[0].record_state }}
                                </div>
                                <div class="col-1 m-0 p-0">
                                    <button class="btn bnt-sm btn-dark p-2 py-1  m-0 d-flex"
                                        @click="cancelAttendanceforOneStudent(item.id)">
                                        <i class=" material-icons-round opacity-10 fs-3">person_remove</i>

                                    </button>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
            </el-card>
        </div>


    </div>
</template>

<script>
import axios from 'axios';
// import { from } from 'core-js/core/array';
import { ElCard, ElInput, ElButton, ElTable, ElTableColumn, ElSelect, ElOption, ElForm, ElFormItem, ElDialog, ElRadioGroup, ElRadio, ElCheckboxGroup, ElCheckbox } from 'element-plus';
import malePhoto from '../../assets/img/male.png';
import femalePhoto from '../../assets/img/female.png';
import moneyFin from '../../../public/money-fin.png';
import unspecifiedPhoto from '../../assets/img/unspecified.png';
import { toInteger } from 'lodash';
import { nextTick } from 'vue';
const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use((config) => {
    document.querySelectorAll('button').forEach((button) => {
        button.setAttribute('disabled', '');  // إضافة الخاصية فقط
    });
    const token = localStorage.getItem('access_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
// دالة بعد اكتمال الطلب
api.interceptors.response.use((response) => {
    document.querySelectorAll('button').forEach((button) => {
        button.removeAttribute('disabled');  // إزالة الخاصية
    });
    ;

    // يمكنك هنا التعامل مع البيانات بعد اكتمال الطلب
    // مثال: تعديل البيانات قبل إرجاعها

    return response;  // إرجاع الاستجابة كما هي
}, (error) => {
    document.querySelectorAll('button').forEach((button) => {
        button.removeAttribute('disabled');  // إزالة الخاصية
    });
    // في حالة حدوث خطأ بعد اكتمال الطلب
    console.error("خطأ في الاستجابة:", error);
    return Promise.reject(error);
});

export default {
    components: {
        ElCard,
        ElInput,
        ElButton,
        ElTable,
        ElTableColumn,
        ElSelect,
        ElOption,
        ElForm,
        ElFormItem,
        ElDialog,
        ElRadioGroup,
        ElRadio
    },
    data() {
        return {
            selectAll: false,
            selectedStudents: [],
            classes: [],
            sections: [],
            students: [],

            form: {
                class_id: "",
                section_id: "",
            }
        };
    },
    methods: {

        getPhotoUrl(photoPath, gender) {
            if (photoPath) {
                // Return the photo URL if the student already has one
                return `https://cale.caledonian.ly/storage/${photoPath}`;
            }
            switch (gender) {
                case 'male':
                    return malePhoto;
                case 'female':
                    return femalePhoto;
                default:
                    return unspecifiedPhoto;
            }
        },
        async fetchClasses() {

            try {
                const response = await api.get('/classes');
                this.classes = response.data;
            } catch (error) {
                this.$message.error('Failed to fetch classes');
            }
        },
        async fetchSections() {
            this.sections = [];
            this.form.section_id = "";
            try {
                const response = await api.get('/sections', {
                    params: { class_id: this.form.class_id },
                });
                this.sections = response.data;
            } catch (error) {
                this.$message.error('Failed to fetch sections');
            }
        },
        async fetchStudents() {

            try {
                const response = await api.post("/students-for-attendance", {

                    class_id: this.form.class_id,
                    section_id: this.form.section_id,

                });
                this.students = response.data;
            } catch (error) {
                console.error("Error fetching students:", error);
            }
        },

        async sendAttendanceforOneStudent(student_id) {

            try {
                const response = await api.post("/send-attendance-for-one-student", {

                    class_id: this.form.class_id,
                    section_id: this.form.section_id,
                    student_id: student_id,
                });
                // this.students = response.data;
            } catch (error) {
                console.error("Error  ", error);
            }
            this.fetchStudents();
        },

        async cancelAttendanceforOneStudent(student_id) {

            try {
                const response = await api.post("/cancel-attendance-for-one-student", {

                    class_id: this.form.class_id,
                    section_id: this.form.section_id,
                    student_id: student_id,
                });
                // this.students = response.data;
            } catch (error) {
                console.error("Error  ", error);
            }
            this.fetchStudents();
        },
        afterMountedActions() {
            console.log(this.classes);
        },

        async sendAttendanceforSelectedStudents(status) {

            try {
                const response = await api.post("/send-attendance-for-selected-students", {

                    class_id: this.form.class_id,
                    section_id: this.form.section_id,
                    selectedStudents: this.selectedStudents,
                    status: status,
                });
                // this.students = response.data;
            } catch (error) {
                console.error("Error  ", error);
            }
            this.fetchStudents();
        },

        toggleSelectAll() {
            if (this.selectAll) {
                this.selectedStudents = this.students.students.map(student => student.id);
            } else {
                this.selectedStudents = [];
            }
        }
    },
    watch: {
        selectedStudents(newVal) {
            this.selectAll = newVal.length === this.students.students.length;
        }
    },
    onMounted() {



    }

    ,
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const urlClass = urlParams.get('class');  // 'Ahmed'
        const urlSection = urlParams.get('section');
        if (urlClass != null) {
            this.fetchClasses().then(() => {


                const foundClass = this.classes.find(c => c.name.toLowerCase() === urlClass.toLowerCase());

                if (foundClass) {
                    this.form.class_id = foundClass.id;
                } else {
                    console.log('Class not found');
                }

                if (urlSection == null) {
                    this.fetchStudents();
                } else {
                    this.fetchSections().then(() => {
                        const foundSection = this.sections.find(s => s.name.toLowerCase() === urlSection.toLowerCase());

                        if (foundSection) {
                            this.form.section_id = foundSection.id;
                            this.fetchStudents();
                        } else {
                            console.log('section not found');
                            this.fetchStudents();
                        }
                    });
                }


            });
        }
        else {
            this.fetchClasses();
        }


        // const urlParams = new URLSearchParams(window.location.search);
        // const urlClass = urlParams.get('class');  // 'Ahmed'
        // const urlSection = urlParams.get('section');
        // if (toInteger(urlClass) != 0)
        //     this.form.class_id = toInteger(urlClass);
        // this.fetchSections();
        // if (toInteger(urlSection) != 0)
        //     this.form.section_id = toInteger(urlSection);




    },



};
</script>

<style scoped>
.container {
    margin: 20px;
}

.error-message {
    color: red;
    font-size: 12px;
}

.el-radio {
    margin: 4px;

}

.el-checkbox {
    margin: 0px !important;

}
</style>