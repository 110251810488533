<template>
    <div>
        <div class="table-container">
            <el-card shadow="always" class="custom-card">
              <h2 class="table-title">{{$t('employeesHourSalary')}}</h2>
              <el-table v-loading="loading" :data="teachers" border stripe style="width: 100%">
                <el-table-column prop="name" :label="$t('employeeName')" width="180" align="center"></el-table-column>
                <el-table-column prop="department.name" :label="$t('department')" width="180" align="center"></el-table-column>
                
                <el-table-column :label="$t('status')" width="150" align="center">
                  <template #default="scope">
                    <span v-if="scope.row.has_salary_record" class="status-exists">
                      Salary Data Exists
                    </span>
                    <span v-else class="status-missing">
                      No Salary Data
                    </span>
                  </template>
                </el-table-column>
                
                <el-table-column :label="$t('operations')" width="420" align="center">
                  <template #default="scope">
                    <el-button 
                      v-if="!scope.row.has_salary_record" 
                      @click="openAddDialog(scope.row)" 
                      type="primary" 
                      size="small"
                      icon="el-icon-plus"
                    >
                      {{$t('addSalary')}}
                    </el-button>
                    <el-button
                      v-if="scope.row.has_salary_record"
                      @click="showSalaryData(scope.row)"
                      type="success"
                      size="small"
                      icon="el-icon-document"
                      style="margin-left: 10px"
                    >
                      Show Salary File
                    </el-button>
                    <el-button
                      v-if="scope.row.has_salary_record"
                      type="danger"
                      size="small"
                      icon="el-icon-delete"
                      style="margin-left: 10px"
                      @click="cancelSalaryFile(scope.row)"
                    >
                      Cancel File
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </div>

        <!-- Dialog for Adding Salary -->
        <el-dialog title="Add Salary Per Hour" v-model="addDialogVisible">
            <el-form :model="salaryForm">
                <el-form-item label="Hourly Rate">
                    <el-input 
                        v-model="salaryForm.hourly_rate" 
                        type="number" 
                        placeholder="e.g., 50.00"
                        :min="0"
                    ></el-input>
                </el-form-item>
                
                <!-- Helper Input for Mandatory Attendance Time -->
                <el-form-item label="Mandatory Attendance Time (HH:MM)">
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-input 
                                v-model.number="attendanceTime.hours" 
                                type="number" 
                                placeholder="Hours (e.g., 8)"
                                @input="calculateAttendanceTime"
                            ></el-input>
                        </el-col>
                        <el-col :span="12">
                            <el-input 
                                v-model.number="attendanceTime.minutes" 
                                type="number" 
                                placeholder="Minutes (e.g., 30)"
                                @input="calculateAttendanceTime"
                            ></el-input>
                        </el-col>
                    </el-row>
                    <el-input 
                        v-model="salaryForm.mandatory_attendance_time" 
                        type="text" 
                        placeholder="Result (e.g., 8:30)"
                        readonly
                    ></el-input>
                </el-form-item>

                <el-form-item label="Number of Classes">
                    <el-input 
                        v-model="salaryForm.num_classes" 
                        type="number" 
                        placeholder="e.g., 5"
                        :min="0"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Class Rate">
                    <el-input 
                        v-model="salaryForm.class_rate" 
                        type="number" 
                        placeholder="e.g., 20.00"
                        :min="0"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="submitSalary">Save</el-button>
            </span>
        </el-dialog>

        <!-- Dialog for Showing Salary Data -->
        <el-dialog title="Salary Data" v-model="showDialogVisible">
            <el-table :data="salaryData" style="width: 100%">
                <el-table-column prop="hourly_rate" label="Hourly Rate"></el-table-column>
                <el-table-column prop="mandatory_attendance_time" label="Mandatory Attendance Time"></el-table-column>
                <el-table-column prop="num_classes" label="Number of Classes"></el-table-column>
                <el-table-column prop="class_rate" label="Class Rate"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showDialogVisible = false">Close</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default {
    data() {
        return {
            teachers: [],
            addDialogVisible: false,
            showDialogVisible: false,
            loading: false,
            selectedEmployeeId: null,
            salaryData: [],
            salaryForm: {
                employee_id: null,
                hourly_rate: '',
                mandatory_attendance_time: '',
                num_classes: '',
                class_rate: '',
            },
            attendanceTime: {
                hours: '',
                minutes: '',
            },
        };
    },
    methods: {
        async cancelSalaryFile(teacher) {
      try {
        await api.delete(`/salaries-per-hour/${teacher.id}`);
        ElMessage.success('Salary record deleted successfully');
        // Refresh teacher data to reflect the deletion
        this.fetchTeachers();
      } catch (error) {
        ElMessage.error('Failed to delete salary record');
        console.error("Delete error:", error);
      }
    },
        async fetchTeachers() {
            this.loading = true;
            try {
                const response = await api.get('employees/teachers');
                this.teachers = response.data.map(teacher => ({
                    ...teacher,
                    has_salary_record: teacher.has_salary_record,
                }));
            } catch (error) {
                ElMessage.error('Error fetching teachers.');
            } finally {
                this.loading = false;
            }
        },
        openAddDialog(employee) {
            this.selectedEmployeeId = employee.id;
            this.salaryForm.employee_id = employee.id;
            this.addDialogVisible = true;
        },
        calculateAttendanceTime() {
            const hours = this.attendanceTime.hours || 0;
            const minutes = this.attendanceTime.minutes || 0;
            this.salaryForm.mandatory_attendance_time = (hours * 60) + minutes;
        },
        async submitSalary() {
            try {
                const response = await api.post('salaries_per_hour', this.salaryForm);
                ElMessage.success(response.data.message);
                this.addDialogVisible = false;
                this.fetchTeachers(); // Refresh the list to show updated status
            } catch (error) {
                ElMessage.error('Error saving salary per hour.');
            }
        },
        async showSalaryData(employee) {
            this.selectedEmployeeId = employee.id;
            try {
                const response = await api.get(`salaries_per_hour/${employee.id}`);
                this.salaryData = response.data;
                this.showDialogVisible = true;
            } catch (error) {
                ElMessage.error('Error fetching salary data.');
            }
        },
    },
    mounted() {
        this.fetchTeachers();
    }
};
</script>
<style scoped>
.table-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.custom-card {
  padding: 20px;
}

.table-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
  color: #303133;
  margin-bottom: 20px;
}

.el-table th {
  background-color: #f5f7fa;
  color: #303133;
  font-weight: bold;
  text-align: center;
}

.el-table .el-table__cell {
  font-size: 14px;
}

.status-exists {
  background-color: lightgreen;
  padding: 5px;
  border-radius: 5px;
}

.status-missing {
  color: red;
  padding: 5px;
  border-radius: 5px;
}

.el-button {
  margin: 0 5px;
}
</style>
