<template>
  <div class="container-fluid py-4">
  
    <div class="row">
      
      <div class="col-12">
        <div class="card my-4">
          <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
            <div class="d-flex justify-content-around">
              <h6 class="text-white text-capitalize ps-3">Manage Exams</h6>
              <el-input
                style="width: 200px;"
                v-model="searchQuery"
                placeholder="Search exams..."
                class="search-input"
                @input="fetchExams"
              />
              <el-button @click="dialogVisible = true" type="success" circle class="mr-5">
                <el-icon style="vertical-align: middle">
                  <Document />
                </el-icon>
              </el-button>
            </div>
          </div>
          <div>
            
            <el-table :data="pagedExams" style="width: 100%">
              <el-table-column property="name" label="Exam Name" width="120" />
              <el-table-column property="description" label="Description" />
              <el-table-column label="Class">
                <template #default="scope">
                  <span v-if="scope.row.sections && scope.row.sections.length > 0">
                    {{ scope.row.sections[0].class_name }}
                  </span>
                  <span v-else>N/A</span>
                </template>
              </el-table-column>
              <el-table-column label="Section">
                <template #default="scope">
                  <span v-if="scope.row.sections && scope.row.sections.length > 0">
                    {{ scope.row.sections[0].name }}
                  </span>
                  <span v-else>N/A</span>
                </template>
              </el-table-column>
              <el-table-column label="Subjects">
                <template #default="scope">
                  <ul v-if="scope.row.sections && scope.row.sections.length > 0">
                    <li v-for="subject in scope.row.sections" :key="subject.id">
                      {{ subject.subject_name }} - {{ subject.exam_date }}
                    </li>
                  </ul>
                  <span v-else>N/A</span>
                </template>
              </el-table-column>
              <el-table-column label="Actions" width="180">
                <template #default="scope">
                  <el-button @click="assignGrades(scope.row)" size="mini">Assign grades</el-button>
                  <div style="margin-bottom: 5px;"></div>
                  <el-button @click="deleteExam(scope.row.id)" type="danger" size="mini">Delete</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="d-flex justify-content-center mt-4">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="totalExams"
                layout="prev, pager, next"
                background
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="Add/Edit Exam" width="800">
      <el-form :model="form" ref="examForm">
        <el-form-item label="Exam Name" :rules="[{ required: true, message: 'Please enter exam name', trigger: 'blur' }]">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="form.description"></el-input>
      
        </el-form-item>
        <el-form-item label="Class" :rules="[{ required: true, message: 'Please select a class', trigger: 'change' }]">
          <el-select v-model="form.class_id" @change="fetchSections" placeholder="Select class">
            <el-option v-for="classItem in classes" :key="classItem.id" :label="classItem.name" :value="classItem.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Section" :rules="[{ required: true, message: 'Please select a section', trigger: 'change' }]">
          <el-select v-model="form.section_id" @change="fetchSubjects" placeholder="Select section">
            <el-option v-for="section in sections" :key="section.id" :label="section.name" :value="section.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Subjects and Dates">
          <div v-for="(subject, index) in form.subjects" :key="index" class="subject-date">
            <el-select v-model="subject.id" placeholder="Select subject">
              <el-option v-for="subjectOption in subjects" :key="subjectOption.id" :label="subjectOption.type" :value="subjectOption.id"></el-option>
            </el-select>
            <el-date-picker v-model="subject.exam_date" type="date" placeholder="Pick a date"></el-date-picker>
            <el-button type="danger" icon="el-icon-minus" @click="removeSubject(index)"></el-button>
          </div>
          <el-button type="primary" icon="el-icon-plus" @click="addSubject">Add Subject</el-button>
        </el-form-item>
      </el-form>
      <div class="step-actions">
        <el-button type="primary" @click="submitForm">Submit</el-button>
      </div>
    </el-dialog>

    <el-dialog v-model="dialogAssignGrades" title="Assign Grades" width="800">
      <el-form :model="gradesForm" ref="gradesForm">
        <el-table :data="gradesForm.students" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column property="name" label="Student Name" width="200" />
          <el-table-column v-for="(subject, index) in gradesForm.students[0].grades" :key="subject.subject_id" :label="subject.subject_name">
            <template #default="scope">
              <el-input v-model="scope.row.grades[index].total_grade" placeholder="Total Grade"></el-input>
              <el-input v-model="scope.row.grades[index].real_result" placeholder="Real Result"></el-input>
              <el-input v-model="scope.row.grades[index].estimate" placeholder="Estimate"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <div class="step-actions">
          <el-button type="primary" @click="submitGrades">Submit Grades</el-button>
          <el-button type="secondary" :disabled="!selectedStudents" @click="displayGrades">Display Grades</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog v-model="dialogDisplayGrades" title="Student Grades" width="800">
      <div v-if="selectedStudents.length">
        <div v-for="student in selectedStudents" :key="student.id">
          <h3>Grades for {{ student.name }}</h3>
          <el-table :data="student.grades" style="width: 100%">
            <el-table-column property="subject.type" label="Subject" />
            <el-table-column property="total_grade" label="Total Grade" />
            <el-table-column property="real_result" label="Real Result" />
            <el-table-column property="estimate" label="Estimate" />
          </el-table>
          <hr />
        </div>
        <div class="step-actions">
          <el-button type="primary" @click="printGrades">Print Grades</el-button>
        </div>
      </div>
      <div v-else>
        <p>No grades available for the selected students.</p>
      </div>
    </el-dialog>
    
    
    
  </div>
</template>

<script>
import { Document } from '@element-plus/icons-vue';
import axios from 'axios';
import logo from "@/assets/img/logo-school.png";
import logoWater from "@/assets/img/logo-school-one.png";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

const currentDate = new Date().toLocaleDateString('en-US');
const currentTime = new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

export default {
  name: "Exams",
  components: {
    Document
  },
  data() {
    return {
      dialogVisible: false,
      dialogAssignGrades: false,
      dialogDisplayGrades: false,
      form: {
        name: '',
        description: '',
        class_id: '',
        section_id: '',
        subjects: []
      },
      gradesForm: {
        students: [],
      },
      selectedStudent: null,
      selectedStudents: [],  // Array to hold multiple selected students
      selectedStudentGrades: [],
      exams: [],
      classes: [],
      sections: [],
      subjects: [],
      currentPage: 1,
      pageSize: 10,
      totalExams: 0,
      isEditing: false,
      editingId: null,
      searchQuery: ''
    };
  },
  computed: {
    filteredExams() {
      const query = this.searchQuery.toLowerCase();
      return this.exams.filter(exam => {
        return (
          exam.name.toLowerCase().includes(query) ||
          exam.description.toLowerCase().includes(query) ||
          (exam.sections[0] && exam.sections[0].class_name.toLowerCase().includes(query)) ||
          (exam.sections[0] && exam.sections[0].name.toLowerCase().includes(query)) ||
          (exam.sections[0] && exam.sections[0].subject_name.toLowerCase().includes(query))
        );
      });
    },
    pagedExams() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredExams.slice(startIndex, endIndex);
    }
  },
  methods: {
    async fetchExams() {
      try {
        const response = await api.get('/exams', { params: { search: this.searchQuery } });
        this.exams = response.data.data;
        this.totalExams = response.data.total;
      } catch (error) {
        this.$message.error('Failed to fetch exams');
      }
    },

    ignoreError(){
      const debounce = (callback, delay) => {
    let tid;
    return function (...args) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
            callback.apply(ctx, args);
        }, delay);
    };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
    constructor(callback) {
        callback = debounce(callback, 20);
        super(callback);
    }
};
    },
    async fetchClasses() {
      try {
        const response = await api.get('/classes');
        this.classes = response.data;
      } catch (error) {
        this.$message.error('Failed to fetch classes');
      }
    },
    async fetchSections() {
      try {
        const response = await api.get('/sections', { params: { class_id: this.form.class_id } });
        this.sections = response.data;
      } catch (error) {
        this.$message.error('Failed to fetch sections');
      }
    },
    async fetchSubjects() {
      try {
        const response = await api.get('/subjects-by-class-and-section', {
          params: {
            class_id: this.form.class_id,
            section_id: this.form.section_id
          }
        });
        this.subjects = response.data;
      } catch (error) {
        this.$message.error('Failed to fetch subjects');
      }
    },
    async submitForm() {
      const payload = {
        ...this.form,
        subjects: this.form.subjects.map(subject => ({
          id: subject.id,
          exam_date: subject.exam_date
        }))
      };

      try {
        if (this.isEditing) {
          await api.put(`/exams/${this.editingId}`, payload);
          this.$message.success('Exam updated successfully');
        } else {
          await api.post('/exams', payload);
          this.$message.success('Exam added successfully');
        }
        this.resetForm();
        this.fetchExams();
      } catch (error) {
        this.$message.error('Failed to save exam');
      }
    },
    async assignGrades(exam) {
      this.dialogAssignGrades = true;
      try {
        const studentsResponse = await api.get('/students-by-class-and-section', {
          params: {
            class_id: exam.sections[0].class_id,
            section_id: exam.sections[0].id
          }
        });

        const subjectsResponse = await api.get(`/subjects-for-exam/${exam.id}`);

        this.gradesForm.students = studentsResponse.data.map(student => ({
          ...student,
          grades: subjectsResponse.data.map(subject => ({
            exam_id: exam.id,
            student_id: student.id,
            subject_id: subject.id,
            subject_name: subject.type,
            total_grade: '',
            real_result: '',
            estimate: '',
            exam_date: subject.exam_date
          }))
        }));
      } catch (error) {
        this.$message.error('Failed to fetch students or subjects');
      }
    },
    async submitGrades() {
      try {
        const grades = this.gradesForm.students.flatMap(student =>
          student.grades.map(grade => ({
            ...grade,
            total_grade: grade.total_grade !== '' ? grade.total_grade : null,
            real_result: grade.real_result !== '' ? grade.real_result : null,
            estimate: grade.estimate !== '' ? grade.estimate : null
          }))
        );
        await api.post('/submit-grades', { grades });
        this.$message.success('Grades submitted successfully');
        this.dialogAssignGrades = false;
      } catch (error) {
        this.$message.error('Failed to submit grades');
      }
    },
    handleSelectionChange(selection) {
    this.selectedStudents = selection;
  },
  async displayGrades() {
    if (this.selectedStudents && this.selectedStudents.length > 0) {
      try {
        const promises = this.selectedStudents.map(async (student) => {
          const response = await api.get(`/grades/${this.gradesForm.students[0].grades[0].exam_id}/student/${student.id}`);
          student.grades = response.data;

          const studentResponse = await api.get(`/students/${student.id}`);
          student.class = studentResponse.data.class;
          student.section = studentResponse.data.section;

          return student;
        });

        this.selectedStudents = await Promise.all(promises);
        this.dialogDisplayGrades = true;
      } catch (error) {
        this.$message.error('Failed to fetch grades');
      }
    } else {
      this.$message.warning('Please select at least one student to display grades.');
    }
  },

  printGrades() {
  const printWindow = window.open('', '', 'height=600,width=800');
  printWindow.document.write('<html><head><title>Print Grades</title>');
  printWindow.document.write('<style>');
  printWindow.document.write('body { font-family: Arial, sans-serif; margin: 20px; position: relative; }');
  printWindow.document.write('h1, h3 { text-align: center; }');
  printWindow.document.write('table { width: 100%; border-collapse: collapse; margin-top: 20px; }');
  printWindow.document.write('table, th, td { border: 1px solid black; }');
  printWindow.document.write('th, td { padding: 8px; text-align: left; }');
  printWindow.document.write('.header { display: flex; justify-content: space-between; align-items: center; }');
  printWindow.document.write('.logo { width: 200px; }');
  printWindow.document.write('.watermark { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0.1; z-index: -1; }');
  printWindow.document.write('@media print { .page-break { page-break-before: always; } }');
  printWindow.document.write('</style>');
  printWindow.document.write('</head><body>');

  this.selectedStudents.forEach((student, index) => {
    if (index > 0) {
      printWindow.document.write('<div class="page-break"></div>');
    }

    const qrCodeUrl = `http://api.qrserver.com/v1/create-qr-code/?data=Student%20Number:%20${student.id}%0AStudent's%20Name:%20${student.name}&size=100x100`;

    printWindow.document.write('<div class="header">');
    printWindow.document.write(`<img src="${logo}" class="logo" alt="School Logo">`);
    printWindow.document.write(`
      <div>
        Date: ${currentDate} ${currentTime}
        <h3>Class Name: ${student.class ? student.class.name : ''}</h3>
        Section Name: <span>${student.section ? student.section.name : ''}</span>
      </div>`);
    printWindow.document.write('</div>');
    printWindow.document.write(`<h3>Student Name: ${student.name}</h3>`);

    printWindow.document.write('<div class="watermark">');
    printWindow.document.write(`<img src="${logoWater}" class="logo" alt="Watermark Logo">`);
    printWindow.document.write('</div>');

    printWindow.document.write('<table>');
    student.grades.forEach((grade) => {
      printWindow.document.write('<tr>');
      printWindow.document.write(`<td>${grade.subject.type}</td>`);
      printWindow.document.write(`<td>${grade.total_grade}</td>`);
      printWindow.document.write(`<td>${grade.real_result}</td>`);
      printWindow.document.write(`<td>${grade.estimate}</td>`);
      printWindow.document.write('</tr>');
    });
    printWindow.document.write('</table>');

    printWindow.document.write(`<div style="margin-top:20px;"><img src="${qrCodeUrl}" alt="QR Code"></div>`);
  });

  printWindow.document.write('</body></html>');
  printWindow.document.close();
  printWindow.print();
},

    deleteExam(id) {
      this.$confirm('Are you sure you want to delete this exam?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(async () => {
        try {
          await api.delete(`/exams/${id}`);
          this.$message.success('Exam deleted successfully');
          this.fetchExams();
        } catch (error) {
          this.$message.error('Failed to delete exam');
        }
      });
    },
    addSubject() {
      this.form.subjects.push({ id: '', exam_date: '' });
    },
    removeSubject(index) {
      this.form.subjects.splice(index, 1);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchExams();
    },

    resetForm() {
      this.form = {
        name: '',
        description: '',
        class_id: '',
        section_id: '',
        subjects: []
      };
      this.dialogVisible = false;
      this.isEditing = false;
      this.editingId = null;
    }
  },
  mounted() {
    this.fetchExams();
    this.fetchClasses();
    this.ignoreError();
  }
};
</script>








  
  <style scoped>
  .bg-header-table {
    
  }
  
  .step-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .subject-date {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  </style>
  