<template>
  <div>
    <!-- Filter Form -->
    <el-form :model="filters" label-width="120px">
      <el-form-item label="Teacher Type">
        <el-select v-model="filters.teacher_type_id" placeholder="Select Teacher Type">
          <el-option v-for="type in teacherTypes" :key="type.id" :label="type.type" :value="type.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="Class">
        <el-select v-model="filters.class_id" placeholder="Select Class">
          <el-option v-for="classItem in classes" :key="classItem.id" :label="classItem.name" :value="classItem.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="Section">
        <el-select v-model="filters.section_id" placeholder="Select Section">
          <el-option v-for="section in sections" :key="section.id" :label="section.name" :value="section.id" />
        </el-select>
      </el-form-item>

      <el-form-item label="Gender">
        <el-select v-model="filters.gender" placeholder="Select Gender">
          <el-option label="Male" value="male" />
          <el-option label="Female" value="female" />
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="fetchEmployees">Filter</el-button>
      <el-button @click="printReport">Print Report</el-button>
    </el-form>

    <!-- Employee List -->
    <el-table v-loading="loading" :data="employees" border>
      <el-table-column sortable prop="name" label="Name" />
      <el-table-column prop="phone_number" label="Phone Number" />
      <el-table-column prop="gender" label="Gender" />
      <el-table-column label="Teacher Types">
        <template v-slot="scope">
          <span>
            {{ scope.row.teacher_types ? scope.row.teacher_types.map(type => type.type).join(', ') : 'N/A' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Classes">
        <template v-slot="scope">
          <span>{{ scope.row.classes.map(cls => cls.name).join(', ') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sections">
        <template v-slot="scope">
          <span>{{ scope.row.sections.map(section => section.name).join(', ') }}</span>
        </template>
      </el-table-column>
    </el-table>


    <!-- Pagination -->
    <el-pagination v-if="employees" @current-change="handlePageChange" :current-page="filters.page"
      :page-size="filters.per_page" layout="total, prev, pager, next" :total="employees.total" />
  </div>
</template>

<script>

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import axios from 'axios';
import TajawalBoldBase64 from "@/assets/fonts/TajawalBold.js";  // Assuming you have a valid Base64 font string in this file.
// import pdfFonts from "pdfmake/build/vfs_fonts";
// import pdfMake from "pdfmake/build/pdfmake";
import TajawalBold from "@/assets/fonts/Tajawal-Bold.ttf"; // Ensure this path is correct
import AmiriRegular from "@/assets/fonts/Amiri-Regular.ttf"; // Ensure this path is correct

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

// pdfMake.fonts = {
//   Tajawal: {
//     normal: 'Tajawal-Bold.ttf',
//     bold: 'Tajawal-Bold.ttf',
//   },
//   Amiri: {
//     normal: AmiriRegular,
//     bold: AmiriRegular,
//     italics: AmiriRegular,
//     bolditalics: AmiriRegular,
//   },
// };


const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use((config) => {
  document.querySelectorAll('button').forEach((button) => {
    button.setAttribute('disabled', '');  // إضافة الخاصية فقط
  });
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
// دالة بعد اكتمال الطلب
api.interceptors.response.use((response) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  ;

  // يمكنك هنا التعامل مع البيانات بعد اكتمال الطلب
  // مثال: تعديل البيانات قبل إرجاعها

  return response;  // إرجاع الاستجابة كما هي
}, (error) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  // في حالة حدوث خطأ بعد اكتمال الطلب
  console.error("خطأ في الاستجابة:", error);
  return Promise.reject(error);
});

export default {
  data() {
    return {
      employees: [],
      loading: false,
      teacherTypes: [],
      classes: [],
      sections: [],

      filters: {
        teacher_type_id: null,
        class_id: null,
        section_id: null,
        gender: null,
        page: 1,
        per_page: 10,
      },
    };
  },
  methods: {
    fetchSections(classId) {
      api
        .get(`/classes/${classId}/sections`)
        .then((response) => {
          this.sections = response.data;
        })
        .catch((error) => {
          console.error('Error fetching sections:', error);
        });
    },
    fetchEmployees() {

      api
        .get('/employees-filtered', { params: this.filters })
        .then((response) => {
          // Update this line to correctly assign the employees array
          this.loading = true;
          this.employees = response.data.data; // Access the data property
          this.loading = false
        })
        .catch((error) => {
          console.error(error);
        }).finally(

      )
    },
    handlePageChange(page) {
      this.filters.page = page;
      this.fetchEmployees();
    },
    printReport() {
  api
    .get('/employees/report', { params: this.filters })
    .then((response) => {
      const { totalEmployees, employeesWithTeacherType, employeesWithoutTeacherType, employees } = response.data;

      // Sort employees alphabetically by name
      const sortedEmployees = employees.sort((a, b) => a.name.localeCompare(b.name));

      // Create a new workbook and a worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Employee Report');

      // Add headers
      worksheet.columns = [
        { header: 'Name', key: 'name', width: 30 },
        { header: 'Phone Number', key: 'phone_number', width: 20 },
        { header: 'Gender', key: 'gender', width: 10 },
        { header: 'Teacher Types', key: 'teacher_types', width: 30 },
        { header: 'Classes', key: 'classes', width: 30 },
        { header: 'Sections', key: 'sections', width: 30 },
        { header: 'Base Salary', key: 'base_salary', width: 15 },
        { header: 'Date of Birth', key: 'date_of_birth', width: 15 },
        { header: 'Date of Join', key: 'date_of_join', width: 15 },
        { header: 'Fingerprint number', key: 'fingerprint_id', width: 15 },
        { header: 'Passport Number', key: 'passport_number', width: 20 },
        { header: 'Address', key: 'address', width: 40 },
        { header: 'National Number', key: 'national_number', width: 20 },
      ];

      // Add data rows
      sortedEmployees.forEach(employee => {
        worksheet.addRow({
          name: employee.name,
          phone_number: employee.phone_number,
          gender: employee.gender,
          teacher_types: employee.teacher_types ? employee.teacher_types.map(type => type.type).join(', ') : 'N/A',
          classes: employee.classes ? employee.classes.map(cls => cls.name).join(', ') : 'N/A',
          sections: employee.sections ? employee.sections.map(section => section.name).join(', ') : 'N/A',
          base_salary: employee.base_salary,
          date_of_birth: employee.date_of_birth,
        
          date_of_join: employee.date_of_join,
          fingerprint_id: employee.fingerprint_id,
          passport_number: employee.passport_number,
          address: employee.address,
          national_number: employee.national_number,
        });
      });

      // Add summary information
      worksheet.addRow([]); // Empty row for spacing
      worksheet.addRow(['Total Employees', totalEmployees]);
      worksheet.addRow(['Employees with Teacher Type', employeesWithTeacherType]);
      worksheet.addRow(['Employees without Teacher Type', employeesWithoutTeacherType]);

      // Style the headers
      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD9D9D9' },
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });

      // Write the file and trigger download
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'employee_report.xlsx';
        link.click();
      });
    })
    .catch((error) => {
      console.error(error);
    });
}


  },
  mounted() {
    // Fetch data for filters (teacher types, classes, sections)
    api.get('/teacher-types').then((response) => {
      this.teacherTypes = response.data;
    });
    api.get('/classes').then((response) => {
      this.classes = response.data;
    });


    this.fetchEmployees(); // Fetch initial data
  },
  watch: {
    'filters.class_id'(newClassId) {
      if (newClassId) {
        this.fetchSections(newClassId);
      } else {
        this.sections = []; // Clear sections if no class is selected
      }
    }
  },

};
</script>