<template>
  <div>
    <el-input v-model="searchQuery" placeholder="Search by student name" @input="fetchHealthFiles"
      style="margin-bottom: 20px;" />

    <el-table :data="studentsWithHealthFiles" style="width: 100%">
      <el-table-column prop="name" label="Student Name" />
      <el-table-column :min-width="120" label=" parent phone number">
        <template v-slot="scope">

          <div style="width: 50;" v-if="scope.row.parent"> {{
            scope.row.parent.phone_number_one
          }}
            <qrcode-canvas :value="'tel:' + scope.row.parent.phone_number_one"></qrcode-canvas>
          </div>


        </template>
      </el-table-column>
      <el-table-column :min-width="120" label=" second  phone number">
        <template v-slot="scope">

          <div style="width: 50;" v-if="scope.row.parent"> {{
            scope.row.parent.phone_number_two
          }}
            <qrcode-canvas :value="'tel:' + scope.row.parent.phone_number_two"></qrcode-canvas>
          </div>


        </template>
      </el-table-column>
      <el-table-column prop="health_file.age" label="Age" />
      <el-table-column prop="health_file.weight" label="Weight" />
      <el-table-column prop="health_file.height" label="Height" />
      <el-table-column prop="health_file.blood_type" label="Blood Type" />
      <el-table-column prop="health_file.medical_history" label="Medical History" />
      <el-table-column prop="health_file.hearing" label="Hearing" />
      <el-table-column prop="health_file.sight" label="Sight" />
      <el-table-column label="Diabetes Mellitus" :formatter="diabetesMellitusFormatter" />
      <el-table-column prop="health_file.food_allergies" label="Food Allergies" />
      <el-table-column prop="health_file.chronic_disease" label="Chronic Disease" />
      <el-table-column prop="health_file.clinical_examination" label="Clinical Examination" />
      <el-table-column prop="health_file.result_clinical_examination" label="Result Clinical Examination" />
      <el-table-column prop="health_file.vaccinations" label="Vaccinations" />
      <el-table-column label="Operation">
        <template v-slot="scope">
          <el-button @click="printHealthFile(scope.row.health_file.id)" type="primary" size="small">Print</el-button>
          <el-button @click="deleteHealthFile(scope.row.health_file.id)" type="danger" size="small">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Pagination Controls -->
    <el-pagination background layout="prev, pager, next" :page-size="pagination.per_page" :total="pagination.total"
      @current-change="handlePageChange" />
  </div>
</template>


<script>
import { ElMessage } from 'element-plus';
import axios from 'axios';
import logo from "@/assets/img/logo-school.png";
import logoWater from '@/assets/img/drugstore.png';
import TajawalBold from "@/assets/fonts/Tajawal-Bold.ttf";
import AmiriRegular from "@/assets/fonts/Amiri-Regular.ttf";
import { Qrcode, QrcodeCanvas, QrcodeSvg } from 'qrcode.vue';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

export default {
  components: {

    Qrcode,
    QrcodeCanvas,
    QrcodeSvg,
  },
  data() {
    return {
      studentsWithHealthFiles: [],
      pagination: {
        total: 0,
        per_page: 10,
        current_page: 1,
      },
      searchQuery: '', // Add this for search functionality
    };
  },
  methods: {
    async fetchHealthFiles(page = 1) {
      try {
        const { data } = await api.get(`/health-files`, {
          params: {
            page,
            search: this.searchQuery // Pass the search query to the backend
          }
        });
        this.studentsWithHealthFiles = data.data;
        this.pagination = {
          total: data.total,
          per_page: data.per_page,
          current_page: data.current_page,
        };
      } catch (error) {
        ElMessage.error('Failed to load health files');
      }
    },
    handlePageChange(page) {
      this.fetchHealthFiles(page); // Trigger API call on page change
    },
    diabetesMellitusFormatter(row) {
      return row.health_file.diabetes_mellitus === 1 ? 'لديه' : 'ليس لديه';
    },
    printHealthFile(healthFileId) {
      const student = this.studentsWithHealthFiles.find(student => student.health_file.id === healthFileId);
      if (!student || !student.health_file) {
        ElMessage.error('Health file not found');
        return;
      }

      const healthFile = student.health_file;
      const studentName = student.name || "لا يوجد اسم";
      const age = healthFile.age || "لا يوجد بيانات";
      const weight = healthFile.weight || "لا يوجد بيانات";
      const height = healthFile.height || "لا يوجد بيانات";
      const bloodType = healthFile.blood_type || "لا يوجد بيانات";
      const medicalHistory = healthFile.medical_history || "لا يوجد بيانات";
      const hearing = healthFile.hearing || "لا يوجد بيانات";
      const sight = healthFile.sight || "لا يوجد بيانات";
      //   const diabetesMellitus = this.diabetesMellitusFormatter(healthFile) || "لا يوجد بيانات";
      const foodAllergies = healthFile.food_allergies || "لا يوجد بيانات";
      const chronicDisease = healthFile.chronic_disease || "لا يوجد بيانات";
      const clinicalExamination = healthFile.clinical_examination || "لا يوجد بيانات";
      const resultClinicalExamination = healthFile.result_clinical_examination || "لا يوجد بيانات";
      const vaccinations = healthFile.vaccinations || "لا يوجد بيانات";

      const printContent = `
  <html>
  <head>
    <title>Print Health File</title>
    <style>
      @font-face {
        font-family: 'TajawalBold';
        src: url('${TajawalBold}') format('truetype');
      }
      @font-face {
        font-family: 'PlaywritePE-Thin';
        src: url('${AmiriRegular}') format('truetype');
      }
      body { 
        font-family: 'PlaywritePE-Thin', Arial, sans-serif; 
        margin: 0;
        padding: 0;
        position: relative;
        direction: rtl;
        font-size: 10pt;
      }
      .watermark {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.1;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-image: url('${logoWater}');
        background-size: contain;
        background-repeat: no-repeat;
      }
      .receipt {
        margin: 10px;
        padding: 5px;
        border: 1px solid #000;
        width: 100%;
        box-sizing: border-box;
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }
      .header .logo {
        width: 80px;
      }
      .header .title {
        text-align: center;
        flex-grow: 1;
      }
      .header .school-name {
        text-align: left;
        font-family: 'TajawalBold', Arial, sans-serif;
        color: #161D6F;
        font-size: 14pt;
      }
      strong {
        background-color: #16325B;
        color: white;
        padding: 2px 4px;
        margin-bottom: 2px;
        display: inline-block;
      }
      .info {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        gap: 10px; /* Space between items */
      }
      .info div {
        margin: 3px 0;
      }
      .info span.value {
        background-color: #CDC1FF;
        color: #201E43;
        padding: 2px 4px;
        display: inline-block;
        width: calc(100% - 20px);
        text-align: center;
        box-sizing: border-box;
      }
      .qr-code {
        margin-top: 10px;
        width: 80px;
        height: 80px;
        text-align: center;
      }
      .footer {
        margin-top: 10px;
        padding: 5px;
        background-color: #f4f4f4;
        border-top: 1px solid #ddd;
        text-align: center;
      }
      .footer .invoice-number-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .footer .inv-title-1 {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .footer .invo-addr-1 {
        font-size: 0.8em;
      }
      .footer img {
        vertical-align: middle;
      }
      @media print {
        .watermark {
          display: block;
        }
        .page-break {
          page-break-before: always;
        }
      }
    </style>
  </head>
  <body>
    <div class="watermark"></div>
    <div class="receipt">
      <div class="header">
        <div class="school-name">
          مدرسة كاليدونيا الدولية
        </div>
        <div class="title">
          <br />
          <strong>رقم السجل الصحي:</strong> ${healthFile.id}
        </div>
        <div class="logo">
          <img src="${logo}" alt="Logo" width="80" />
        </div>
      </div>

      <div class="info">
        <div><strong>إســـم الطالـــب:</strong> <span class="value">${studentName}</span></div>
        <div><strong>السن:</strong> <span class="value">${age}</span></div>
        <div><strong>الوزن:</strong> <span class="value">${weight}</span></div>
        <div><strong>الطول:</strong> <span class="value">${height}</span></div>
        <div><strong>فصيلة الدم:</strong> <span class="value">${bloodType}</span></div>
        <div><strong>التاريخ الطبي:</strong> <span class="value">${medicalHistory}</span></div>
        <div><strong>السمع:</strong> <span class="value">${hearing}</span></div>
        <div><strong>النظر:</strong> <span class="value">${sight}</span></div>
        <div><strong>حساسية الطعام:</strong> <span class="value">${foodAllergies}</span></div>
        <div><strong>الأمراض المزمنة:</strong> <span class="value">${chronicDisease}</span></div>
        <div><strong>الفحص السريري:</strong> <span class="value">${clinicalExamination}</span></div>
        <div><strong>نتائج الفحص السريري:</strong> <span class="value">${resultClinicalExamination}</span></div>
        <div><strong>التطعيمات:</strong> <span class="value">${vaccinations}</span></div>
      </div>

      <div class="qr-code">
        <img src="http://api.qrserver.com/v1/create-qr-code/?data=${healthFile.id}&size=80x80" alt="QR Code" />
      </div>

      <div class="footer">
        <div class="invoice-number-inner">
          <h4 class="inv-title-1">Contact Us /</h4>
          <p class="invo-addr-1">
            <img style="width:17px; height:17px; margin-right:8px;" src="./location.png" /> Cordoba 2 - Hay Al Andalus - Behind Pasta Factory
            <br/>
            <img style="width:17px; height:17px; margin-right:8px;" src="./mail.png" /> info@caledonian.ly <br/>
            <img style="width:17px; height:17px; margin-right:8px;" src="./phone-call.png" /> 0916676973 <br/>
          </p>
        </div>
      </div>
    </div>
  </body>
  </html>
`;

      // Open a new window and write the print content
      const printWindow = window.open('', '_blank', 'width=800,height=600');
      printWindow.document.open();
      printWindow.document.write(printContent);
      printWindow.document.close();

      // Wait for the document to load before triggering print
      printWindow.onload = () => {
        printWindow.print();
        printWindow.close();
      };
    },
    async deleteHealthFile(healthFileId) {
      try {
        await api.delete(`/health-files/${healthFileId}`);
        ElMessage.success('Health file deleted successfully');
        this.fetchHealthFiles(this.pagination.current_page); // Refresh the current page
      } catch (error) {
        ElMessage.error('Failed to delete health file');
      }
    },
  },
  mounted() {
    this.fetchHealthFiles(); // Load data on component mount
  },
};
</script>
<style>

</style>