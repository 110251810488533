<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
              <div class="d-flex justify-content-around">
                <h6 class="text-white text-capitalize ps-3">Employee Records</h6>
                <el-input style="width: 100px;" v-model="searchQuery" placeholder="Name" class="search-input"
                  @input="handleSearch" />
                <el-input style="width: 100px;" v-model="searchPhoneQuery" placeholder="Phone" class="search-input"
                  @input="handleSearch" />
                <el-input style="width: 100px;" v-model="searchNationalQuery" placeholder="National ID"
                  class="search-input" @input="handleSearch" />
                <el-input style="width: 100px;" v-model="searchById" placeholder="File Number" class="search-input"
                  @input="handleSearch" />
                <el-input style="width: 100px;" v-model="searchPassportQuery" placeholder="Passport Number"
                  class="search-input" @input="handleSearch" />

              </div>

              <el-button @click="dialogVisible = true" type="success" circle class="mr-5">
                <el-icon style="vertical-align: middle">
                  <Document />
                </el-icon>
              </el-button>
            </div>

            <!-- Table Data -->

            <el-table v-loading="loading" :data="allEmployees" style="width: 100%" :cell-class-name="highlightIdCell">
              <el-table-column label="#" width="50" align="right">
                <template #default="scope">
                  {{ ((currentPage - 1) * 10) + scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column type="expand" label="Actions" width="290">
                <template #default="scope">
                  <el-button @click="showEmployee(scope.row)" type="primary" size="mini">View</el-button>
                  <el-button @click="editEmployee(scope.row)" type="warning" size="mini">Edit</el-button>
                  <el-popconfirm title="Are you sure you want to delete this employee?" confirm-button-text="Yes"
                    cancel-button-text="No" icon="el-icon-warning" icon-color="red"
                    @confirm="deleteEmployee(scope.row.id)">
                    <template #reference>
                      <el-button type="danger" size="mini">Delete</el-button>
                    </template>
                  </el-popconfirm>

                </template>
              </el-table-column>
              <el-table-column property="id" label="FILE NUMBER OF Employee" width="200"
                :cell-class-name="highlightIdCell" />
              <el-table-column property="name" label="Name" width="120" />
              <el-table-column property="phone_number" label="Phone Number" width="120" />
              <el-table-column property="passport_number" label="passport number" width="120" />
              <el-table-column property="address" label="Address" />
              <el-table-column property="department.name" label="Department" width="180" />
              <el-table-column property="employee_type.name" label="Position" width="150" />
            </el-table>

            <!-- Pagination -->
            <div class="d-flex justify-content-center mt-4">
              <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
                :total="totalEmployees" layout="prev, pager, next" background />
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>

        <!-- Dialog for adding a new employee -->
        <el-dialog v-model="dialogVisible" title="Add Employee" width="800px" :close-on-click-modal="false">
            <span>Please fill out the form below</span>
          
            <div class="employee-form">
              <el-form :model="form" @submit.prevent="submitForm" label-position="top">
                <div class="grid-container">
                  <el-form-item label="Name" required>
                    <el-input v-model="form.name" placeholder="Enter Name" />
                  </el-form-item>
          
                  <el-form-item label="Gender" required>
                    <el-select v-model="form.gender" placeholder="Select Gender">
                      <el-option label="Male" value="male"></el-option>
                      <el-option label="Female" value="female"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item v-if="userType === 'developer' "  label="Associated User">
  <el-select
    v-model="form.user_id"
    filterable
    remote
    reserve-keyword
    placeholder="Search and select a user"
    :remote-method="remoteMethod"
    :loading="userLoading"
    @change="handleUserSelect"
    clearable
  >
    <el-option
      v-for="user in users"
      :key="user.id"
      :label="user.name + ' (ID: ' + user.id + ')'"
      :value="user.id">
    </el-option>
  </el-select>
</el-form-item>
          
                  <el-form-item label="Date of Birth" required>
                    <el-date-picker v-model="form.date_of_birth" type="date" placeholder="Select Date of Birth" />
                  </el-form-item>
          
                  <el-form-item label="Fingerprint Number">
                    <el-input v-model="form.fingerprint_id" type="text" placeholder="Fingerprint Number" />
                  </el-form-item>
          
                  <el-form-item label="Passport Number">
                    <el-input v-model="form.passport_number" placeholder="Enter Passport Number" />
                  </el-form-item>
          
                  <el-form-item label="Department" required>
                    <el-select v-model="form.department_id" placeholder="Select Department">
                      <el-option v-for="department in departments" :key="department.id" :label="department.name" :value="department.id"></el-option>
                    </el-select>
                  </el-form-item>
          
                  <el-form-item label="Teacher Types">
                    <el-select v-model="form.teacher_type_ids" multiple placeholder="Select Teacher Types" @change="fetchClassesAndSections" filterable>
                      <el-option v-for="teacherType in teacherTypes" :key="teacherType.id" :label="teacherType.type" :value="teacherType.id"></el-option>
                    </el-select>
                  </el-form-item>
          
                  <el-form-item label="Classes" v-if="form.teacher_type_ids.length">
                    <el-select v-model="form.class_ids" multiple placeholder="Select Classes" @change="fetchSections" :disabled="!form.teacher_type_ids.length">
                      <el-option v-for="classRoom in classes" :key="classRoom.id" :label="classRoom.name" :value="classRoom.id"></el-option>
                    </el-select>
                  </el-form-item>
          
                  <el-form-item label="Sections" v-if="form.class_ids.length">
  <el-select v-model="form.section_ids" multiple placeholder="Select Sections" :disabled="!form.class_ids.length">
    <el-option v-for="section in filteredSections" :key="section.id" :label="section.name" :value="section.id"></el-option>
  </el-select>
</el-form-item>
          
                  <el-form-item label="Employee Type">
                    <el-select v-model="form.employee_type_id" placeholder="Select Employee Type">
                      <el-option v-for="employeeType in employeeTypes" :key="employeeType.id" :label="employeeType.name" :value="employeeType.id"></el-option>
                    </el-select>
                  </el-form-item>
          
                  <el-form-item label="National ID">
                    <el-input v-model="form.national_number" placeholder="Enter National ID" />
                  </el-form-item>
          
                  <el-form-item label="Phone Number" required>
                    <el-input v-model="form.phone_number" placeholder="Enter Phone Number" />
                  </el-form-item>
          
                  <el-form-item label="Second Phone Number">
                    <el-input v-model="form.phone_number_two" placeholder="Enter Second Phone Number" />
                  </el-form-item>
          
                  <el-form-item label="Address" required>
                    <el-input v-model="form.address" placeholder="Enter Address" />
                  </el-form-item>
          
                  <el-form-item label="Date of Join" required>
                    <el-date-picker v-model="form.date_of_join" type="date" placeholder="Select Date of Join" />
                  </el-form-item>
          
                  <el-form-item label="Photos">
                    <el-upload
                      action=""
                      list-type="picture-card"
                      :file-list="photosList"
                      :on-remove="removePhoto"
                      :before-upload="beforeUploadPhoto"
                      :multiple="true"
                      :limit="10"
                    >
                      <el-button type="primary">Upload Photos</el-button>
                    </el-upload>
                  </el-form-item>
                  
                  <el-form-item label="Attached Files">
                    <el-upload
                      action=""
                      :file-list="attachedFilesList"
                      :on-remove="removeAttachedFile"
                      :before-upload="beforeUploadAttachedFile"
                      :multiple="true"
                      :limit="10"
                    >
                      <el-button type="primary">Upload Files</el-button>
                    </el-upload>
                  </el-form-item>
                  <hr/>
          
                  <el-form-item v-if="userType === 'moder' " label="Basic Salary" style="margin-left: 190px !important;">
                    <el-input-number v-model="form.base_salary" :min="0" placeholder="Base Salary" />
                  </el-form-item>
                  <el-form-item label="Years of Experience">
                    <el-input-number style="right:200px;" v-model="form.years_of_experience" :min="0" placeholder="Years of Experience" />
                  </el-form-item>
          
                  <el-form-item>
                    <el-button type="primary" @click="submitForm">Submit</el-button>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </el-dialog>
          
        <!-- Dialog for edit a new employee -->
          
        <el-dialog v-model="dialogEditVisible" title="Edit Employee" width="800px" :close-on-click-modal="false">
          <span>Please fill out the form below</span>
  
          <div class="employee-form">
              <el-form :model="form" @submit.prevent="submitEditForm" label-position="top">
                  <div class="grid-container">
                      <el-form-item label="Name" required>
                          <el-input v-model="form.name" placeholder="Enter Name" />
                      </el-form-item>
  
                      <el-form-item label="Gender" required>
                          <el-select v-model="form.gender" placeholder="Select Gender">
                              <el-option label="Male" value="male"></el-option>
                              <el-option label="Female" value="female"></el-option>
                          </el-select>
                      </el-form-item>

                      <el-form-item v-if="userType === 'developer' "  label="Associated User">
  <el-select
    v-model="form.user_id"
    filterable
    remote
    reserve-keyword
    placeholder="Search and select a user"
    :remote-method="remoteMethod"
    :loading="userLoading"
    @change="handleUserSelect"
    clearable
  >
    <el-option
      v-for="user in users"
      :key="user.id"
      :label="user.name + ' (ID: ' + user.id + ')'"
      :value="user.id">
    </el-option>
  </el-select>
</el-form-item>
  
                      <el-form-item label="Date of Birth" required>
                          <el-date-picker v-model="form.date_of_birth" type="date" placeholder="Select Date of Birth" />
                      </el-form-item>
                      <el-form-item label="Date of Join" required>
                        <el-date-picker v-model="form.date_of_join" type="date" placeholder="Select Date of Join" />
                      </el-form-item>
                      <el-form-item label="Fingerprint Number">
                        <el-input v-model="form.fingerprint_id" type="text" placeholder="Fingerprint Number" />
                      </el-form-item>
  
                      <el-form-item label="Passport Number">
                          <el-input v-model="form.passport_number" placeholder="Enter Passport Number" />
                      </el-form-item>
  
                      <el-form-item label="Department" required>
                          <el-select v-model="form.department_id" placeholder="Select Department">
                              <el-option v-for="department in departments" :key="department.id" :label="department.name" :value="department.id"></el-option>
                          </el-select>
                      </el-form-item>
  
                      <el-form-item label="Teacher Types">
                          <el-select v-model="form.teacher_type_ids" multiple placeholder="Select Teacher Types" @change="fetchClassesAndSections" filterable>
                              <el-option v-for="teacherType in teacherTypes" :key="teacherType.id" :label="teacherType.type" :value="teacherType.id"></el-option>
                          </el-select>
                      </el-form-item>
  
                      <el-form-item label="Classes" v-if="form.teacher_type_ids.length">
                          <el-select v-model="form.class_ids" multiple placeholder="Select Classes" @change="fetchSections">
                              <el-option v-for="classRoom in classes" :key="classRoom.id" :label="classRoom.name" :value="classRoom.id"></el-option>
                          </el-select>
                      </el-form-item>
  
                      <el-form-item label="Sections" v-if="form.class_ids.length">
  <el-select v-model="form.section_ids" multiple placeholder="Select Sections" :disabled="!form.class_ids.length">
    <el-option v-for="section in filteredSections" :key="section.id" :label="section.name" :value="section.id"></el-option>
  </el-select>
</el-form-item>
  
                      <el-form-item label="Employee Type">
                          <el-select v-model="form.employee_type_id" placeholder="Select Employee Type">
                              <el-option v-for="employeeType in employeeTypes" :key="employeeType.id" :label="employeeType.name" :value="employeeType.id"></el-option>
                          </el-select>
                      </el-form-item>
  
                      <el-form-item label="National ID">
                          <el-input v-model="form.national_number" placeholder="Enter National ID" />
                      </el-form-item>
  
                      <el-form-item label="Phone Number" required>
                          <el-input v-model="form.phone_number" placeholder="Enter Phone Number" />
                      </el-form-item>
  
                      <el-form-item label="Second Phone Number">
                          <el-input v-model="form.phone_number_two" placeholder="Enter Second Phone Number" />
                      </el-form-item>
  
                      <el-form-item label="Address" required>
                          <el-input v-model="form.address" placeholder="Enter Address" />
                      </el-form-item>
  
                    
  


          <!-- Display Existing Photos -->
          <el-form-item label="Current Photos">
            <div v-if="form.photos && form.photos.length">
              <el-image v-for="(photo, index) in form.photos" :key="index" :src="photo"
                style="width: 100px; height: 100px; margin-right: 10px;">
              </el-image>
            </div>
            <div v-else>No photos available</div>
          </el-form-item>
          <!-- Upload New Photos -->
          <el-form-item label="Upload New Photos">
            <el-upload action="#" :auto-upload="false" multiple :on-change="handlePhotosChange" :file-list="photoFiles">
              <el-button type="primary">Upload Photos</el-button>
            </el-upload>
          </el-form-item>

          <!-- Display Attached Files -->
          <el-form-item label="Current Attached Files">
            <div v-if="form.attached_files && form.attached_files.length">
              <el-link v-for="(file, index) in form.attached_files" :key="index" :href="file" target="_blank"
                style="display: block;">
                File {{ index + 1 }}
              </el-link>
            </div>
            <div v-else>No files available</div>
          </el-form-item>

          <!-- Upload New Files -->
          <el-form-item label="Upload New Files">
            <el-upload action="#" :auto-upload="false" multiple :on-change="handleFilesChange" :file-list="fileUploads">
              <el-button type="primary">Upload Files</el-button>
            </el-upload>
          </el-form-item>

  
                      <el-form-item v-if="userType === 'moder' " label="Base Salary">
                          <el-input-number v-model="form.base_salary" :min="0" placeholder="Base Salary" />
                      </el-form-item>
                      <el-form-item label="Years of Experience">
                          <el-input-number v-model="form.years_of_experience" :min="0" placeholder="Years of Experience" />
                      </el-form-item>
  
                      <el-form-item>
                          <el-button type="primary" @click="submitEditForm">Submit</el-button>
                      </el-form-item>
                  </div>
              </el-form>
          </div>
      </el-dialog>
        

  <!-- Dialog for showing employee details and salary -->
  <el-dialog v-model="salaryDialogVisible" title="Employee Salary Details">
    <el-form :model="selectedSalary" ref="salaryForm" label-width="120px">
      <!-- Display employee details -->
      <el-form-item label="Name">
        <el-input v-model="selectedEmployee.name" disabled></el-input>
      </el-form-item>

      <!-- Salary input -->
      <el-form-item label="Salary">
        <el-input v-model="selectedSalary.amount" type="number" placeholder="Enter salary"></el-input>
      </el-form-item>

      <!-- Deductions -->
      <el-form-item label="Deductions">
        <el-select v-model="selectedDeductions" multiple placeholder="Select deduction type">
          <el-option v-for="deduction in deductionsFetchedAll" :key="deduction.id" :label="deduction.name"
            :value="deduction.id">
          </el-option>
        </el-select>
      </el-form-item>

      <!-- Submit and Cancel Buttons -->
      <el-form-item>
        <el-button type="primary" @click="submitSalaryForm">Submit</el-button>
        <el-button @click="salaryDialogVisible = false">Cancel</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>


  <el-dialog v-model="showDialogVisible" title="تفاصيل الموظف" width="800">
    <div v-if="selectedEmployee" class="employee-info">
      <div class="employee-details">
        <div class="detail-item">
          <strong>الاسم:</strong> {{ selectedEmployee.name }}
        </div>
        <div class="detail-item">
          <strong>رقم المستخدم:</strong> {{ selectedEmployee.id }}
        </div>
        <div class="detail-item">
          <strong>رقم القسم:</strong> {{ selectedEmployee.department ? selectedEmployee.department.name : 'N/A' }}
        </div>
        <div class="detail-item">
          <strong>نوع المعلم:</strong> {{ selectedEmployee.teacher_type ? selectedEmployee.teacher_type.type : 'N/A' }}
        </div>
        <div class="detail-item">
          <strong>الرقم الوطني:</strong> {{ selectedEmployee.national_number }}
        </div>
        <div class="detail-item">
          <strong>رقم الهاتف:</strong> {{ selectedEmployee.phone_number }}
        </div>
        <div class="detail-item">
          <strong>رقم الهاتف الثاني:</strong> {{ selectedEmployee.phone_number_two }}
        </div>
        <div class="detail-item">
          <strong>العنوان:</strong> {{ selectedEmployee.address }}
        </div>
        <div class="detail-item">
          <strong>الرمز السري:</strong> {{ selectedEmployee.pin }}
        </div>
      </div>
      <div class="employee-images">
        <div class="image-item" v-for="(photo, index) in parsedPhotos" :key="index">
          <strong>Image {{ index + 1 }}:</strong>
          <img :src="getImageUrl(photo)" alt="Employee Image" class="responsive-image">
        </div>
      </div>
    </div>
  </el-dialog>

</template>

<script>
import { Document, Money } from '@element-plus/icons-vue';
import { ElPopconfirm } from 'element-plus';
import axios from 'axios';
import { mapGetters ,mapMutations } from "vuex";
import { debounce } from 'lodash';


const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
});

api.interceptors.request.use((config) => {
  document.querySelectorAll('button').forEach((button) => {
    button.setAttribute('disabled', '');  // إضافة الخاصية فقط
  });
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
// دالة بعد اكتمال الطلب
api.interceptors.response.use((response) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  ;

  // يمكنك هنا التعامل مع البيانات بعد اكتمال الطلب
  // مثال: تعديل البيانات قبل إرجاعها

  return response;  // إرجاع الاستجابة كما هي
}, (error) => {
  document.querySelectorAll('button').forEach((button) => {
    button.removeAttribute('disabled');  // إزالة الخاصية
  });
  // في حالة حدوث خطأ بعد اكتمال الطلب
  console.error("خطأ في الاستجابة:", error);
  return Promise.reject(error);
});

export default {
  name: "Employees",
  components: {
    Document,
    Money,
    ElPopconfirm
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      users: [], // For storing fetched users
      userLoading: false, // For loading state
      userSearchQuery: '', // For search term
      dialogEditVisible: false,
      showDialogVisible: false,
      salaryDialogVisible: false,
      selectedEmployee: null,
      selectedSalary: null,
      selectedDeductions: [],
      deductionsFetchedAll: [],
      selectedAbsences: [],

      form: {
        name: '',
        date_of_join: '',
        fingerprint_id: '',
        employee_type_id: '',
        user_id: '',
        department_id: null,
        teacher_type_ids: [], // Updated to allow multiple teacher types
        class_ids: [], // Updated to allow multiple classes
        section_ids: [], // Updated to allow multiple sections
        class_id: '',
        section_id: '',
        national_number: '',
        phone_number: '',
        phone_number_two: '',
        address: '',
        pin: '',
        photos: [],
        attached_files: [], // New field
        years_of_experience: 0,
        base_salary: 0,
        gender: '', // New field
        date_of_birth: '', // New field
        passport_number: '' // New field
      },
      photosList: [],
      attachedFilesList: [],
      employeeTypes: [],
      filteredSections: [],
      searchQuery: '', // Search input model
      searchById: '', // for ID
      searchNationalQuery: '', // New: for national number
      searchPhoneQuery: '', // New: for national number
      searchPassportQuery: '', // New: for passport number
      allEmployees: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10, // Number of records per page
      totalEmployees: 0,
      departments: [],
      teacherTypes: [],
      classes: [],
      sections: [],
      message: ''
    };
  },
  methods: {
    async searchUsers(query) {
    if (query) {
      this.userLoading = true;
      try {
        const response = await api.get('/users', { params: { name: query } });
        this.users = response.data.data;
      } catch (error) {
        console.error('Error searching users:', error);
      } finally {
        this.userLoading = false;
      }
    }
  },
  
  handleUserSelect(user) {
    this.form.user_id = user;
  },
  
  remoteMethod: debounce(function(query) {
    this.searchUsers(query);
  }, 300),
    highlightIdCell({ row, column }) {

      if (column.property === 'id') {
        return 'highlight-id-cell';
      }
      return '';
    },

    async fetchDeductionsdAll() {
      try {
        // deductionTypes
        const response = await api.get('/deduction-types');
        this.deductionsFetchedAll = response.data;
      } catch (e) {
        console.error('Error fetching deduction:', error);
        this.$message.error('Failed to fetching deduction.');
      }
    },
    async submitSalaryForm() {
      try {
        // API call to update salary and deductions
        const response = await api.put(`/employees/${this.selectedEmployee.id}/update-salary`, {
          salary: this.selectedSalary.amount,
          deductions: this.selectedDeductions
        });

        this.$message.success('Salary updated successfully');
        this.salaryDialogVisible = false; // Close dialog after success
      } catch (error) {
        console.error('Error updating salary:', error);
        this.$message.error('Failed to update salary.');
      }
    },
    handlePhotosChange(file, fileList) {
      // Handle the files selected by the user
      this.photoFiles = fileList.map(file => file.raw); // Store the raw file objects
    },
    handleFilesChange(file, fileList) {
      // Handle the files selected by the user
      this.fileUploads = fileList.map(file => file.raw); // Store the raw file objects
    },

    beforeUploadPhoto(file) {
      this.form.photos.push(file);
      this.photosList.push(file);
      return false; // Prevent automatic upload
    },
    removePhoto(file) {
      const index = this.form.photos.indexOf(file);
      if (index > -1) {
        this.form.photos.splice(index, 1);
      }
    },
    beforeUploadAttachedFile(file) {
      this.form.attached_files.push(file);
      this.attachedFilesList.push(file);
      return false; // Prevent automatic upload
    },
    removeAttachedFile(file) {
      const index = this.form.attached_files.indexOf(file);
      if (index > -1) {
        this.form.attached_files.splice(index, 1);
      }
    },
    handlePhotoUpload(file) {
      this.form.photos.push(file.raw);
    },
    // handleFileUpload(file) {
    //   this.form.attached_files.push(file.raw);
    // },
    formatDateOfBirth(value) {
      // This method formats the date to YYYY-MM-DD
      if (!value) return ''; // Handle empty values safely
      const d = new Date(value);

      // Format date as YYYY-MM-DD without shifting due to time zone
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(d.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;

    },
    formatDateOfJoin(value) {
      if (!value) return ''; // Handle empty values safely
      const d = new Date(value);

      // Format date as YYYY-MM-DD without shifting due to time zone
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(d.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    async fetchClassesAndSections() {
      this.classes = [];
      this.form.class_ids = [];
      this.form.section_ids = [];

      if (this.form.teacher_type_ids.length) {
        try {
          // Fetch classes that match the selected teacher types
          const response = await api.get(`/classes?teacher_type_ids=${this.form.teacher_type_ids.join(',')}`);
          this.classes = response.data;

          // Fetch sections for the first selected class if any
          if (this.classes.length) {
            await this.fetchSections();
          }
        } catch (error) {
          console.error("Error fetching classes:", error);
        }
      }
    },
    async fetchSections() {
  console.log("Selected Class IDs:", this.form.class_ids); // Log selected class IDs
  this.filteredSections = []; // Reset filtered sections
  if (this.form.class_ids.length) {
    try {
      for (const classId of this.form.class_ids) {
        const response = await api.get(`/classes/${classId}/sections`);
        console.log("API Response:", response.data); // Log the API response
        const sections = response.data.sections;

        if (Array.isArray(sections)) {
          this.filteredSections.push(...sections);
        } else {
          console.error("Expected sections to be an array, but got:", sections);
        }
      }

      console.log("Filtered Sections:", this.filteredSections); // Log the filtered sections
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  }
},
    handleAttachedFilesUpload(event) {
      const files = event.target.files;
      if (files) {
        this.form.attached_files = Array.from(files);
      }
    },
    getImageUrl(imagePath) {
      return `http://localhost:8000/storage/${imagePath}`;
    },
    async fetchEmployeeTypes() {
      try {
        const response = await api.get('/employee-types-all');
        this.employeeTypes = response.data;
      } catch (error) {
        console.error('Failed to fetch employee types:', error);
      }
    },

    async submitEditForm() {


      try {
        if (!this.form.id) {
          this.$message.error('No employee selected for updating');
          return;
        }

        // Prepare the form data
        const formData = {
          id: this.form.id, // Ensure this contains the correct employee ID
          name: this.form.name,
          user_id: this.form.user_id,
          gender: this.form.gender,
          date_of_birth: this.formatDateOfBirth(this.form.date_of_birth),
          fingerprint_id: this.form.fingerprint_id,
          date_of_join: this.formatDateOfJoin(this.form.date_of_join),
          passport_number: this.form.passport_number,
          department_id: this.form.department_id,
          teacher_type_ids: this.form.teacher_type_ids,
          class_ids: this.form.class_ids,
          section_ids: this.form.section_ids,
          employee_type_id: this.form.employee_type_id ?? '',
          national_number: this.form.national_number ?? '',
          phone_number: this.form.phone_number,
          phone_number_two: this.form.phone_number_two ?? '',
          address: this.form.address,
          pin: this.form.pin ?? '',
          years_of_experience: this.form.years_of_experience,
          base_salary: this.form.base_salary,
          // Add more fields as needed
        };

        // Convert the form data to URLSearchParams for x-www-form-urlencoded format
        const urlEncodedData = new URLSearchParams();
        Object.keys(formData).forEach(key => {
          if (Array.isArray(formData[key])) {
            formData[key].forEach(item => {
              urlEncodedData.append(`${key}[]`, item);
            });
          } else {
            urlEncodedData.append(key, formData[key]);
          }
        });

        // Send the PUT request to update the employee
        await api.put(`/employees/${this.form.id}`, urlEncodedData, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });

        this.$message.success('Employee updated successfully');
        this.resetForm();
        this.fetchEmployees();
        this.dialogEditVisible = false; // Close the dialog
      } catch (error) {
        console.error('Error submitting form:', error);
        this.$message.error('Error updating employee');
      }
    },



    async submitForm() {
      const formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('user_id', this.form.user_id);
      formData.append('department_id', this.form.department_id);
      formData.append('employee_type_id', this.form.employee_type_id);
      // formData.append('teacher_type_id', this.form.teacher_type_id);
      // Append arrays for teacher types, classes, and sections
      this.form.teacher_type_ids.forEach(id => {
        formData.append('teacher_type_ids[]', id);
      });

      this.form.class_ids.forEach(id => {
        formData.append('class_ids[]', id);
      });

      this.form.section_ids.forEach(id => {
        formData.append('section_ids[]', id);
      });
      // formData.append('class_id', this.form.class_id);
      // formData.append('class_id', this.form.class_id);
      // formData.append('section_id', this.form.section_id);
      formData.append('national_number', this.form.national_number);
      formData.append('phone_number', this.form.phone_number);
      formData.append('phone_number_two', this.form.phone_number_two);
      formData.append('address', this.form.address);
      formData.append('date_of_join', this.formatDateOfJoin(this.form.date_of_join));
      formData.append('fingerprint_id', this.form.fingerprint_id);
      formData.append('years_of_experience', this.form.years_of_experience);
      formData.append('base_salary', this.form.base_salary);
      formData.append('pin', this.form.pin);
      formData.append('gender', this.form.gender);
      formData.append('date_of_birth', this.formatDateOfBirth(this.form.date_of_birth));
      formData.append('passport_number', this.form.passport_number);
      // Debug: Check photos_list before appending
      console.log('Photos List before appending:', this.form.photos_list);

      // Append photos to formData
      this.form.photos.forEach(photo => {
        formData.append('photos[]', photo);
      });

      // Append attached files to formData
      this.form.attached_files.forEach(file => {
        formData.append('attached_files[]', file);
      });


      this.$forceUpdate();

      try {
        await api.post('/employees', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.$message.success('تمت إضافة الموظف بنجاح');
        this.resetForm();
        this.dialogVisible = false;
        this.fetchEmployees();
      } catch (error) {
        this.$message.error('فشل في إضافة الموظف');
      }
    },
    async fetchEmployees() {
      this.loading = true;
      try {
        const response = await api.get('/employees', {
          params: {
            page: this.currentPage,
            per_page: this.pageSize,
            name: this.searchQuery,
            phone_number: this.searchPhoneQuery,
            national_number: this.searchNationalQuery,
            passport_number: this.searchPassportQuery,
          }
        });
        this.allEmployees = response.data.data;
        this.totalEmployees = response.data.total;
        this.currentPage = response.data.current_page;
        this.pageSize = response.data.per_page;
      } catch (error) {
        this.$message.error('Failed to fetch employees');
      } finally {
        this.loading = false;
      }
    },

    async fetchDepartmentsAndTeacherTypes() {
      try {
        const response = await api.get('/departments-teacher-types');
        this.departments = response.data.departments;
        this.teacherTypes = response.data.teacherTypes;
      } catch (error) {
        this.$message.error('فشل في جلب الأقسام وأنواع المعلمين');
      }
    },
    async showEmployee(employee) {
      try {
        const response = await api.get(`/employees/${employee.id}`);
        this.selectedEmployee = response.data;
        this.showDialogVisible = true;
      } catch (error) {
        this.$message.error('فشل في جلب تفاصيل الموظف');
      }
    },
    async openSalaryDialog(employee) {
      try {
        const response = await api.get(`/employees/${employee.id}/financial-details`); // Fetch employee financial data
        this.selectedEmployee = response.data.employee;
        this.selectedSalary = response.data.salary;
        this.selectedDeductions = response.data.deductions;

        this.salaryDialogVisible = true; // Open the dialog after fetching the data
      } catch (error) {
        console.error('Error fetching employee details:', error);
        this.$message.error('Error fetching employee financial details.');
      }
    },
    async editEmployee(employee) {
      try {
        const response = await api.get(`/employees/${employee.id}`);
        const employeeData = response.data;

        // Populate form with fetched employee data
        this.form = {
          id: employeeData.id,
          name: employeeData.name,
          gender: employeeData.gender,
          date_of_birth: employeeData.date_of_birth,
          date_of_join: employeeData.date_of_join,
          user_id: employeeData.user_id,
          fingerprint_id: employeeData.fingerprint_id,
          passport_number: employeeData.passport_number,
          department_id: employeeData.department_id,
          teacher_type_ids: employeeData.teacher_types.map(type => type.id),
          class_ids: employeeData.classes.map(cls => cls.id),
          section_ids: employeeData.sections.map(sec => sec.id),
          employee_type_id: employeeData.employee_type_id,
          national_number: employeeData.national_number,
          phone_number: employeeData.phone_number,
          phone_number_two: employeeData.phone_number_two,
          address: employeeData.address,
          pin: employeeData.pin,
          years_of_experience: employeeData.years_of_experience,
          base_salary: employeeData.base_salary,
          // Display existing photos and attached files
          photos: employeeData.photos || [],
          attached_files: employeeData.attached_files || []
        };

          // If there's a user_id, fetch the user details
    if (employeeData.user_id) {
      try {
        const userResponse = await api.get(`/users?id=${employeeData.user_id}`);
        if (userResponse.data.data.length > 0) {
          this.users = userResponse.data.data;
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

        this.dialogEditVisible = true; // Open the dialog
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    },
    async deleteEmployee(id) {
      try {
        await api.delete(`/employees/${id}`);
        this.$message.success('تم حذف الموظف بنجاح');
        this.fetchEmployees(); // Refresh employee list after deletion
      } catch (error) {
        this.$message.error('فشل في حذف الموظف');
      }
    },
    resetForm() {
      this.form = {
        employee_type_id: '',
        name: '',
        user_id: '',
        department_id: '',
        teacher_type_id: '',
        national_number: '',
        phone_number: '',
        phone_number_two: '',
        address: '',
        pin: '',
        photos: [],
        attached_files: [],
        teacher_type_ids: [],
        photos_list: [],
        class_ids: [], // Updated to allow multiple classes
        section_ids: [],
        years_of_experience: 0,
        base_salary: 0,
      };
      this.filteredSections = [];
      // this.dialogVisible = false;
    },
    handlePreview(file) {
      window.open(file.url || URL.createObjectURL(file.raw));
    },
    handleRemove(file, fileList) {
      this.form.photos_list = fileList;
    },
    handleFileUpload(event) {
      const files = event.target.files;
      if (files) {
        this.form.photos = Array.from(files);
      }
    },
    beforeUploadPhotos(file) {
      this.form.photos_list.push(file);
      console.log('File added to photos_list:', file);
      return false; // Prevent auto upload
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchEmployees(); // Fetch employees for the new page
    },
    handleSearch: debounce(function () {
      this.fetchEmployees();
    }, 300), // Delay of 300ms
    async loadInitialData() {
      // Load your data here
      try {
        const [teacherTypes, classes, sections, departments, employeeTypes] = await Promise.all([
          api.get('/teacher-types'),
          api.get('/classes'),
          api.get('/sections'),
          api.get('/departments'),

        ]);
        this.teacherTypes = teacherTypes.data;
        this.classes = classes.data;
        this.sections = sections.data;
        this.departments = departments.data;
      } catch (error) {
        console.error("Error loading initial data:", error);
      }
    },
  },
  watch: {
    searchQuery: 'handleSearch',
    searchPhoneQuery: 'handleSearch',
    searchNationalQuery: 'handleSearch',
    searchPassportQuery: 'handleSearch'
  },

    computed: {
        pagedEmployees() {
            // Calculate the start and end index for pagination
            const startIndex = (this.currentPage - 1) * this.pageSize;
            const endIndex = startIndex + this.pageSize;
            // Return a slice of employees based on currentPage and pageSize
            return this.filteredEmployees.slice(startIndex, endIndex);
        },
        filteredEmployees() {
            const query = this.searchQuery.toLowerCase();
            return this.allEmployees.filter(employee => {
                return (
                    employee.name.toLowerCase().includes(query) ||
                    employee.phone_number.toLowerCase().includes(query) ||
                    employee.national_number.toLowerCase().includes(query)
                );
            });
        },
        parsedPhotos() {
            // Parse selectedEmployee.photos if it's a string
            if (this.selectedEmployee && typeof this.selectedEmployee.photos === 'string') {
                return JSON.parse(this.selectedEmployee.photos);
            }
            // If already an array, return as-is
            return this.selectedEmployee ? this.selectedEmployee.photos : [];
        },
        ...mapGetters(['userType']),
    },
    mounted() {
        this.loadInitialData();
        this.fetchEmployeeTypes();
        this.fetchEmployees();
        this.fetchDepartmentsAndTeacherTypes();
        this.fetchDeductionsdAll();
    },
 
};
</script>


<style scoped>
.el-table>>>.highlight-id-cell {
  background-color: #063f5a !important;
  /* Increased specificity */
  color: white;
  text-align: center;
  font: 800;
  font-size: 20px;
}

.highlight-id-row {
  background-color: #003c96;
  /* light background color */
}

.el-table>>>.operation-cell-align-right {
  text-align: right !important;
}

.el-table>>>.el-table__cell {
  text-align: right !important;
}

.operation-buttons {
  display: flex;
  justify-content: flex-end;
}

.employee-form {
  padding: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.el-form-item {
  margin-bottom: 20px;
}

.el-upload {
  width: 100%;
}
</style>
