<template>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
              <div class="d-flex justify-content-around">
                <h6 class="text-white text-capitalize ps-3">Employee Logs</h6>
              </div>
            </div>
            <div>
              <el-button @click="exportLogs('xlsx')">Export to Excel</el-button>
              <el-button @click="exportLogs('csv')">Export to CSV</el-button>
  
              <el-table :data="logs" style="width: 100%">
                <el-table-column prop="employee.name" label="Employee Name" width="150" />
                <el-table-column prop="check_in_time" label="Check-In Time" width="180" />
                <el-table-column prop="check_out_time" label="Check-Out Time" width="180" />
              </el-table>
            </div>
            <el-pagination
              v-if="pagination.total > pagination.per_page"
              @current-change="handleCurrentChange"
              :current-page="pagination.current_page"
              :page-size="pagination.per_page"
              :total="pagination.total"
              layout="prev, pager, next"
              background>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  export default {
    name: 'ShowLogChecks',
    data() {
      return {
        logs: [],
        pagination: {
          current_page: 1,
          per_page: 10,
          total: 0,
        },
      };
    },
    created() {
      this.fetchLogs();
    },
    methods: {
      async fetchLogs(page = 1) {
        try {
          const response = await api.get(`/logs?page=${page}`);
          this.logs = response.data.data;
          this.pagination = {
            current_page: response.data.current_page,
            per_page: response.data.per_page,
            total: response.data.total,
          };
        } catch (error) {
          this.$message.error('Failed to fetch logs');
        }
      },
      async exportLogs(format) {
        try {
          const response = await api.post(`/logs/export/${format}`, {}, {
            responseType: 'blob',
          });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `logs.${format}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          this.$message.error('Failed to export logs');
        }
      },
      handleCurrentChange(page) {
        this.fetchLogs(page);
      },
    },
  };
  </script>
<style scoped>
.el-form {
  margin-bottom: 20px;
}
</style>
    