<template>
    <div>
        <div class="table-container">
            <el-card shadow="always" class="custom-card">
              <h2 class="table-title">Employee Deduction Management</h2>
              
              <div class="button-container">
                <el-button type="primary" icon="el-icon-plus" @click="openDialog">
                  Add Deduction
                </el-button>
              </div>
              
              <el-table v-loading="loading" :data="deductions" border stripe style="width: 100%">
                <el-table-column prop="amount" label="Amount" align="center"></el-table-column>
                <el-table-column prop="description" label="Description" align="center"></el-table-column>
                <el-table-column label="Actions" align="center">
                  <template v-slot="scope">
                    <el-button size="mini" type="primary" @click="editDeduction(scope.row)">Edit</el-button>
                    <el-button size="mini" type="danger" @click="deleteDeduction(scope.row.id)">Delete</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </div>
  
      <el-dialog v-model="dialogVisible" title="Deduction Form">
        <el-form :model="form">
          <el-form-item label="Amount">
            <el-input v-model.number="form.amount" type="number" placeholder="Enter amount" />
          </el-form-item>
          <el-form-item label="Description">
            <el-input v-model="form.description" placeholder="Enter description" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="saveDeduction">Save</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default {
    data() {
      return {
        deductions: [],
        form: {
          id: null,
          amount: 0,
          description: ''
        },
        dialogVisible: false,
        loading: false,
        isEditMode: false,
      };
    },
    methods: {
      async fetchDeductions() {
        this.loading = true;
        try {
          const response = await api.get('deductions-per-hour');
          this.deductions = response.data;
        } catch (error) {
          console.error('Error fetching deductions:', error);
        }finally{
            this.loading = false;
        }
      },
      openDialog() {
        this.resetForm();
        this.isEditMode = false;
        this.dialogVisible = true;
      },
      async saveDeduction() {
        try {
          if (this.isEditMode) {
            await api.put(`deductions-per-hour/${this.form.id}`, this.form);
          } else {
            await api.post('deductions-per-hour', this.form);
          }
          this.fetchDeductions();
          this.dialogVisible = false;
        } catch (error) {
          console.error('Error saving deduction:', error);
        }
      },
      editDeduction(deduction) {
        this.form = { ...deduction };
        this.isEditMode = true;
        this.dialogVisible = true;
      },
      async deleteDeduction(id) {
        try {
          await api.delete(`deductions-per-hour/${id}`);
          this.fetchDeductions();
        } catch (error) {
          console.error('Error deleting deduction:', error);
        }
      },
      resetForm() {
        this.form = {
          id: null,
          amount: 0,
          description: ''
        };
      }
    },
    mounted() {
      this.fetchDeductions();
    }
  };
  </script>
  
  
  <style scoped>
  .dialog-footer {
    text-align: right;
  }
  </style>
  