<template>
    <div class="manage-absences-deductions-container">
      <el-card class="box-card">
  
        <!-- Employee Filter -->
        <div class="filter-row">
          <el-input
            v-model="searchQuery"
            placeholder="Search by employee name"
            clearable
            @clear="onFilterChange"
            @input="onFilterChange"
            class="search-input"
          ></el-input>
  
          <el-select
            v-model="selectedEmployee"
            placeholder="Select Employee"
            @change="onEmployeeSelect"
          >
            <el-option
              v-for="employee in filteredEmployees"
              :key="employee.id"
              :label="employee.name"
              :value="employee.id"
            ></el-option>
          </el-select>
        </div>
  
        <!-- Absences Filter & Table -->
        <div class="section">
          <div class="filter-row">
            <el-select
              v-model="absenceForm.absence_type_id"
              placeholder="Select Absence Type"
              @change="filterAbsences"
            >
              <el-option
                v-for="type in absenceTypes"
                :key="type.id"
                :label="type.name"
                :value="type.id"
              ></el-option>
            </el-select>
  
            <el-button type="primary" @click="openAddAbsenceModal" :disabled="!selectedEmployee">
              Add Absence
            </el-button>
          </div>
  
          <!-- Absences Table with Loading Effect -->
          <el-table
            v-if="!absenceLoading && filteredAbsences.length > 0"
            :data="filteredAbsences"
            stripe
          >
            <el-table-column prop="date" label="Date" width="150"></el-table-column>
            <el-table-column prop="employee.name" label="Employee Name" width="200"></el-table-column>
            <el-table-column prop="absence_type.name" label="Absence Type" width="200"></el-table-column>
          </el-table>
          <el-skeleton v-if="absenceLoading" :rows="3"></el-skeleton>
          <el-empty v-if="!absenceLoading && filteredAbsences.length === 0" description="No absences found"></el-empty>
        </div>
  
        <!-- Deductions Filter & Table -->
        <div class="section">
          <div class="filter-row">
            <el-select
              v-model="deductionForm.deduction_type_id"
              placeholder="Select Deduction Type"
              @change="filterDeductions"
            >
              <el-option
                v-for="type in deductionTypes"
                :key="type.id"
                :label="type.name"
                :value="type.id"
              ></el-option>
            </el-select>
  
            <el-button type="primary" @click="openAddDeductionModal" :disabled="!selectedEmployee">
              Add Deduction
            </el-button>
          </div>
  
          <!-- Deductions Table with Loading Effect -->
          <el-table
            v-if="!deductionLoading && filteredDeductions.length > 0"
            :data="filteredDeductions"
            stripe
          >
            <el-table-column prop="date" label="Date" width="150"></el-table-column>
            <el-table-column prop="employee.name" label="Employee Name" width="200"></el-table-column>
            <el-table-column prop="deduction_type.name" label="Deduction Type" width="200"></el-table-column>
            <el-table-column prop="amount" label="Amount" width="100"></el-table-column>
          </el-table>
          <el-skeleton v-if="deductionLoading" :rows="3"></el-skeleton>
          <el-empty v-if="!deductionLoading && filteredDeductions.length === 0" description="No deductions found"></el-empty>
        </div>
      </el-card>
  
      <!-- Absence Modal -->
      <el-dialog title="Add Absence" v-model="absenceModalVisible">
        <el-form :model="absenceForm">
          <el-form-item label="Absence Date" :label-width="formLabelWidth">
            <el-date-picker v-model="absenceForm.date" type="date" placeholder="Pick a date" @change="formatDate"></el-date-picker>
          </el-form-item>
          <el-form-item label="Absence Type" :label-width="formLabelWidth">
            <el-select v-model="absenceForm.absence_type_id" placeholder="Select Absence Type">
              <el-option v-for="type in absenceTypes" :key="type.id" :label="type.name" :value="type.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="absenceModalVisible = false">Cancel</el-button>
          <el-button type="primary" @click="submitAbsence">Submit</el-button>
        </div>
      </el-dialog>
  
      <!-- Deduction Modal -->
      <el-dialog title="Add Deduction" v-model="deductionModalVisible">
        <el-form :model="deductionForm">
          <el-form-item label="Deduction Date" :label-width="formLabelWidth">
            <el-date-picker v-model="deductionForm.date" type="date" placeholder="Pick a date" @change="formatDate"></el-date-picker>
          </el-form-item>
          <el-form-item label="Amount" :label-width="formLabelWidth">
            <el-input v-model="deductionForm.amount" placeholder="Amount"></el-input>
          </el-form-item>
          <el-form-item label="Reason" :label-width="formLabelWidth">
            <el-input v-model="deductionForm.reason" placeholder="Reason"></el-input>
          </el-form-item>
          <el-form-item label="Deduction Type" :label-width="formLabelWidth">
            <el-select v-model="deductionForm.deduction_type_id" placeholder="Select Deduction Type">
              <el-option v-for="type in deductionTypes" :key="type.id" :label="type.name" :value="type.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="deductionModalVisible = false">Cancel</el-button>
          <el-button type="primary" @click="submitDeduction">Submit</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  
  export default {
  data() {
    return {
      searchQuery: '',
      selectedEmployee: null,
      employees: [],
      filteredEmployees: [],
      absenceLoading: false,
      deductionLoading: false,
      absences: [],
      deductions: [],
      filteredAbsences: [],
      filteredDeductions: [],
      absenceModalVisible: false,
      deductionModalVisible: false,
      absenceForm: { employee_id: null, date: '', absence_type_id: null },
      deductionForm: { employee_id: null, date: '', amount: '', reason: '', deduction_type_id: null },
      absenceTypes: [],
      deductionTypes: [],
      formLabelWidth: '120px',
    };
  },
  methods: {
    async fetchAbsenceAndDeductionTypes() {
      try {
        const [absenceTypesResponse, deductionTypesResponse] = await Promise.all([
          api.get('/absence-types'),
          api.get('/deduction-types'),
        ]);
        this.absenceTypes = absenceTypesResponse.data;
        this.deductionTypes = deductionTypesResponse.data;
      } catch (error) {
        this.$message.error('Failed to load absence and deduction types.');
      }
    },
    async fetchEmployees() {
      try {
        const response = await api.get('/employees-absences-deductions');
        this.employees = response.data;
        this.filteredEmployees = response.data;
      } catch (error) {
        this.$message.error('Failed to load employees.');
      }
    },
    onFilterChange() {
      if (this.searchQuery) {
        this.filteredEmployees = this.employees.filter((employee) =>
          employee.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      } else {
        this.filteredEmployees = this.employees;
      }
    },
    async onEmployeeSelect() {
      this.absenceForm.employee_id = this.selectedEmployee;
      this.deductionForm.employee_id = this.selectedEmployee;
      await this.fetchAbsences();
      await this.fetchDeductions();
    },
    async fetchAbsences() {
  this.absenceLoading = true;
  try {
    const params = {};
    if (this.selectedEmployee) {
      params.employee_id = this.selectedEmployee;  // Add employee_id only if selected
    }
    const response = await api.get('/absences', { params });
    this.absences = response.data;
    this.filteredAbsences = response.data;
  } catch (error) {
    this.$message.error('Failed to load absences.');
  } finally {
    this.absenceLoading = false;
  }
},
    filterAbsences() {
      this.filteredAbsences = this.absences.filter(
        (absence) => absence.absence_type_id === this.absenceForm.absence_type_id
      );
    },
    async fetchDeductions() {
  this.deductionLoading = true;
  try {
    const params = {};
    if (this.selectedEmployee) {
      params.employee_id = this.selectedEmployee;  // Add employee_id only if selected
    }
    const response = await api.get('/deductions', { params });
    this.deductions = response.data;
    this.filteredDeductions = response.data;
  } catch (error) {
    this.$message.error('Failed to load deductions.');
  } finally {
    this.deductionLoading = false;
  }
},
    filterDeductions() {
      this.filteredDeductions = this.deductions.filter(
        (deduction) => deduction.deduction_type_id === this.deductionForm.deduction_type_id
      );
    },
    openAddAbsenceModal() {
      this.absenceModalVisible = true;
    },
    openAddDeductionModal() {
      this.deductionModalVisible = true;
    },
      formatDate(value) {
      // This method formats the date to YYYY-MM-DD
      const date = new Date(value);
      const formattedDate = date.toISOString().split('T')[0]; // Formats date as YYYY-MM-DD
      this.absenceForm.date = formattedDate;
      this.deductionForm.date = formattedDate;
    },
      async submitAbsence() {
        try {
          await api.post('/absences', this.absenceForm);
          this.$message.success('Absence added successfully');
          this.clearForms();
          this.fetchAbsences();
        } catch (error) {
          this.$message.error('Failed to add absence.');
        }
      },
      async submitDeduction() {
        try {
          await api.post('/deductions', this.deductionForm);
          this.$message.success('Deduction added successfully');
          this.clearForms();
          this.fetchDeductions();
        } catch (error) {
          this.$message.error('Failed to add deduction.');
        }
      },
      clearForms() {
        this.absenceForm = { employee_id: this.selectedEmployee, date: '', absence_type_id: null };
        this.deductionForm = { employee_id: this.selectedEmployee, date: '', amount: '', reason: '', deduction_type_id: null };
      },
    },
    mounted() {
      this.fetchEmployees();
      this.fetchAbsenceAndDeductionTypes();
      this.fetchAbsences();
      this.fetchDeductions();
    },
  };
  </script>
  
  <style scoped>
  .manage-absences-deductions-container {
    .filter-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .search-input {
      width: 300px;
    }
    .section {
      margin-bottom: 30px;
    }
    .box-card {
      padding: 20px;
    }
    .dialog-footer {
      text-align: right;
    }
  }
  
  </style>
  