<template>
    <div>
      <!-- Employee List -->
<!-- Filter and Search Options -->
<el-row :gutter="20" style="margin-bottom: 20px;">
  <el-col :span="6">
    <el-input v-model="searchQuery" placeholder="Search by Name" @input="handleSearch"></el-input>
  </el-col>
  <el-col :span="6">
    <el-select v-model="selectedDepartment" placeholder="Filter by Department" @change="handleSearch">
      <el-option v-for="department in departments" :key="department.id" :label="department.name" :value="department.id"></el-option>
    </el-select>
  </el-col>
  <el-col :span="6">
    <el-select v-model="selectedEmployeeType" placeholder="Filter by Employee Type" @change="handleSearch">
      <el-option v-for="type in employeeTypes" :key="type.id" :label="type.name" :value="type.id"></el-option>
    </el-select>
  </el-col>
  <el-col :span="6">
    <el-select v-model="selectedTeacherType" placeholder="Filter by Teacher Type" @change="handleSearch">
      <el-option v-for="type in teacherTypes" :key="type.id" :label="type.type" :value="type.id"></el-option>
    </el-select>
  </el-col>
</el-row>

<!-- Employee List in a Card Container -->
<el-card shadow="always" style="padding: 20px; margin-top: 20px;">
  <el-input
  v-model="inputMonth"
  type="number"
  placeholder="Enter Month"
  min="1"
  max="12"
  class="input-field"
></el-input>
<el-input
  v-model="inputYear"
  type="number"
  placeholder="Enter Year"
  min="1900"
  max="2100"
  class="input-field"
></el-input>
  <el-table
    v-loading="loading"
    :data="employees"
    style="width: 100%;"
    border
    stripe
    @selection-change="handleSelectionChange"
  >
    <!-- Selection Column -->
    <el-table-column type="selection" width="55"></el-table-column>

    <!-- Employee Name Column -->
    <el-table-column :label="$t('employeeName')" prop="name">
      <template #default="scope">
        {{ scope.row.name }}
      </template>
    </el-table-column>

    <!-- Position Column -->
    <el-table-column :label="$t('employeeType')">
      <template #default="scope">
        {{ scope.row.employee_type ? scope.row.employee_type.name : 'No Type Assigned' }}
      </template>
    </el-table-column>

    <!-- Department Column -->
    <el-table-column :label="$t('department')">
      <template #default="scope">
        {{ scope.row.department ? scope.row.department.name : 'Not specified' }}
      </template>
    </el-table-column>

    <!-- Teacher Types Column -->
    <el-table-column :label="$t('teacherType')">
      <template #default="scope">
        <div v-if="scope.row.teacher_types && scope.row.teacher_types.length > 0">
          <ul>
            <li v-for="(type, index) in scope.row.teacher_types" :key="index">
              {{ type.type }}
            </li>
          </ul>
        </div>
        <span v-else>Not specified</span>
      </template>
    </el-table-column>

    <!-- Classes Column -->
    <el-table-column :label="$t('class')">
      <template #default="scope">
        <div v-if="scope.row.classes && scope.row.classes.length > 0">
          <ul>
            <li v-for="(classItem, index) in scope.row.classes" :key="index">
              {{ classItem.name }}
            </li>
          </ul>
        </div>
        <span v-else>Not specified</span>
      </template>
    </el-table-column>

    <!-- Sections Column -->
    <el-table-column :label="$t('section')">
      <template #default="scope">
        <div v-if="scope.row.sections && scope.row.sections.length > 0">
          <ul>
            <li v-for="(section, index) in scope.row.sections" :key="index">
              {{ section.name }}
            </li>
          </ul>
        </div>
        <span v-else>Not specified</span>
      </template>
    </el-table-column>

    <!-- Actions Column -->
    <el-table-column :label="$t('actions')" align="center">
      <template #default="scope">
        <el-button type="primary" @click="openSalaryDialog(scope.row)" size="mini">{{$t('setSalary')}}</el-button>
      </template>
    </el-table-column>
  </el-table>

  <!-- Bulk Action and Export Buttons -->
  <div style="margin-top: 20px; display: flex; justify-content: space-between;">
    <el-button 
      type="primary" 
      @click="calculateBulkSalary" 
      :disabled="selectedEmployees.length === 0"
      style="width: 200px;"
    >
      Issued To All
    </el-button>


  </div>
</el-card>

<!-- Pagination Component -->
<el-pagination
  background
  layout="prev, pager, next"
  :total="totalEmployees"
  :page-size="pageSize"
  @current-change="handlePageChange"
  :current-page="currentPage"
  style="margin-top: 20px; text-align: center;"
></el-pagination>

  
  
      <!-- Salary Dialog -->
<!-- Salary Dialog -->
<el-dialog v-model="salaryDialogVisible" title="Set Employee Salary" width="750px">
  <el-form :model="selectedSalary" ref="salaryForm" label-width="130px" class="salary-form">
    
    <!-- Basic Salary Details -->
    <div class="form-section">
      <h4>{{$t('salaryDetails')}}</h4>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="$t('baseSalary')">
            <el-input v-model="selectedSalary.base_salary" type="number" placeholder="Base salary" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('bonus')">
            <el-input v-model="selectedSalary.bonus" type="number" placeholder="Enter bonus amount"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>

    <!-- Time Period Selection -->
    <div class="form-section">
      <h4>{{$t('period')}}</h4>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Month">
            <el-select v-model="month" placeholder="Choose Month" @change="fetchAttendanceAndAbsences">
              <el-option v-for="m in months" :key="m.value" :label="m.label" :value="m.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Year">
            <el-input v-model="year" type="number" placeholder="Enter Year" @input="fetchAttendanceAndAbsences"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <!-- The absence days should be here -->

            <!-- Absence Records Section -->
            <div class="form-section">
          <h4>{{ $t('absenceRecords') }}</h4>
          <el-table :data="employeeAbsences" border stripe v-loading="loadingAbsences">
            <el-table-column prop="date" :label="$t('date')" width="150"></el-table-column>
            <el-table-column prop="absence_type.name" :label="$t('absenceType')"></el-table-column>
          </el-table>
        </div>

    <CustomCalendar 
      v-if="selectedSalary.employee_id"
      :employeeId="selectedSalary.employee_id"
      :month="month"
      :year="year"
      :attendanceRecords="attendanceRecords"
      :absences="absences"
      :absenceTypes="absenceTypes"
      @refresh-data="fetchAttendanceAndAbsences"
    />

    <!-- Submit & Cancel Buttons -->
    <el-form-item class="dialog-footer">
      <el-button type="primary" @click="submitSalaryForm">Submit</el-button>
      <el-button @click="salaryDialogVisible = false">Cancel</el-button>
    </el-form-item>

  </el-form>
</el-dialog>


    </div>
  </template>
  
  
  <script>
  import CustomCalendar from "./CustomCalendar.vue"; // Import the custom calendar
  import axios from 'axios';
  import { debounce } from 'lodash';
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://cale.caledonian.ly/api',
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default {
    components: {
    CustomCalendar, // Register component
  },
  data() {
    return {
      selectedEmployees:[],
      inputMonth: null, // To capture month from the user
      inputYear: null, // To capture year from the user
      employees: [],
      attendanceRecords: [], 
      selectedEmployeeId: null,
    month: 1, // default to current year
    year: new Date().getFullYear(), // default to current year
      salaryDialogVisible: false,
      loading: false,
      totalEmployees: 0,
      pageSize: 10,
      currentPage: 1,
      searchQuery: '',
      selectedDepartment: null,
      selectedEmployeeType: null,
      selectedTeacherType: null,
      departments: [],
      employeeTypes: [],
      teacherTypes: [],
      selectedSalary: {
        base_salary: null,
        bonus: null,
        allowance: null,
        currency: "LYD", // Default value
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        employee_id: null,
        selectedDeductions: [], // To hold selected deductions
        selectedAbsences: [] // To hold selected absences
      },
      months: [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
      ],
      deductions: [],
      absences: [],
      absenceTypes: [], // This will hold the types of absences available
      employeeAbsences: [], // To store absence records for the selected employee
      loadingAbsences: false, // Loading state for absence records
    };
  },
  watch: {
    month: "fetchAttendanceAndAbsences",
    year: "fetchAttendanceAndAbsences",
  },
  methods: {
    async fetchAttendanceAndAbsences() {
      if (!this.selectedSalary.employee_id) return;

      try {
        console.log("🟢 Fetching Attendance for Employee:", this.selectedSalary.employee_id, this.month, this.year);

        const url = `/attendance-processes/${this.selectedSalary.employee_id}/${this.month}/${this.year}`;
        console.log("🟢 Request URL:", url);

        const attendanceResponse = await api.get(url);
        console.log("🟢 API Response:", attendanceResponse);

        this.attendanceRecords = attendanceResponse.data.attendance || [];
        this.absences = attendanceResponse.data.absences || [];

        console.log("📌 Parent Component Stored attendanceRecords:", JSON.stringify(this.attendanceRecords, null, 2));
        console.log("📌 Parent Component Stored absences:", JSON.stringify(this.absences, null, 2));
      } catch (error) {
        console.error("❌ Error fetching attendance/absences:", error);
        if (error.response) {
          console.error("❌ Response Data:", error.response.data);
          console.error("❌ Response Status:", error.response.status);
          console.error("❌ Response Headers:", error.response.headers);
        }
      }
    },
  
    handleSelectionChange(selection) {
      this.selectedEmployees = selection;
    },
    async calculateBulkSalary() {
      if (!this.inputMonth || !this.inputYear) {
        this.$message.error('Please enter a valid month and year.');
        return;
      }
      if (!this.selectedEmployees.length) {
        this.$message.error('No employees selected.');
        return;
      }

      try {
        const employeeIds = this.selectedEmployees.map(employee => employee.id);
        const response = await api.post('/salaries/calculate-bulk', {
          employee_ids: employeeIds,
          month: this.inputMonth,
          year: this.inputYear,
          bonus: 0, // Adjust as needed
          allowance: 0, // Adjust as needed
          currency: 'LYD', // Default or selected currency
        });

        this.$message.success(this.$t('salaryCalculationSuccess'));
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t('salaryCalculationFailed'));
      }
    },

    async fetchEmployees() {
      this.loading = true;
  try {
    console.log('Fetching employees with params:', {
      page: this.currentPage,
      per_page: this.pageSize,
      name: this.searchQuery,
      department_id: this.selectedDepartment,
      employee_type_id: this.selectedEmployeeType,
      teacher_type_id: this.selectedTeacherType,
    });

    const response = await api.get('/employees-issued-salaries', {
      params: {
        page: this.currentPage,
        per_page: this.pageSize,
        name: this.searchQuery,
        department_id: this.selectedDepartment,
        employee_type_id: this.selectedEmployeeType,
        teacher_type_id: this.selectedTeacherType,
      },
    });
    this.employees = response.data.data;
    this.totalEmployees = response.data.total;
  } catch (error) {
    console.error('Error fetching employees:', error);
    this.$message.error('Failed to load employees.');
  }finally{
    this.loading = false;
  }
},

    async fetchFilters() {
      try {
        const response = await api.get('/departments-teacher-types');
        this.departments = response.data.departments;
        this.teacherTypes = response.data.teacherTypes;
        // assuming employeeTypes are coming from the same endpoint
        this.employeeTypes = response.data.employeeTypes;
      } catch (error) {
        console.error('Error fetching filter options:', error);
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchEmployees();
    },
    handleSearch: debounce(function () {
      this.currentPage = 1;
      this.fetchEmployees();
    }, 300),

    async openSalaryDialog(employee) {
      this.salaryDialogVisible = true;
      this.selectedSalary.employee_id = employee.id;
      this.selectedSalary.base_salary = employee.base_salary;

      // Fetch absence records for the selected employee
      await this.fetchEmployeeAbsences(employee.id);

      // Fetch attendance and absence data for the calendar
      await this.fetchAttendanceAndAbsences();

      console.log("🟢 Data Passed to CustomCalendar:", {
        employeeId: this.selectedSalary.employee_id,
        month: this.month,
        year: this.year,
      });
    },
    async updateAbsence(absence) {
      try {
        await this.$confirm('Are you sure you want to record this absence?', 'Confirmation', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        });
        const response = await api.post('/absences', {
          employee_id: this.selectedSalary.employee_id,
          date: absence.date,
          absence_type_id: absence.absence_type_id
        });
        this.$notify({
          type: 'success',
          title: 'Success',
          message: response.data.message
        });
      } catch (error) {
        console.error('Error updating absence:', error);
        this.$notify({
          type: 'error',
          title: 'Error',
          message: 'Failed to record absence. Please try again.'
        });
      }
    },
    async fetchDeductionsAndAbsences() {
      if (this.selectedSalary.month && this.selectedSalary.year) {
        await this.fetchEmployeeDeductions(this.selectedSalary.employee_id, this.selectedSalary.month, this.selectedSalary.year);
        await this.fetchEmployeeAbsences(this.selectedSalary.employee_id, this.selectedSalary.month, this.selectedSalary.year);
      }
    },
    async fetchEmployeeDeductions(employeeId, month, year) {
      try {
        const response = await api.get('/deductions', {
          params: { employee_id: employeeId, month, year },
        });
        this.deductions = response.data || []; // Assuming deductions array
      } catch (error) {
        this.$message.error('Failed to load deductions.');
      }
    },
    async fetchEmployeeAbsences(employeeId) {
      this.loadingAbsences = true;
      try {
        const response = await api.get('/absences', {
          params: { employee_id: employeeId },
        });
        this.employeeAbsences = response.data;
      } catch (error) {
        console.error('Error fetching absence records:', error);
        this.$message.error('Failed to load absence records.');
      } finally {
        this.loadingAbsences = false;
      }
    },
    handleDeductionSelection(selectedDeductions) {
  this.selectedSalary.selectedDeductions = selectedDeductions;
},
    handleAbsenceSelection(selectedAbsences) {
      this.selectedSalary.selectedAbsences = selectedAbsences;
    },
    async submitSalaryForm() {
  try {
    if (!this.selectedSalary.employee_id || !this.month || !this.year) {
      this.$notify({
        type: 'warning',
        title: 'Incomplete Form',
        message: 'Please select an employee, month, and year before submitting.',
      });
      return;
    }

    const payload = {
      bonus: this.selectedSalary.bonus || 0,
      allowance: this.selectedSalary.allowance || 0,
      currency: this.selectedSalary.currency || 'LYD',
    };

    const response = await api.post(`/calculate-salary/${this.selectedSalary.employee_id}/${this.month}/${this.year}`, payload);
    const calculatedSalary = response.data;

    this.$notify({
      type: 'success',
      title: 'Salary Saved',
      message: `Net salary for ${this.month}/${this.year} has been calculated and stored.`,
    });

  } catch (error) {
    if (error.response && error.response.status === 400) {
      this.$notify({
        type: 'error',
        title: 'Duplicate Entry',
        message: error.response.data.error,
      });
    } else {
      console.error('Error calculating salary:', error);
      this.$notify({
        type: 'error',
        title: 'Calculation Error',
        message: 'There was an error calculating the salary. Please try again.',
      });
    }
  }
},


async submitAbsenceType(absence) {
  try {
    const confirmed = await this.$confirm(
      `Are you sure you want to record this absence for ${absence.date}?`,
      'Confirm Absence',
      {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }
    );

    if (confirmed) {
      await api.post('/absences', {
        employee_id: this.selectedSalary.employee_id,
        date: absence.date,
        absence_type_id: absence.absence_type_id,
      });
      this.$notify({
        type: 'success',
        title: 'Success',
        message: `Absence for ${absence.date} has been recorded.`,
      });
    }
  } catch (error) {
    if (error !== 'cancel') {
      console.error('Error submitting absence:', error);
      this.$notify({
        type: 'error',
        title: 'Error',
        message: 'Failed to record the absence. Please try again.',
      });
    }
  }
}




  },
  mounted() {
    this.fetchEmployees();
    this.fetchFilters();
  },
  async created() {
  try {
    const response = await api.get('/absence-types');
    this.absenceTypes = response.data; // Populate absence types
  } catch (error) {
    console.error('Error fetching absence types:', error);
  }
}

};

  </script>
  
  <style scoped>
  .salary-form .form-section {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
  }
  .salary-form h4 {
    margin-bottom: 10px;
    color: #409eff;
  }
  .dialog-footer {
    text-align: right;
  }

.form-section h4 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
  </style>